<template>
	<div>
		<Breadcrumbs main="" title="release.header"/>
		<!-- Container-fluid starts-->
		<div class="container-fluid search-page">
			<div class="row">
                <div class="col-sm-6">
                    <form class="theme-form">
                        <div class="row ml-1">
                            <div class="col-sm-6">
                                <label for="selectedCont" class="col-form-label pt-0 f-w-600" style="font-size:16px;">{{ $t("release.contNo") }}</label>  
                                <v-select label="selectedCont" class="style-chooser" style="font-size:16px;" v-model="selectedCont" :options="contList" :placeholder="$t('release.contNoPh')">       
                                    <template #no-options>
                                        {{$t('reuse.noMatchingOptions')}}
                                    </template>
                                </v-select>                            
                            </div>
                            <div class="col-sm-6">
                                <label for="distLim" class="col-form-label pt-0 f-w-600" style="font-size:16px;">{{ $t("release.distLim") }}</label>
                                <input v-model="distLim" class="form-control" style="font-size:16px;" type="text" :placeholder="$t('release.distLimPh')" >
                            </div>                              
                        </div>   
                        <div class="form-group ml-3 text-primary f-w-600">
                            <div class="row">
                                <label for="contRelsAddress" class="col-form-label pt-0 ml-3">{{ $t("release.locRels") }}</label>    
                                <p class=" ml-2 fw-bold text-danger"  > {{ $t("release.ifChg") }}</p>
                                <a href="#googleMapRels"><u class=" ml-2 fw-bold text-info" style="font-size:15px" @click="showAC"><i class="icofont icofont-map-search mr-2 " style="font-size:25px"></i> Google Map </u></a>                                
                            </div>
                            <input v-model="contRelsAddress" class="form-control text-info" type="text" id="contRelsAddress" :placeholder="$t('release.locRelsPh') " >
                            <h6 class=" ml-3 text-info"> Latitude : {{ locRels.lat }}  ,  Longitude : {{ locRels.lng }}</h6>
                        </div>      
                        <div class="row ml-1">
                                
                            <div class="col-sm-6 form-group ">
                                <div class="col-sm-12 ">
                                    <h6>{{ $t('release.jobType') }}</h6>
                                </div>
                                <div class="col-sm-12">
                                    <div class="form-group m-t-5 custom-radio-ml">
                                        <div class="radio radio-primary">
                                            <b-form-radio name="jobType" value="Drop-off" v-model="jobType">{{ $t('release.df') }}</b-form-radio>
                                        </div>
                                        <div class="radio radio-primary">
                                            <b-form-radio name="jobType" value="Pick-up" v-model="jobType">{{ $t('release.pu') }}</b-form-radio>
                                        </div>
                                    </div>
                                </div>
                            </div>  
                        </div>  
                        <b-modal ref="rels-modal" hide-footer :title= "$t('release.findingResult')">
                            <div class="d-block text-center">
                                <h4>{{ $t('release.noMatchingCont') }}</h4>
                            </div>
                            <b-button class="my-2" style="font-size:18px;" variant="outline-warning" block @click="doNotModal">
                                {{ $t('release.dontPost') }}
                            </b-button>                            
                            <div class="row " style="align-items:center ">
                                <b-button class="mt-3" variant="outline-info" block @click="postItModal">
                                    <span class="my-5" style="font-size: 18px;">
                                        {{ $t("release.postIt") }}
                                    </span>                                                                             
                                </b-button>                                               
                            </div>   
                        </b-modal>
                        <!-- </div> -->
                        <div class="form-group mt-3 mb-0">
                            <button class="btn btn-primary btn-block" style="font-size:20px" type="button" @click="handleSearching">{{ $t('release.searching') }}</button>
                        </div>
                    </form>
                </div>
                <div class="col-sm-6" id="googleMapRels">
                    <section class="ui one column centered grid mt-5" v-show="showAutoComplete"  style="position:relative; z-index: 5;">
                        <div class="column" >
                            <form class="ui left segment small form" >
                                <div class="ui message red" v-show="error">{{ error }}</div>
                                <!-- <div class="ui segment" > -->
                                    <div class="field" >
                                        <div class="row mb-3 col-sm-12">
                                            <div class=" col-sm-1">
                                                <i class="icon times text-primary" style="font-size: 24px;"  @click="offAC" ></i>                                                    
                                            </div>
                                            <div class="col-sm-11">
                                                <span class="text-warning"> {{ $t('company.donotEnterKey') }}</span>       
                                            </div>                              
                                        </div>
                                        <!-- <div class="ui message blue" >{{ compAddress }}</div> -->
                                        <div class="ui right icon input large" :class="{loading:spinner}">
                                            <input 
                                                type="text"
                                                v-b-popover.hover="$t('company.inputLocNameMsg')" 
                                                :title="$t('company.inputLocName')"                                                
                                                id="autocompleteRels"
                                                v-model="address"
                                                onkeypress= "return event.keyCode != 13"
                                            >
                                            <i 
                                                class="dot circle link icon" 
                                                @click="locatorButtonPressed"
                                                v-b-popover.hover.top="$t('company.useCurrPosition')"
                                            ></i>
                                        </div>
                                        <!-- dummy input to fix bug : only one input, make reload if we press ENTER.  -->
                                        <!-- <div class="ui message red" v-show="false">
                                            <input 
                                                type="text" 
                                            >
                                        </div> -->
                                    </div>

                                    <div class="row" @click="clearAddress">
                                        <i class="icon eraser f-10 ml-4 text-danger"  style="font-size: 18px;"></i>
                                        <h5 class="ml3 text-info" > {{ $t('company.clearAddress') }} </h5>
                                        
                                    </div>
                                <!-- </div> -->
                            </form>                               
                        </div>
                    </section> 
                    <section id="map" style="background-color: lightblue;"></section>   
                </div>
            </div>
            <b-alert v-model="showAlertWallet" variant="danger" dismissible class="col-sm-6">
                <h4 class="alert-heading f-w-800">{{ $t(`${this.$t("release.walletCompNotEnough")}`)}}</h4>
                <div class="row ml-1">
                    <span>{{ $t(`${this.$t("release.balancedAmount")}`) }}</span>
                    <span class="text-dark f-w-800 mx-2" style="font-size: 16px;">{{ balanced}}</span>
                    <span>{{ $t(`${this.$t("refill.mUnit")}`) }}</span>
                </div>
                <div>
                    <span>{{ $t(`${this.$t("release.refillOrContact")}`) }}</span>
                </div>
            </b-alert>
            <div class="row">
              <div class="col-md-12">
                <div class="card">
                      <h4 class="mb-1 mt-4 f-w-600">{{ $t('release.matchingList') }}</h4>
                      <div class="table-responsive datatable-vue">
                        <b-table
                            selectable
                            sticky-header
                            head-variant="light"
                            select-mode="single"
                            show-empty
                            sort-icon-left
                            stacked="md"
                            selectedVariant="primary"
                            :busy="isBusy"
                            :items="items"
                            :fields="tablefields"
                            :current-page="currentPage"
                            :per-page="perPage"
                            @row-selected="rowSelected"
                        >
                            <template #cell(selected)="{ rowSelected }">
                                <template v-if="rowSelected">
                                    <span aria-hidden="true">&check;</span>
                                    <span class="sr-only">{{ $t('require.selected') }}</span>
                                </template>
                                <template v-else>
                                    <span aria-hidden="true">&nbsp;</span>
                                    <span class="sr-only">{{ $t('require.notSelected') }}</span>
                                </template>
                            </template>
                            <template #table-busy>
                                <div class="text-center text-danger my-2">
                                <b-spinner class="align-middle"></b-spinner>
                                <strong>{{ $t('require.loading') }}</strong>
                                </div>
                            </template>
                            <template #empty>
                                <h5>{{ $t('reuse.noRecord') }}</h5>
                            </template>
                            <!-- <template slot="action" slot-scope="row">
                              <b-button variant="danger" @click="selectRow(row.index)">Select</b-button>
                            </template>
                            <template slot="isDeleted" slot-scope="row">
                              {{ row.item ? 'Yes :)' : 'No :(' }}
                            </template> -->
                        </b-table>
                      </div>
                      <b-row>
                        <div class="col-sm-3 ">
                            <b-col class="my-1 ml-3">
                                <b-pagination
                                v-model="currentPage"
                                :total-rows="totalRows"
                                :per-page="perPage"
                                class="my-0"
                                ></b-pagination>
                            </b-col>                             
                        </div>
                        <div class="col-sm-5" >  
                            <b-col class="delete-datatable">
                                <b-button variant="primary" @click="selectBatchRow" class="mb-1" style="font-size:20px">
                                    {{ $t("release.confirmSelected") }} <span class="position-absolute top-0 start-100 translate-middle badge rouned-pill bg-warning">{{ selected.length }}</span>   
                                </b-button>
                            </b-col>    
                            <!-- <b-col class="delete-datatable">
                                <b-button variant="primary" @click="selectRow(row.index)" class="mb-1" style="font-size:20px">
                                    {{ $t("release.confirmSelected") }} <span class="position-absolute top-0 start-100 translate-middle badge rouned-pill bg-warning">{{ selected.length }}</span>   
                                </b-button>
                            </b-col>                             -->
                        </div>          
                        <div class="col-sm-4 " >
                            <b-form-group label-cols="2" :label= "$t('require.perPage')" class="datatable-select">
                            <b-form-select v-model="perPage" :options="pageOptions"></b-form-select>
                            </b-form-group>
                        </div>      
                      </b-row>
                </div>
              </div>
            </div>
            <div class="divider"> </div>
            <div>
                <h4 class="mb-1 mt-4 f-w-600">{{ $t('release.postedRelease') }}</h4>
                <b-table
                    sticky-header
                    head-variant="light"
                    table-variant="success"
                    show-empty
                    sort-icon-left
                    stacked="md"      
                    :items="itemsPostedRels"
                    :fields="fieldsPostedRels"
                    :current-page="currentPagePostedRels"
                    :per-page="perPagePostedRels"
                >
                    <template #cell(actPostedRels)="row" >
                    <b-button  size="sm" @click="actPostedRelsCancel(row.item)" class="m-1">
                        {{ $t('matchTx.cancel') }}
                    </b-button>
                    </template>
                    <template #empty>
                        <h5>{{ $t('reuse.noRecord') }}</h5>
                    </template>
                </b-table>
                <b-row>
                    <div class="row col-sm-8 ">
                    <b-col class="my-1 ml-3">
                        <b-pagination
                        v-model="currentPagePostedRels"
                        :total-rows="totalRowsPostedRels"
                        :per-page="perPagePostedRels"
                        align="fill"
                        class="my-0"
                        ></b-pagination>
                    </b-col>   
                    <b-col sm="3" md="6" class="my-1">
                        <b-form-group
                        :label= "$t('require.perPage')"
                        label-for="per-page-select"
                        label-cols-sm="6"
                        label-cols-md="4"
                        label-cols-lg="3"
                        label-align-sm="right"
                        label-size="sm"
                        class="mb-0"
                        >
                        <b-form-select
                            id="per-page-select"
                            v-model="perPagePostedRels"
                            :options="pageOptionsPostedRels"
                        ></b-form-select>
                        </b-form-group>
                    </b-col>
                    </div>
                </b-row>
                <!-- Info modal Reqr Attch Seal-->
            </div>

        </div>
        <!-- Container-fluid Ends-->
    </div>
</template>
<script>

const axios = require("axios");
import {getBalance, uaTxWallet, googleMapApiKey, agentAtContNo, postedByCompId, getStatusByDocId, compNameLocalByCompId, updateContActivebyContNo, updateActive2False_Cont, updateStatus , updateStatusReqrSrchr, contList, locByCompId,saveWithId} from "../../firebase"
import {getRandomInt} from "../../utilities/util" 
import Datepicker from 'vuejs-datepicker';
import {relsCost} from '../../constants/config'
export default {
	data(){
		return{
            balanced: 0,
            showAlertWallet: false,
            showAutoComplete : false,
            contList : [],
            address : '',
            error : '',        
            spinner: false,
            format: "yyyy-MM-dd",
            relsId: '',
            selectedCont : '',
            agent: '',  // owner of cont. 
            selectedContData: '',  // for makeMatchTx
            compNameLocalRels: '',  // for Release and makeMatchTx 
            contRelsAddress: '',
            locRels : '',
            jobType : '',
            userId: '',
            compId: '',
            distLim : '50',
            payload : '',
            relsQty : 1,
            isBusy: false,
            tablefields: [
                { key: 'selected', label: this.$t('require.selected'), sortable: true, variant: 'info' },
                { key: 'distance', label: this.$t('release.distance'), sortable: true, variant: 'info'},                  
                { key: 'id', label: this.$t('require.id'), sortable: true, },  
                { key: 'bkgId', label: this.$t('release.bkgId'), sortable: true, },
                { key: 'agent', label: this.$t('release.agent'), sortable: true, },                
                { key: 'minDetDate', label: this.$t('release.minDetDate'), sortable: true, },                
                { key: 'compNameLocalReqr', label: this.$t('release.compNameLocalReqr'), sortable: true, },                
                { key: 'contReqrAddress', label: this.$t('release.contReqrAddress'), sortable: false, },
                { key: 'contType', label: this.$t('release.contType'), sortable: true, },
                { key: 'contSize', label: this.$t('release.contSize'), sortable: true, },
                { key: 'contGrade', label: this.$t('release.contGrade'), sortable: true, },
                { key: 'jobType', label: this.$t('release.jobType'), sortable: true, 
                    formatter: value => {
                        return this.$t(`require.${value}`)
                }},
            ],
            items : [],
            // items: [{ "id": 0 ,"bkgId":"1", "agent":"","minDetDate":"YYYY-MM-DD","distance":0, "compNameLocal":"******", "contReqrAddress":"Wait for matching result.","contType":"","contSize":"","contGrade":"","jobType":"Drop-off","reqrId":""}],
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [5, 10, 15],
            selected: [],
              // PostedRelease
            itemsPostedRels: [],
            fieldsPostedRels: [
                { key: 'relsId', label: this.$t('release.relsId'), sortable: true, },  
                { key: 'contNo', label: this.$t('release.contNo'), sortable: true, },                        
                { key: 'agent', label: this.$t('release.agent'), sortable: true, }, 
                { key: 'compNameLocalRels', label: this.$t('matchTx.compNameLocalRels'), sortable: true, },                
                { key: 'contRelsAddress', label: this.$t('matchTx.contRelsAddress'), sortable: false, },   
                { key: 'distLim', label: this.$t('require.distLim'), sortable: true, },             
                { key: 'detDate', label: this.$t('require.detDate'), sortable: true, }, 
                { key: 'contType', label: this.$t('release.contType'), sortable: true, },
                { key: 'contSize', label: this.$t('release.contSize'), sortable: true, },
                { key: 'contGrade', label: this.$t('release.contGrade'), sortable: true, },
                { key: 'jobType', label: this.$t('release.jobType'), sortable: true, 
                    formatter: value => {
                        return this.$t(`require.${value}`)
                }},
                { key: 'timestamp', label: this.$t('shipApproval.date'), sortable: true, 
                    formatter: value => {
                        return this.$moment(value).format("YY-MM-DD @ HH:mm")
                }},
                { key: 'actPostedRels', label: this.$t('matchTx.actions'), variant: 'danger'}
            ],
            totalRowsPostedRels: 1,
            currentPagePostedRels: 1,
            perPagePostedRels: 10,
            pageOptionsPostedRels: [5, 10, 15, { value: 100, text: "Show a lot" }],
		}
	},
	components: {
		Datepicker
	},
    computed: {
        // sortOptions() {
        // // Create an options list from our fields
        // return this.tablefields.filter(f => f.sortable).map(f => { return { text: f.label, value: f.key } });
        // }
    },
	mounted(){
        // Set the initial number of items
        this.totalRows = this.items.length
        let compId= localStorage.getItem("compId")
        let userId= localStorage.getItem("userId")
        this.compId = compId
        this.userId= userId        
        contList(compId, (res)=>{
            this.contList = res
            console.log(`contList => ${JSON.stringify(res , null , 2)}`)
        })
        postedByCompId( "Release",this.compId, "Release" , (res)=>{
            this.itemsPostedRels = res
            this.totalRowsPostedRels = res.length
        })
        locByCompId(compId ,(res)=>{
            // console.log("res =>"+ JSON.stringify(res , null , 2))
            this.locRels = {
                    lat : res.compLoc.lat,
                    lng : res.compLoc.lng
                }
            this.contRelsAddress = res.compAddress
            this.showUserLocationOnTheMap(res.compLoc.lat, res.compLoc.lng )
        })

        var autocomplete = new google.maps.places.Autocomplete(
            document.getElementById('autocompleteRels'),
            {
                bounds: new google.maps.LatLngBounds(
                    new google.maps.LatLng(13.555 , 100.50)
                )
            }
        )
        autocomplete.addListener("place_changed",  ()=>{
            let place = autocomplete.getPlace()
            // console.log("place =>"+ JSON.stringify(place , null , 2))
            let lat = place.geometry.location.lat()
            let lng = place.geometry.location.lng() 
            if (!place.geometry) {
                this.$toasted.show(this.$t("require.fillinMatchedChar"), {theme: 'bubble',   position: 'bottom-right',   type: 'error', duration: 15000});
            } else {
                this.showUserLocationOnTheMap(lat, lng)
                this.getAddressFrom(lat, lng)              
                this.locRels = {
                    lat : lat,
                    lng : lng
                }
            }
        })        
	},
	methods:{
        locatorButtonPressed(){
            this.spinner =true
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    (position) =>{ 
                        let lat = position.coords.latitude
                        let lng = position.coords.longitude
                        this.getAddressFrom(lat, lng )
                        this.showUserLocationOnTheMap(lat, lng )
                        this.locRels = {
                            lat : lat,
                            lng : lng
                        }
                    },
                    error => {
                        this.error = "Locator is unable to find the address. Please type your address manually."
                        this.spinner =false
                    }
                );
            } else {
                this.error = error.message
                this.spinner =false
                // console.log(`Your browser does not support geolocation API. Please enter your address`);
            }
        },
        async getAddressFrom(lat, lng){
            await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${googleMapApiKey}&loading=async`)
            .then(response =>{
                if (response.data.error_message) {
                    this.error = response.data.error_message
                } else {
                   this.contRelsAddress = response.data.results[0].formatted_address 
                    this.address = response.data.results[0].formatted_address
                    // this.address = ''
                }
                this.spinner =false
            })
            .catch(error =>{
                this.error = error.message
                this.spinner =false
                // console.log(`error.message =>${JSON.stringify(error)}`)
            })
        },
        showUserLocationOnTheMap(latitude , longitude){
            // Creat a map object
            let map = new google.maps.Map(document.getElementById("map"),{
                zoom:15,
                center: new google.maps.LatLng(latitude, longitude),
                mapTypeId: google.maps.MapTypeId.ROADMAP
            })
            // Add marker
            new google.maps.Marker({
                position: new google.maps.LatLng(latitude, longitude),
                map: map
            })
            // await new google.maps.marker.AdvanceedMarkerElement({
            //     position: await new google.maps.LatLng(latitude, longitude),
            //     map: map
            // })
        },
        clearAddress(){
            this.address = ''

        },
        showAC(){
            if (this.showAutoComplete == false) {
                this.showAutoComplete = true
            } else {
                this.showAutoComplete = false
            }
        },
        offAC(){
            if (this.showAutoComplete == true) {
                this.showAutoComplete = false
            } 
        },

        showMatchingTable( updatedReqrList){
            console.log (`updateReqrList.data (before nextTick) => ${JSON.stringify(updatedReqrList ,null, 2)}`)  
            this.items = updatedReqrList
            this.totalRows = updatedReqrList.length     
            this.isBusy = false
        },
        showModal() {
            this.isBusy = false
            this.$refs['rels-modal'].show()
        },
        async postItModal() {
            await getBalance(this.compId , async(res)=>{
                if (res < relsCost) {
                    this.balanced = res 
                    this.showAlertWallet = true
                } else {
                    await saveWithId('Release', this.payload.relsId, this.payload).then(async()=>{  // make a posted RELEASE
                        await updateContActivebyContNo(this.selectedCont , false).then(async()=>{
                            this.selectedCont = ''                  
                            await contList(this.compId, (res)=>{   // reset contList for next searching in the current page.
                                this.contList = res
                                this.refreshPostedRelease() 
                                this.selectedCont = ''
                                this.$refs['rels-modal'].hide() 
                                this.$toasted.show( this.$t("release.post1Rels"), {theme: 'bubble', type: 'success' , position: 'bottom-center', duration: 5000});  
                               
                                // window.location.reload()                       
                            })                 
                        })
                        let slipId = `${this.$moment().format("YYMMDDHH")}-${getRandomInt(10000 , 90000)}`
                        await uaTxWallet(relsCost * -1, this.compId, slipId, "auto", "expense", "post release" ,this.payload.relsId)
                    })                    
                }
            })

        },
        doNotModal() {
            this.$refs['rels-modal'].hide()
        },

        rowSelected(item) {
            this.selected = item
        },
        async selectBatchRow(){
            await this.makeMatchTx(this.selected, (res)=>{
                if (res != "done") {
                    this.$toasted.show(this.$t("require.notMatched"), {theme: 'bubble', type: 'error' , position: 'bottom-center', duration: 5000});  
                } else {
                    this.items = []
                    this.totalRows = 0
                    this.selected =[]
                    this.selectedContNo = ''
                    this.jobType = ''
                }
            })
        },
        async makeMatchTx(selectedBkgId, cb){
            let mTxId = `${this.$moment().format("YYMM")}`+'MT'+`${Math.floor(Math.random() * (90000 - 10000 + 1) + 10000)}`
            if (selectedBkgId.length == 0) {
                this.$toasted.show(this.$t("release.noSelectedCont"), {theme: 'bubble', type: 'error' , position: 'bottom-center', duration: 5000});   
            } else {
                getBalance(this.compId , async(res)=>{
                    if (res < relsCost) {
                        this.balanced = res 
                        this.showAlertWallet = true
                    } else {
                        await getStatusByDocId( "Require",selectedBkgId[0].reqrId, async(res)=>{
                            if (res == "Matched") {
                                this.$toasted.show( this.$t("require.postedMatched"), {theme: 'bubble', type: 'info' , position: 'bottom-center', duration: 10000}); 
                            } else {
                                await this.saveMatchTx(selectedBkgId[0] ,mTxId);
                                contList(this.compId, (res)=>{   // reset contList for next searching in the current page.
                                    this.contList = res
                                })
                                this.selectedCont = ''
                                let slipId = `${this.$moment().format("YYMMDDHH")}-${getRandomInt(10000 , 90000)}`
                                await uaTxWallet(relsCost * -1, this.compId, slipId, "auto", "expense", "match by release", mTxId)
                                this.$toasted.show(this.$t("release.makeMatchingTx"), {theme: 'bubble', type: 'success' , position: 'bottom-center', duration: 10000});
                                cb("done")  
                            }
                        })
                    } 
                })
            }
        },
        async saveMatchTx( selBkgId ,mTxId){
            const data = {
                mTxId : mTxId,
                contNo : this.selectedCont, //selCont.contNo,
                distance: selBkgId.distance,                
                bkgId: selBkgId.bkgId, //this.selectedBkgId,
                compNameLocalRels: this.compNameLocalRels,// selCont.compNameLocal,                
                contRelsAddress:  this.contRelsAddress, //selCont.contRelsAddress,
                compNameLocalReqr :  selBkgId.compNameLocalReqr,//await compNameLocalByCompId(this.compId),                
                contReqrAddress: selBkgId.contReqrAddress,//this.contReqrAddress,                
                jobTypeRels: this.jobType, //selCont.jobType,
                jobTypeReqr: selBkgId.jobType, //this.jobType,
                msReqr: 'matched',
                msReqrTS: Date.parse(new Date()),
                jbReqr:'waitAccept',
                jbReqrTS:Date.parse(new Date()),
                actReqr:{
                    cancel:false, 
                    accept: true, 
                    reject:true, 
                    attachSeal:false
                },  
                actRels:{
                    cancel:true, 
                    accept: false, 
                    reject:false, 
                    attachSeal:false
                },
                stepNo: 1,                                               
                msRels: 'matched',
                msRelsTS:Date.parse(new Date()),
                jbRels: 'waitAccept',
                jbRelsTS:Date.parse(new Date()),
                compIdRels: this.compId,//selCont.compId,
                compIdReqr : selBkgId.compId,//this.compId,
                userIdRels: this.userId,//selCont.userId,
                userIdReqr: selBkgId.userId,//this.userId,
                relsTS: Date.parse(new Date()),// selCont.timestamp,
                reqrTS: selBkgId.timestamp,// Date.parse(new Date()),
                // relsId: this.relsId,   
                relsId: "noPost",             
                reqrId: selBkgId.reqrId,
                status: "matched", 
                txSearcher: "Release", 
                timestamp: Date.parse(new Date()),
                active: true,
                sealNo: "",
                // agent: this.selectedContData.agent, //selCont.agent,   
                // contType: this.selectedContData.contType,
                // contSize: this.selectedContData.contSize,
                // contGrade: this.selectedContData.contGrade,
                // detDate : this.selectedContData.detDate, // selCont.detDate, (cont. DetDate)
                // detTS :  this.selectedContData.detTS,                     
                ...this.selectedContData
            }
            await saveWithId('MatchTx', mTxId, data).then(async()=>{
                // await updateActive2False('Release', selCont.relsId)
                await updateStatus('Require', selBkgId.reqrId, 'Matched')
                // await updateActive2False_Cont(selCont.contNo)  
                // await updateActive2False_Cont(this.selectedCont)  
                await updateContActivebyContNo(this.selectedCont , false) 
                // window.location.reload()
            })
        },
        handleSearching(){
            let msg = 'completed'
            if (this.selectedCont ==='') {
                msg = 'release.addSelectedCont'
            } else if (this.contReqrAddress ===''){
                msg = 'release.addContRelsAddress'
            } else if (this.LocReqr== ''){
                msg = 'release.useGoogleMap'
            } else if (this.distLim ===''){
                msg = 'release.addDistLim'
            } else if (isNaN(parseInt(this.distLim))){
                msg = 'release.distLimNumber'
            } else if (this.jobType ===''){
                msg = 'release.selJobType'
            }  
            if (msg === 'completed'){
                agentAtContNo(this.selectedCont , async(res)=>{
                    this.items = []   // clear data in the matching table
                    this.isBusy = true  // turn om spiner
                    this.selectedContData = res
                    this.compNameLocalRels =  await compNameLocalByCompId(this.compId)
                    let YYMM = this.$moment().format("YYMM")
                    this.relsId = YYMM+'RL'+`${Math.floor(Math.random() * (90000 - 10000 + 1) + 10000)}`                    
                    let payload = {
                        relsId : this.relsId,
                        contNo : this.selectedCont,
                        distLim : parseInt(this.distLim),
                        contRelsAddress: this.contRelsAddress,
                        locRels: this.locRels,  
                        jobType : this.jobType,
                        compId : this.compId,
                        compNameLocalRels : this.compNameLocalRels,
                        userId : this.userId,
                        status: "Release",
                        timestamp: Date.parse(new Date()),                        
                        // agent : res.agent,     
                        // contType : res.contType,
                        // contSize : res.contSize,
                        // contGrade : res.contGrade,                                                     
                        // detDate : res.detDate,
                        // detTS: res.detTS,
                        // imageUri : res.imageUri,
                        // maxGross : res.maxGross,
                        // tare : res.tare,
                        ...res
                    }
                    // console.log ('payload=>'+JSON.stringify(payload , null, 2))
                    const functionsURL ='https://matchedrels-7gonzgqu6a-as.a.run.app'       
                    await axios.post (functionsURL, payload)
                    .then((response)=>{
                        // console.log ('post, response=>'+JSON.stringify(response , null, 2))
                        let respData = response.data
                        if (respData.length == 0) {
                            this.payload = payload
                            this.showModal()                              
                        } else {
                            this.showMatchingTable(respData)
                        }
                    })
                    .catch((error) => { 
                        console.log(` Network Error => ${error}` )
                        this.$toasted.show( `Network Error : ${error}`, {theme: 'bubble', type: 'error' , position: 'bottom-center', duration: 10000});       
                    });
                })                             
            }else{
                this.$toasted.show(this.$t(msg), {theme: 'bubble', type: 'error' , position: 'bottom-center', duration: 5000});     
            }
        },


        // PostedRelease
        async refreshPostedRelease() {
            await postedByCompId( "Release",this.compId, "Release" , (res)=>{
                this.itemsPostedRels = res
                this.totalRowsPostedRels = res.length
                })
        },
        async actPostedRelsCancel(item) {
            this.$bvModal.msgBoxConfirm(this.$t('require.confirmCancelMsg'), {
                title: this.$t('matchTx.plsConfirm'),
                size: 'sm',
                buttonSize: 'sm',
                okVariant: 'warning',
                okTitle: this.$t('matchTx.yes'),
                cancelTitle: this.$t('matchTx.no'),
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
            })
            .then(async(value) => {
                if (value) {
                    await getStatusByDocId( "Release",item.relsId, async(res)=>{
                        if (res == "Matched") {
                            this.$toasted.show( this.$t("require.postedMatched"), {theme: 'bubble', type: 'info' , position: 'bottom-center', duration: 10000});  
                        } else {
                            await updateContActivebyContNo(item.contNo, true).then(async()=>{
                                await contList(this.compId, (res)=>{ 
                                    this.contList = res
                                    updateStatus('Release', item.relsId, 'Cancel')
                                    this.refreshPostedRelease()
                                    this.selectedCont = ''             
                                    this.$toasted.show( this.$t('require.postedCancelled'), {theme: 'bubble', type: 'error' , position: 'bottom-center', duration: 10000});  
                                })
                                // window.location.reload()
                            })
                            let slipId = `${this.$moment().format("YYMMDDHH")}-${getRandomInt(10000 , 90000)}`
                            await uaTxWallet(relsCost, this.compId, slipId, "auto", "refund",  "cancel posted release", item.relsId)
                        }
                    })
                } else {
                    return
                }
            })
            .catch(error => {
                this.$toasted.show( `Action-Cancel: ${error}`, {theme: 'bubble', type: 'error' , position: 'bottom-center', duration: 10000});  
            })
        },    


    }
}
</script>
<style>
.ui.button,
.dot.circle.icon{
    background-color: #138fc0;
    color : white
}
.pac-icon {
    display: none;
}
.pac-item{
    padding:0px;
    font-size: 10px;
    cursor: pointer;
}
.pac-item:hover {
    background-color: #ececec;
}
.pac-item-query {
    font-size: 10px;
}
#map {
    position:absolute;
    top:0;
    right:0;
    bottom: 0;
    left: 0;
    background: blue;
}

.style-chooser .vs__search::placeholder,
.style-chooser .vs__dropdown-toggle,
.style-chooser .vs__dropdown-menu {
  background: #dfe5fb;
  border: none;
  color: #394066;
  /* text-transform: lowercase;
  font-variant: small-caps; */
}

.style-chooser .vs__clear,
.style-chooser .vs__open-indicator {
  fill: #394066;
}
.divider {
    height: 1px;
    background-color: #0f0f0f;
    margin:5px
}
</style>
