import { doc ,collection, query, deleteDoc, onSnapshot} from "firebase/firestore"; 
import {db, sumUpWallet, getBalance , saveWithId} from "../../firebase"
	// const state = { items: [], }

	// const mutations = { setItems(state, items) { state.items = items; }, }

	// const actionsTemp = { fetchItems({ commit }) { 
	// 	let userId = localStorage.getItem('userId')
	// 	deleteDoc(doc(db, `${userId}PostBackPS`, userId))  
	// 		// const itemsCollection = collection(db, 'items'); // 'items' is your collection name 
	// 		// onSnapshot(itemsCollection, (snapshot) => { 		
	// 	const pb= query(collection(db,`${userId}PostBackPS`))
	// 	const unsubscribe = onSnapshot(pb, 
	// 		(snapshot) => { 
	// 		const items = snapshot.docs.map(doc => ({ 
	// 			id: doc.id, 
	// 			...doc.data() 
	// 		})); 
	// 		commit('setItems', items); 
	// 	});
	// }}

// export default store; 
	const state = {
		items: [],
		unsubscribe: null,
		// loading: false,
		error: null,
		walletBal : 0
	}

	const mutations = {
		setItems(state, items) {
			state.items = items;
		},
		setUnsubscribe(state, unsubscribe) {
			state.unsubscribe = unsubscribe;
		},
		setLoading(state, loading) {
			state.loading = loading;
		},
		setError(state, error) {
			state.error = error;
		},
		setWalletBal(state, walletBal) {
			state.walletBal = walletBal;
		},
		clearItems(state) {
			state.items = [];
		}
	}

	const actions = {
		fetchItems({ commit, state }, collectionPath) {
			// let id = localStorage.getItem('userId')
			let refno = collectionPath.slice(0 ,12)
			let compId = collectionPath.slice(0 ,7)
			getBalance(compId , (res)=>{
				commit('setWalletBal', res );

			})
			deleteDoc(doc(db, collectionPath, refno))  			
			// commit('setLoading', true);
			commit('setError', null);
			if (state.unsubscribe) {
				state.unsubscribe();
				commit('setUnsubscribe', null);
				commit('clearItems');
			}
			try {
				// const unsubscribe = db.collection(collectionPath).onSnapshot(
				const pb= query(collection(db, collectionPath))
				const unsubscribe = onSnapshot(pb, 
					(snapshot) => {
						const items = [];
						snapshot.forEach((doc) => {
							items.push({ 
								id: doc.id, 
								...doc.data() 
							});
						});
						if (items.length != 0) {
							commit('setItems', items);
							// commit('setLoading', false);
						}
						console.log(` act onSnapshot=>${JSON.stringify(items , null ,2)}`)
						if (items.length != 0 && items[0].refno == refno) {
							let approvedAmount = parseInt(items[0].total)
							sumUpWallet(approvedAmount, compId).then(async()=>{
								const uaTx = {
									slipId : items[0].refno,
									payerId : compId,        
									paymentMethod : "online",
									amount : approvedAmount,
									acctType : "refill",
									reason : "refillOL",
								}
								await saveWithId('uaTx', items[0].refno, uaTx)
								await getBalance(compId , (res)=>{
									commit('setWalletBal', res );
								})
							})
							deleteDoc(doc(db, collectionPath, items[0].refno))  
						}
					},
					(error) => {
						commit('setError', error);
						commit('setLoading', false);
						console.error("Error fetching items:", error);
					}
				);
				commit('setUnsubscribe', unsubscribe);
			} catch (error) {
				commit('setError', error);
				// commit('setLoading', false);
				console.error("Error setting up listener:", error);
			}
		},
		unsubscribeFromFirestore({ commit, state }) {
			if (state.unsubscribe) {
				state.unsubscribe();
				commit('setUnsubscribe', null);
				commit('clearItems');
			}
		}
	}

	const getters = {
		allItems: (state) => state.items,
		isLoading: (state) => state.loading,
		hasError: (state) => state.error,
		walletBal: (state) => state.walletBal
	}
	
	// const store = new Vuex.Store({
	// 	modules: {
	// 	firestore: firestoreModule // Register the module
	// }
	// });
	// export default store;

export default {
	namespaced: true, // Important: Makes this a namespaced module
	state,
	actions,
	getters,
	mutations
}