<template>
    <div class="page-wrapper">
       <div class="container-fluid">
           <!-- sign up page start-->
           <div class="authentication-main">
               <div class="row">
                   <div class="col-md-12 p-0">
                       <div class="auth-innerright">
                           <div class="authentication-box">
                               <div class="card p-3">
                                    <div class="d-flex flex-row  justify-content-between ">
                                        <!-- <a href="/process/lucking">
                                            <feather type="arrow-left-circle" size="36"></feather>
                                        </a> -->
                                        <!-- <div class="text-center mb-2"><img alt="" src="../../assets/images/conex-logo.png"></div>                                  -->
                                        <div class="d-flex flex-row justify-content-start ml-2">
                                            <h5 class="text-dark ml-2">{{ compNameLocal }}-</h5>
                                            <h5 class="text-primary">{{ $t('refillOL.wallet') }} :</h5>
                                            <h5 class="text-secondary mx-2">{{ walletBal }} </h5>
                                            <h5 class="text-primary"> {{ cc }}</h5>
                                        </div>                                      
                                        
                                        <div style="text-align: end"><LocaleSwitcher/></div>       
                                    </div>         
                                   <div class="text-center">
                                        <h2 class="mt-3 mb-2">{{ $t('refillOL.title') }}</h2>
                                        <!-- <h6 class="text-center text-info ">{{ $t('refillOL.info') }} </h6> -->
                                    </div> 

                                    <div>
                                        <!-- <div v-if="isLoading">Loading...PaySo Notification via Real-time Firestore</div> -->
                                        <div v-if="hasError">{{ hasError }}</div>
                                        <!-- <div v-show="allItems.length != 0" > 
                                            <div class="d-flex flex-row justify-content-center bg-warning text-dark mx-3" style="font-size: 22px; ">
                                                <span class="mx-2" >{{ $t('refill.refill') }}</span>
                                                <ul>
                                                <li v-for="item in allItems" :key="item.id">{{ item.total}}</li>
                                                </ul>
                                                <span class="mx-2"> {{ $t('refill.mUnit') }}</span>
                                                <span class="mx-2"> {{ $t('refillOL.success') }}</span> 
                                            </div>
                                        </div> -->
                                    </div> 
                                    <div>
                                        <b-modal ref="removeCard-modal" hide-footer :title="$t($t('refillOL.removeCard') )">
                                            <div class="d-block text-center">
                                                <h4>{{ $t('refillOL.removeCardMsg') }}</h4>
                                            </div>
                                            <b-button @click="handleCancelCCRegis">{{ $t('refillOL.remove') }}</b-button>
                                            <b-button @click="goHome">{{ $t('refillOL.no') }}</b-button>
                                        </b-modal> 
                                    </div>
                                    <div>
                                        <b-modal ref="canotRemoveCard-modal" hide-footer :title="$t($t('refillOL.canotRemoveCard') )">
                                            <div class="d-block text-center">
                                                <h4>{{ $t('refillOL.canotRemoveCardMsg') }}</h4>
                                            </div>
                                            <b-button @click="hideCanotRemoveCardModal">{{ $t('refillOL.OK') }}</b-button>
                                            <b-button @click="goHome">{{ $t('refillOL.home') }}</b-button>
                                        </b-modal> 
                                    </div>
                                    <!-- <div class="row"> -->
                                        <div class="col-sm-12">
                                            <b-form-group class="f-w-600 text-secondary" style="font-size: 20px"  :label="`${$t('refillOL.chooseAmount')} - ${$t(`currency.${lang}`) }`" v-slot="{ ariaDescribedby }">
                                                <b-form-radio-group
                                                    v-model="selectedAmount"
                                                    :options="optionsAmount"
                                                    :aria-describedby="ariaDescribedby"
                                                    name="selectedAmount-options"
                                                    plain
                                                    size="lg"
                                                ></b-form-radio-group>
                                            </b-form-group>
                                        </div>  
                                    <!-- </div> -->
                                    <h4 class="ml-3 text-primary"> {{ $t('refillOL.paymentAcct') }}</h4>
                                    <div class="divider"> </div>
                                    <div class="col-sm-12 mt-2" >
                                        <h5 class="text-info"> {{ $t('refillOL.payments') }}</h5>
                                        <div v-show="!showPaymentSelection" class="iBank mt-2" >
                                            <b-card-group deck>
                                                <b-card class="mx-3" @click="handlePayMethodIndex(1)" :img-src= "require('../../assets/images/iconPayments/PromptPay-logo.png')" img-alt="qrCodePP" img-top></b-card>
                                                <b-card class="mr-3" @click="handlePayMethodIndex(2)" :img-src="require('../../assets/images/iconPayments/truemoney-wallet-logo.png')" img-alt="trueW" img-top></b-card>
                                                <b-card class="mr-3" @click="handlePayMethodIndex(3)" :img-src="require('../../assets/images/iconPayments/credit-card.png')" img-alt="creditcard" img-top></b-card>
                                                <b-card class="mr-3" @click="handlePayMethodIndex(4)" :img-src="require('../../assets/images/iconPayments/mobile-banking.png')" img-alt="mBank" img-top></b-card>
                                            </b-card-group>                                            
                                        </div>
                                    </div>
                                    <div class="divider"> </div>
                                    <div class="col-sm-12 my-2">
                                        <h5 class="text-info"> {{ $t('refillOL.cCard') }}</h5>
                                        <div v-if="cardArr.length != 0" class="row my-2">
                                            <div class="mx-2" v-for="(item, index) in cardArr" :key="index" >
                                                <div>
                                                    <div class-="row">
                                                        <span class="text-info" style="font-size: 14px;">{{`...${item.cardNumber}` }}</span>
                                                        <span :class="isHovered ? 'text-danger' : ''" style=" cursor:alias" @click="alertCancelCCRegis(index)" v-b-hover="handleHover" v-b-popover.hover="$t('refillOL.delCard')">
                                                            <i class="icon eraser f-10 " style="font-size: 14px;"></i>
                                                        </span> 
                                                    </div>                                        
                                                    <div class="row mx-1" @click="handlePayMethodIndex(0)" style="cursor:pointer">
                                                        <img class="mr-1" :src="getIconCardImage(item.cardType)" alt="cCard Image"/>
                                                    </div>  
                                                </div>
                                            </div>
                                        </div>                                    
                                    </div>

                                    <div class="col-sm-12" >
                                        <h5 class="text-info"> {{ $t('refillOL.mBank') }}</h5>
                                        <div v-show="!showPaymentSelection" class="iBank my-2" >
                                            <b-card-group deck>
                                                <b-card class="mx-3" @click="handlePayMethodIndex(5)" :img-src= "require('../../assets/images/iconBank/I-kbank.png')" img-alt="K-Bank" img-top></b-card>
                                                <b-card class="mr-3" @click="handlePayMethodIndex(6)" :img-src="require('../../assets/images/iconBank/I-scb.png')" img-alt="SCB" img-top></b-card>
                                                <b-card class="mr-3" @click="handlePayMethodIndex(7)" :img-src="require('../../assets/images/iconBank/I-bay.png')" img-alt="BAY" img-top></b-card>
                                                <b-card class="mr-3" @click="handlePayMethodIndex(8)" :img-src="require('../../assets/images/iconBank/I-bbl.png')" img-alt="BBL" img-top></b-card>
                                            </b-card-group>                                            
                                        </div>
                                    </div>
                                    <b-button @click="simCreatePostbackPaySo"> Refill Simulation - Postback-PaySo </b-button>
                                </div>
                            </div>
                       </div>
                   </div>
               </div>
           </div>
       </div>
    </div>
    </template>
    <script>
    import { mapGetters, mapActions } from 'vuex';
    import {getDataWithId, getBalance, saveWithId, sumUpWallet } from "../../firebase"
    import LocaleSwitcher from "../../components/localSwitcher.vue"
    import { htmlChannel, htmlChannelTW, htmlCC1st, htmlCCregistered ,getRegisCardData ,cancelCCregistered, getIconCard } from '../../utilities/paySoAPI'
    import {currency} from '../../constants/config'
    import {getRandomInt} from "../../utilities/util"
    export default {
        components: { LocaleSwitcher },
        name: 'refillOL',
        data() {
            return{
                userId: '',
                compId: '',
                compNameLocal: '',
                amount: 0,
                balanced: 0,
                showPaymentSelection: false,
                cardArr: [
                    {cardType : "VISA" , cardNumber : "5555"},                    
                    {cardType : "MASTER CARD" , cardNumber : "4444"},                    
                    {cardType : "AMEX" , cardNumber : "1111"},
                    {cardType : "UNIONPAY" , cardNumber : "2222"},
                    {cardType : "JCB" , cardNumber : "3333"},
                ],
                customertoken: '',
                customeremail: '',
                channel: '',
                cc: '',
                webViewCase: 'channel',
                lang:'',
                selectedAmount: 0,
                optionsAmount : [
                {text : "1000" , value : 1000},
                {text : "2000" , value : 2000},
                {text : "3000" , value : 3000},
                {text : "5000" , value : 5000},
                {text : "10000" , value : 10000},
                ],
                isHovered: false,
                // isLoading: false,
                refno: null,
                approvedAmount : 0
            }
        },

        mounted(){
        // this.userId = localStorage.getItem("userId")
        this.compId = localStorage.getItem("compId")
        this.refno = `${this.compId}${getRandomInt(10000 , 90000)}`
        this.fetchItems(`${this.refno}PbPs`);   // ePayment via Vuex                 
        this.lang = this.$i18n.locale
        getDataWithId("Company", "compId", this.compId, (res)=>{
            this.compNameLocal = res.compNameLocal
            this.customeremail = res.contactEmail
            this.busType = res.busType
        })
        // getBalance(this.compId , (res)=>{
        //     console.log(`this.Bal=>${res}`)
        //     this.balanced = res
        // })
        // this.customeremail = localStorage.getItem("user")
        this.cc = currency
        // this.getRegiscards(this.userId)
        },
        
        beforeUnmount() {
          this.unsubscribeFromFirestore();   // ePayment via Vuex
        },
        
        computed: {
         //  ...mapGetters('realtimeFs', ['allItems','isLoading','hasError'])   // ePayment via Vuex
           ...mapGetters('realtimeFs', ['allItems','hasError' , 'walletBal'])   // ePayment via Vuex
           // Access with module name
        },

        methods: {
            handleHover(hovered) {
            this.isHovered = hovered
            },
           ...mapActions('realtimeFs', ['fetchItems', 'unsubscribeFromFirestore']),  // ePayment via Vuex
            // Access with module name
            getIconCardImage(id){
                return getIconCard(id)
            },
            alertCancelCCRegis(cardid){
                this.$refs['removeCard-modal'].show()  
            },

            handleCancelCCRegis(cardid){
                let payload = {
                    customerref : this.compId,
                    customertoken : customertoken,
                    cardid : cardid
                } 
                cancelCCregistered( payload, function(resp){
                    console.log(`Registered Card Data => ${JSON.stringify(resp)}`)
                    if (resp.message == 'Complete') {
                        this.getRegiscards(this.userId) 
                    } else {
                        this.showCanotRemoveCardModal()
                    }
                })
                this.$refs['removeCard-modal'].hide()  
            },
            showCanotRemoveCardModal() {
            this.$refs['canotRemoveCard-modal'].show()         
            },
            hideCanotRemoveCardModal() {
            this.$refs['canotRemoveCard-modal'].hide()         
            },
            async getRegiscards(userId){
                getRegisCardData(userId , function (res){
                    console.log(`Registered Card Data => ${JSON.stringify(res)}`)
                    if (res) {
                        this.cardArr = res.card
                        this.customertoken = res.data               
                    } else {
                        this.cardArr = []
                        this.customertoken = ""
                    }
                })  
            },
            async handlePayMethodIndex(index){
                switch (index) {
                    case 0:
                        this.webViewCase ="ccRegistered"
                        this.channel = "full"
                        this.showPaymentSelection =true
                        break;
                    case 1:
                        this.webViewCase ="channel"
                        this.channel ="promptpay"
                        this.showPaymentSelection =true
                        break;
                    case 2:
                        this.webViewCase ="channelTW"
                        this.channel ="truewallet"
                        this.showPaymentSelection =true
                        break;
                    case 3:
                        this.webViewCase ="cc1st"
                        this.channel ="full"
                        this.showPaymentSelection =true
                        break;
                    case 4:
                        this.webViewCase ="channel"
                        this.channel ="ibanking"
                        this.showPaymentSelection =true
                        break;
                    case 5:
                        this.webViewCase ="channel"
                        this.channel ="ibanking_kbank"
                        this.showPaymentSelection =true
                        break;
                    case 6:
                        this.webViewCase ="channel"
                        this.channel ="ibanking_scb"
                        this.showPaymentSelection =true
                        break;
                    case 7:
                        this.webViewCase ="channel"
                        this.channel ="ibanking_bay"
                        this.showPaymentSelection =true
                        break;
                    case 8:
                        // await genSimPostBack(refno , amount)
                        this.webViewCase ="channel"
                        this.channel ="ibanking_bbl"
                        this.showPaymentSelection =true
                        break;
                    default:
                        break;
                }
            },
            goHome(){
                this.hideCanotRemoveCardModal()
                this.$router.push('/dashboard/homePage')
            },
            async simCreatePostbackPaySo() {
                // let refno = this.refno
                // this.refno = refno
                // let customerref = this.refno.slice(0 ,7)
                const payload = {
                refno : this.refno,
                statusname : "completed",
                cardtype : "VISA",
                customeremail : this.customeremail,
                total: 100,
                productdetail : "Simulation Testing"
                }
                await saveWithId(`${this.refno}PbPs`, this.refno, payload).then(()=>{
                    // setTimeout(function () {
                    //     window.location.href='/dashboard/homePage'
                    // },2000)
                    this.$toasted.show(`${this.$t('refill.refill')} ${this.allItems[0].total} ${this.$t('refill.mUnit')} ${this.$t('refillOL.success')}`, {theme: 'bubble', position: 'bottom-center', type: 'success' , duration: 5000});
                })
            },
            realtimeFS(){
                this.$toasted.show('onSnapshot was acknowledged.', {theme: 'bubble', position: 'bottom-center', type: 'success' , duration: 5000});    
            }
        }
    }
    </script>
    <style scoped>
    .iBank {
        width: 75%;
        height: 75%;
        border-radius: 5px;
    }
    .divider {
        height: 1px;
        background-color: #0f0f0f;
        margin:5px
    }
    </style>