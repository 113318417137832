import axios  from "axios";
const productdetail = "EV Charging Service"
const merchantid = "77747396"
const password = "ts8JWZm8"
const apikey = "iNAPSfCt"
const secretkey = "MHx0wf0h9vbEJJrP"
const authkey = "eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJhdWQiOiIyIiwiZXhwIjozMjkyMDQwODU5LCJpYXQiOjE3MTUyNDA4NTksImp0aSI6IjE3MTUyNDA4NTk0OWY2OTgxMWU5NWUyOGI3MTEwOTM2OTJiYmY5MTI5ZGNjMTE2YmU4Mzg0ZmFhYTYzY2M4NjFiMjNkN2E0OGQwYTZjYmQ3IiwibmJmIjoxNzE1MjQwODU5LCJzdWIiOiI0NzM5NiJ9.A2SuerxXTVKn0tt7mGfO_R5CXNZBlrwd0zP9Sdoxr2_D_WXFiJ6d13_cSCIeL_aLy76r9EaxtDLutIWZ8eHJYIrxzvFLYs-XDF1iwHO2tLyNvYNDELKMtSxGrLpXZheCuaZkqAFO7LqpG92DGB8r0mGbXfiXrmfqu9SCF3Ogn9_NIeJ_5opWc20V_RI1AulPtWjSLNX3DPZBfj4MTJr0MMHkMXwS2BqMQ0mNYdnY1pu3RCPzbEQGyO4kA_Kmy-JsjhCyqvgGpT8gI03QIcDlnfKxdWRj46nZUM-K7zchvigBwv1iMAlw1GoV-9ZiahkmY9sFA6wdlNeBtIfAVuE5XQ"
//end point for payso to call in when payment success/reject.
//https://asia-southeast1-conex-a535b.cloudfunctions.net/postbackpayso
export const htmlChannel = (payload) =>{
    const htmlContent = `
    <html>
    <head>
        <title>Paysolutoins Payment</title>
            <script>
            // Function to automatically submit the form after a delay
            function autoSubmit() {
            setTimeout(function() {
                document.forms["paymentForm"].submit();
            }, 500); // Adjust the delay time in milliseconds as needed
            }
            // Call the autoSubmit function when the document is ready
            document.addEventListener("DOMContentLoaded", autoSubmit);
        </script>
    </head>
    <body>
        <form id="paymentForm" method="post" action="https://payment.paysolutions.asia/epaylink/payment.aspx">
            <input type="hidden" name="customeremail" value=${payload.customeremail} /><br />
            <input type="hidden" name="productdetail" value=${productdetail} /><br />
            <input type="hidden" name="refno" value=${payload.refno} /><br />
            <input type="hidden" name="merchantid" value=${merchantid} /><br />
            <input type="hidden" name="cc" value=${payload.cc} /><br />
            <input type="hidden" name="total" value=${payload.total} /><br />
            <input type="hidden" name="lang" value=${payload.lang} /><br /><br />
            <input type="hidden" name="channel" value=${payload.channel} /><br /><br />
            <input type="hidden" name="Submit" value="Comfirm Order" />
        </form>
    </body>
    </html>
    `; 
    return htmlContent
}
export const htmlChannelTW = (payload) =>{
    const htmlContent = `
    <html>
    <head>
        <title>Paysolutoins Payment</title>
            <script>
            // Function to automatically submit the form after a delay
            function autoSubmit() {
            setTimeout(function() {
                document.forms["paymentForm"].submit();
            }, 500); // Adjust the delay time in milliseconds as needed
            }
            // Call the autoSubmit function when the document is ready
            document.addEventListener("DOMContentLoaded", autoSubmit);
        </script>
    </head>
    <body>
        <form id="paymentForm" method="post" action="https://payment.paysolutions.asia/epaylink/payment.aspx">
            <input type="hidden" name="customeremail" value=${payload.customeremail} /><br />
            <input type="hidden" name="customername" value=${payload.customername} /><br />
            <input type="hidden" name="customertel" value=${payload.customertel} /><br />
            <input type="hidden" name="productdetail" value=${productdetail} /><br />
            <input type="hidden" name="refno" value=${payload.refno} /><br />
            <input type="hidden" name="merchantid" value=${merchantid} /><br />
            <input type="hidden" name="cc" value=${payload.cc} /><br />
            <input type="hidden" name="total" value=${payload.total} /><br />
            <input type="hidden" name="lang" value=${payload.lang} /><br /><br />
            <input type="hidden" name="channel" value=${payload.channel} /><br /><br />
            <input type="hidden" name="Submit" value="Comfirm Order" />
        </form>
    </body>
    </html>
    `; 
    return htmlContent
}
export const htmlCC1st = (payload) =>{
    const htmlContent = `
    <html>
        <head>
            <title>Paysolutoins Payment</title>
            <script>
                function autoSubmit() {
                setTimeout(function() {
                    document.forms["paymentForm"].submit();
                }, 500);
                }
                document.addEventListener("DOMContentLoaded", autoSubmit);
          </script>
        </head>
        <body>
            <form id="paymentForm" method="post" action="https://www.thaiepay.com/epaylink/payment.aspx">
                <input type="hidden" name="customeremail" value=${payload.customeremail} /><br />
                <input type="hidden" name="productdetail" value=${productdetail} /><br />
                <input type="hidden" name="refno" value=${payload.refno} /><br />
                <input type="hidden" name="merchantid" value=${merchantid} /><br />
                <input type="hidden" name="cc" value=${payload.cc} /><br />
                <input type="hidden" name="total" value=${payload.total} /><br />
                <input type="hidden" name="lang" value=${payload.lang} /><br />
                <input type="hidden" name="channel" value=${payload.channel} /><br /><br />
                <input type="hidden" name="customerref" value=${payload.customerref} /><br /><br />     
                <input type="hidden" name="Submit" value="Comfirm Order" />
            </form>
        </body>
    </html>
    `; 
    return htmlContent
}

export const htmlCCregistered = (payload) =>{
    const htmlContent = `
    <html>
        <head>
            <title>Paysolutoins Payment</title>
            <script>
                function autoSubmit() {
                setTimeout(function() {
                    document.forms["paymentForm"].submit();
                }, 500);
                }
                document.addEventListener("DOMContentLoaded", autoSubmit);
          </script>
        </head>
        <body>
            <form id="paymentForm" method="post" action="https://www.thaiepay.com/epaylink/payment.aspx">
                <input type="hidden" name="customeremail" value=${payload.customeremail} /><br />
                <input type="hidden" name="productdetail" value=${productdetail} /><br />
                <input type="hidden" name="refno" value=${payload.refno} /><br />
                <input type="hidden" name="merchantid" value=${merchantid} /><br />
                <input type="hidden" name="cc" value=${payload.cc} /><br />
                <input type="hidden" name="total" value=${payload.total} /><br />
                <input type="hidden" name="lang" value=${payload.lang} /><br />
                <input type="hidden" name="channel" value=${payload.channel} /><br /><br />
                <input type="hidden" name="customerref" value=${payload.customerref} /><br />
                <input type="hidden" name="customertoken" value=${payload.customertoken} /><br /><br />  
                <input type="hidden" name="Submit" value="Comfirm Order" />
            </form>
        </body>
    </html>
    `; 
    return htmlContent
}

export const getRegisCardData = (customerref, cb)=>{
    let data = JSON.stringify({ 
        "merchantID": merchantid.slice(3,8),  // last 5 digits of merchantid
        "customerRef": customerref, 
    }); 
    console.log(`data => ${data}`)
    let config = { 
        method: 'post', 
        url: 'https://apis.paysolutions.asia/tokenizer/getCustomerToken', 
        headers: { 
            'Content-Type': 'application/json',
            'apikey': apikey, 
            'secretkey': secretkey, 
        }, 
        data : data 
    }
    
    axios
    .request(config)
    .then((response) => { 
        cb (response.data)
    })
    .catch((error) => { 
        console.log(`paySo, getRegisCardData Error => ${error}` )
        cb(undefined)
    });
}

export const cancelCCregistered = (payload, cb)=>{
    let data = JSON.stringify({ 
        "merchantID": merchantid.slice(3,8), 
        "customerRef": payload.customerref,
        "customertoken": payload.customertoken,
        "cardid": payload.cardid 
    }); 
    console.log(`data, CancelCCRegis => ${data}`)
    let config = { 
        method: 'post', 
        url: 'https://apis.paysolutions.asia/tokenizer/removeCustomerCard', 
        headers: { 
            'Content-Type': 'application/json',
            "apikey": apikey, 
            "secretkey": secretkey, 
        }, 
        data : data 
    }
    axios
    .request(config)   // .post(config) 
    .then((response) => { 
        // console.log(`response.data => ${JSON.stringify(response.data)}`)
        cb (response.data)
    })
    .catch((error) => { 
        console.log(`paySo, cancelCCregistered Error => ${error}` ) 
        cb (undefined)
    });
}
export const getIconCard = (cardType)=>{
    let icon = ''
    switch (cardType) {
        case "AMEX":
            icon = require("../assets/images/iconCard/amex.png")
            break;
        case "UNIONPAY":
            icon = require("../assets/images/iconCard/unionpay.png")
            break;
        case "JCB":
            icon = require("../assets/images/iconCard/jcb.png")
            break;
        case "MASTER CARD":
            icon = require("../assets/images/iconCard/mastercard.png")
            break;
        case "VISA":
            icon = require("../assets/images/iconCard/visa.png")
            break;
        default:
            break;
    }
    return icon
}

