<template>
  <div>
    <Breadcrumbs main="slip.report" title="slip.header" />
    <b-container fluid>
      <!-- <h3 class="mb-1 mt-4 f-w-600">{{ $t('matchTx.header') }}</h3> -->
      <div class="row">
        <div class="col-sm-3 mx-3">
          <label class="f-w-600" style="font-size:16px;">{{ $t("slip.startDate") }}</label>
          <div class="form-group">
              <datepicker input-class="datepicker-here form-control digits" :format="format" v-model="startDate" ></datepicker>
          </div>  
        </div>  
        <div class="col-sm-3 mx-3">
          <label class="f-w-600" style="font-size:16px;">{{ $t("slip.endDate") }}</label>
          <div class="form-group">
              <datepicker input-class="datepicker-here form-control digits"  :format="format" v-model="endDate" ></datepicker>
          </div>                     
        </div> 
        <div class="col-sm-2 ml-3 mt-4">
          <b-button class="my-2 f-w-600" style="font-size:18px;" variant="outline-primary" block @click="runBill">
            {{ $t('slip.makeReport') }}
          </b-button>      
        </div> 
        <div class="col-sm-3 ml-3 mt-4">
          <b-button class="my-2 f-w-600" style="font-size:18px;" variant="outline-primary" block @click="exportExcel">
            {{ $t('slip.export') }}
          </b-button>      
        </div> 
      </div>  
      <div>
        <div class="row ml-2">
          <h4 class="mb-1 mt-4 f-w-600">{{ $t('slip.slip') }}</h4>
          <h4 class="mb-1 mt-4 mx-2 f-w-600">{{ $t('slip.compNameLocal') }}</h4>
          <h4 class="text-primary mb-1 mt-4 mx-2 f-w-600">{{compNameLocal}}</h4>
        </div>
      <b-table
        sticky-header
        head-variant="light"
        table-variant="info"
        show-empty
        sort-icon-left
        stacked="md"      
        :items="items"
        :fields="fields"
        v-model="visibleRows"
        :current-page="currentPage"
        :per-page="perPage"
      >
        <template slot="bottom-row">
          <td></td><td></td><td></td><td></td>
          <td class="text-right"><span style="font-size: 18px;">{{ $t('slip.total') }} </span></td>
          <!-- this is a computed prop that adds up all the expenses in the visible rows -->
          <td><span style="font-size: 18px;"> {{ totalExpenses }} </span></td>          
          <td></td>
        </template>
        <template #empty>
            <h5>{{ $t('reuse.noRecord') }}</h5>
        </template>
      </b-table>
      <b-row>
        <div class="row col-sm-4 ">
          <b-col class="my-1 ml-3">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              align="fill"
              class="my-0"
            ></b-pagination>
          </b-col>   
          <b-col sm="3" md="6" class="my-1">
            <b-form-group
              :label= "$t('require.perPage')"
              label-for="per-page-select"
              label-cols-sm="8"
              label-cols-md="6"
              label-cols-lg="4"
              label-align-sm="right"
              label-size="sm"
              class="mb-0"
            >
              <b-form-select
                id="per-page-select"
                v-model="perPage"
                :options="pageOptions"
              ></b-form-select>
            </b-form-group>
          </b-col>
        </div>
      </b-row>
    </div>
  </b-container>
  </div>
</template>
<script>
import { slipComp, compNameLocalByCompId} from "../../firebase"
import Datepicker from "vuejs-datepicker";
import {exportXlsx} from "../../utilities/util"
  export default {
    data() {
      return {
        compId : '',
        compNameLocal : '',
        format: "yyyy-MM-dd",
        startDate : '',
        endDate : '',
        items: [],
        fields: [
          { key: 'slipId', label: this.$t('slip.slipId'), sortable: true, },
          { key: 'timestamp', label: this.$t('shipApproval.date'), sortable: true, 
            formatter: value => {
            return this.$moment(value).format("YY-MM-DD @ HH:mm")
          }},
          { key: 'payerId', label: this.$t('slip.payerId'), sortable: true, },
          { key: 'acctType', label: this.$t('slip.acctType'), sortable: true, },   
          { key: 'paymentMethod', label: this.$t('slip.paymentMethod'), sortable: true, },  
          { key: 'amount', label: this.$t('bill.amount'), sortable: true, variant: 'warning'},                     
          { key: 'reason', label: this.$t('slip.reason'), sortable: true,
            formatter: value => {
              return this.$t(`uaTxWallet.${value}`)
          }},
          { key: 'remark', label: this.$t('slip.remark'), sortable: true, },
        ],
        totalRows: 1,
        currentPage: 1,
        perPage: 10,
        pageOptions: [5, 10, 15, { value: 100, text: "Show a lot" }],
        visibleRows: []
      }
    },
    components: {
      Datepicker
    },
    computed: {
      totalExpenses() {
        return this.items.reduce((accum, item) => {
          // Assuming expenses is the field you want to total up
          return accum + parseFloat(item.amount)
        }, 0.00)
      }
    },
    mounted() {
      this.compId= localStorage.getItem("compId")
      this.getCompName(this.compId)
    },
    methods: {
      async runBill(){
        this.items =[]
        let msg = 'completed'
        if (this.startDate ==='') {
            msg = 'slip.addStartDate'
        } else if (this.endDate ===''){
            msg = 'slip.addEndDate'
        }  
        if (msg === 'completed'){       
          await this.setItems(this.compId, this.startDate, this.endDate, (res)=>{
            if (res.length > 0) {
              this.items = res 
              this.totalRows = res.length
            } else {
              this.$toasted.show(this.$t('slip.noData'), {theme: 'bubble', type: 'error' , position: 'bottom-center', duration: 5000}); 
            }
            // console.log("[setItems] res =>"+ JSON.stringify(res, null , 2)) 
          })                
        }else{
            this.$toasted.show(this.$t(msg), {theme: 'bubble', type: 'error' , position: 'bottom-center', duration: 5000});     
        }
      },
      async getCompName(compId){
        this.compNameLocal = await compNameLocalByCompId(compId)
      },
      async setItems (compId , startDate, endDate, cb ){
        let start = this.$moment(startDate).format("YYYY-MM-DD")
        let startAt =   Date.parse(start + ' ' + '00:00:01')
        let end = this.$moment(endDate).format("YYYY-MM-DD")
        let endAt =   Date.parse(end + ' ' + '00:00:01')
        await slipComp( compId, startAt , endAt, async(res)=>{
          cb(res)
        })
      },

      exportExcel() {
        if (this.items.length == 0) {
          this.$toasted.show(this.$t('slip.noData'), {theme: 'bubble', type: 'error' , position: 'bottom-center', duration: 5000}); 
        } else {
          exportXlsx(this.items , `slip-${this.$moment().format("YYYY-MM-DD-HH-mm")}`)
        }
      },
    }
  }
</script>