<template>
	<div>
		<Breadcrumbs main="container.resource" title="container.header"/>
		<!-- Container-fluid starts-->
		<div class="container-fluid">
            <div>
                <div class="row">
                    <h4 class="mb-1 mt-4 ml-3 f-w-600">{{ $t('container.activeContList') }}</h4>
                    <b-button pill href="#addContainer" size="sm" @click="process = 'add' " class="bg-primary mb-4 mt-3 ml-3 ">
                        <span class="f-w-600 text-white" style="font-size: 18px;">{{ $t('container.add') }} </span>
                    </b-button>                    
                    <b-col lg="3" class="ml-3 mt-3">
                        <b-form-group
                        :label="$t('matchTx.filter')"
                        label-for="filter-input"
                        label-cols-sm="3"
                        label-align-sm="right"
                        label-size="lg"
                        class="mb-0"
                        >
                        <b-input-group size="sm">
                            <b-form-input
                            id="filter-input"
                            v-model="filter"
                            type="search"
                            :placeholder="$t('matchTx.typeToSearch')"
                            class="text-info"
                            style="font-size: 18px;"
                            ></b-form-input>
                            <b-input-group-append>
                            <b-button size="md" variant="primary" :disabled="!filter" @click="filter = ''">
                                {{ $t('matchTx.clear') }}
                            </b-button>
                            </b-input-group-append>
                        </b-input-group>
                        </b-form-group>
                    </b-col>
                    <b-col lg="5" class="my-1">
                        <b-form-group
                        v-model="sortDirection"
                        :label="$t('matchTx.filterOn')"
                        :description= "$t('matchTx.uncheck')"
                        label-cols-sm="3"
                        label-align-sm="right"
                        label-size="lg"
                        class="mb-0"
                        v-slot="{ ariaDescribedby }"
                        >
                        <b-form-checkbox-group
                            v-model="filterOn"
                            :aria-describedby="ariaDescribedby"
                            class="mt-1 text-info"
                            plain
                        >
                            <b-form-checkbox value="contNo">{{ $t('matchTx.contNo') }}</b-form-checkbox>                                                                
                            <b-form-checkbox value="owner">{{ $t('matchTx.agent') }}</b-form-checkbox>
                            <b-form-checkbox value="contSize">{{ $t('matchTx.contSize') }}</b-form-checkbox>   
                            <b-form-checkbox value="contType">{{ $t('matchTx.contType') }}</b-form-checkbox>   
                            <b-form-checkbox value="contGrade">{{ $t('matchTx.contGrade') }}</b-form-checkbox>
                        </b-form-checkbox-group>
                        </b-form-group>
                    </b-col>   
                </div>
                <b-table
                    sticky-header
                    head-variant="light"
                    table-variant="success"
                    show-empty
                    sort-icon-left
                    stacked="md"
                    :sort-direction="sortDirection"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    :items="items"
                    :fields="fields"
                    :current-page="currentPage"
                    :per-page="perPage"
                    @filtered="onFiltered"
                >
                    <!-- A virtual column -->
                    <template #cell(index)="data">
                        {{ data.index + 1 }}
                    </template>
                    <template #cell(edit)="row" >
                        <b-button pill size="sm" @click="actUpdate(row.item)" class="m-1">
                           <span class="f-w-600" style="font-size: 16px;"> {{ $t('container.update') }}</span>
                        </b-button>
                    </template>
                    <template #cell(delete)="row" >
                        <b-button pill size="sm" @click="actDelete(row.item)" class="m-1">
                            <span class="f-w-600" style="font-size: 16px;"> {{ $t('container.delete') }}</span>
                        </b-button>
                    </template>

                    <template #cell(contImage)="row" >
                        <b-button size="sm" @click="openModalImage(row.item, row.index, $event.target )" class="m-1">
                            {{ row.item.contNo }}
                        </b-button>
                    </template>
                    <template slot="bottom-row">
                        <td></td>
                        <b-button pill href="#addContainer" size="sm" @click="process = 'add' " class="bg-primary  ml-3 mt-3">
                            <span class="f-w-600 text-white" style="font-size: 18px;">{{ $t('container.add') }} </span>
                        </b-button>
                    </template>
                    <template #empty>
                        <h5>{{ $t('reuse.noRecord') }}</h5>
                    </template>
                </b-table>
                <b-row>
                    <div class="row col-sm-8 ">
                    <b-col class="my-1 ml-3">
                        <b-pagination
                        v-model="currentPage"
                        :total-rows="totalRows"
                        :per-page="perPage"
                        align="fill"
                        class="my-0"
                        ></b-pagination>
                    </b-col>   
                    <b-col sm="3" md="6" class="my-1">
                        <b-form-group
                        :label= "$t('require.perPage')"
                        label-for="per-page-select"
                        label-cols-sm="6"
                        label-cols-md="4"
                        label-cols-lg="3"
                        label-align-sm="right"
                        label-size="sm"
                        class="mb-0"
                        >
                        <b-form-select
                            id="per-page-select"
                            v-model="perPage"
                            :options="pageOptions"
                        ></b-form-select>
                        </b-form-group>
                    </b-col>
                    </div>
                </b-row>
                    <!-- Info modal Container Image-->
                <b-modal id="contModal" :title="modalTitle" ok-only @hide="resetInfoModal">
                    <div class="card me-5" style=" width:15rem">
                        <img  class="card-img-top" :src="imageUriModal" :alt="$t('container.noImg')"/>
                    </div>
                </b-modal>
            </div>
            <b-modal ref="cont-modal" hide-footer :title="$t('container.repeatCont')">
                    <div class="d-block text-center">
                        <h4>{{ $t('container.repeatContMsg') }}</h4>
                    </div>
                    <b-button class="my-2" style="font-size:18px;" variant="outline-warning" block @click="doNotModal">
                        {{ $t('bookingId.doNot') }}
                    </b-button>                            
                    <div class="row " style="align-items:center ">                                                                         
                        <b-button class="mt-3" variant="outline-danger" block @click="overwriteModal">
                            <span class="my-5" style="font-size: 18px;">
                                {{ $t("bookingId.overwrite") }}
                            </span>                                                                             
                        </b-button>                                               
                    </div>   
            </b-modal>            
            <div v-show="process === 'add' " class="row">
                <div id="addContainer" class="col-sm-6">
                    <div class="row ml-3" v-b-hover="handleHover">
                        <h3 class="text-primary mt-3">{{ $t('container.add') }}</h3>
                        <span class="text-danger" style="font-size: 30px; font-weight: bold; cursor: pointer" @click="process = 'info' ">&times;</span>
                        <span v-show="isHovered" class="text-danger">{{ $t('container.cancelReturn')  }} </span>
                    </div>
                    <form class="theme-form">
                        <div class="row">
                            <div class="form-group col-sm-4">
                                <label for="contNo" class="f-w-600 ml-3" style="font-size: 16px">{{ $t("container.contNo") }}</label>                                                 
                                <input  v-model="contNo" class="form-control" type="text" id="contNo" :placeholder="$t('container.contNoPh')" >
                            </div>                            
                            <div class="form-group col-sm-5 ml-3">
                                <label for="owner" class="f-w-600 ml-3" style="font-size: 16px;"  >{{ $t("container.owner") }}</label> 
                                <v-select label="owner"  style="font-size:16px;" v-model="owner" :options="agentList" :placeholder="$t('container.ownerPh')">                                        
                                </v-select>
                            </div>                                
                        </div>
                        <div class="row ml-3">
                            <div class="row">
                                <div class="col-sm-12">
                                    <b-form-group class="f-w-600" style="font-size: 16px"  :label="$t('container.contSize')" v-slot="{ ariaDescribedby }">
                                        <b-form-radio-group
                                            v-model="contSize"
                                            :options="optionsContSize"
                                            :aria-describedby="ariaDescribedby"
                                            name="contSize-options"
                                            plain
                                            stacked
                                            size="lg"
                                        ></b-form-radio-group>
                                    </b-form-group>
                                </div>  
                            </div>
                            <div class="row">
                                <div class="col-sm-12 ml-5">
                                    <b-form-group class="f-w-600" style="font-size: 16px"  :label="$t('container.contType')" v-slot="{ ariaDescribedby }">
                                        <b-form-radio-group
                                            v-model="contType"
                                            :options="optionsContType"
                                            :aria-describedby="ariaDescribedby"
                                            name="contType-options"
                                            plain
                                            stacked
                                            size="lg"
                                        ></b-form-radio-group>
                                    </b-form-group>
                                </div>  
                            </div>
                        </div>
                        <h4 class="text-warning">{{ $t('container.optionalInfo') }}</h4>
                        <div class=" row ml-3">
                            <div class="form-group mt-2">
                                <b-form-group class="f-w-600" style="font-size: 16px" :label="$t('container.contGrade')" v-slot="{ ariaDescribedby }">
                                    <b-form-radio-group
                                        v-model="contGrade"
                                        :options="optionsContGrade"
                                        :aria-describedby="ariaDescribedby"
                                        name="contGrade-options"
                                        size="lg"                                            
                                        plain
                                        stacked
                                    ></b-form-radio-group>
                                </b-form-group>
                            </div> 
                            <div class="form-group ml-5 ">
                                <label for="maxGross" class="col-form-label f-w-600" style="font-size: 16px">{{ $t("container.maxGross") }}</label>
                                <input v-model="maxGross" class="form-control" type="text" id="maxGross" :placeholder="$t('container.maxGrossPh')" >
                            </div>
                            <div class="form-group ml-5">
                                <label for="tare" class="col-form-label  f-w-600" style="font-size: 16px">{{ $t("container.tare") }}</label>
                                <input v-model="tare" class="form-control" type="text" id="tare" :placeholder="$t('container.tarePh')" >
                            </div> 
                            <div class="form-group ml-2">
                                <label for="detDate" class="f-w-600" style="font-size: 16px">{{ $t("container.detDate") }}</label>
                                <datepicker input-class="datepicker-here form-control digits"  :disabled-dates="disabledDates"  :format="format" v-model="detDate" ></datepicker>
                            </div>                            
                        </div>
                        <div class="form-group">
                            <button class="btn btn-primary btn-block" style="font-size:20px"  type="button" @click="handleSave">{{ $t('container.save') }}</button>
                        </div>
                    </form>
                </div>
                <div class="col-sm-6">
                    <div class=" card">
                        <div class="top">
                            <span class="text-primary" style="font-size: 20px;">{{ $t('container.dragDrop') }}</span>
                        </div>
                        <div class="drag-area" @dragover.prevent="onDragOver" @dragleave.prevent="onDragLeave" @drop.prevent="onDrop">  
                            <span class="text-info" v-if="!isDragging">
                                {{ $t('container.dragDropHere') }}
                                    <span class="select" role="button" @click="selectFiles">
                                    {{ $t('container.choose') }}
                                    </span>
                            </span>
                            <div v-else class="select">{{ $t('container.dropHere') }}</div>
                            <!-- <input name="file" type="file"  accept="image/*"  class="file" ref="fileInput" multiple @change="onFileSelect"/> -->
                            <input name="file" type="file" accept="image/*" class="file" ref="fileInput" @change="onFileSelect"/>
                        </div>
                        <div class="container">
                            <div class="image" v-for="(image, index) in images" :key="index" >
                                <span class="delete" @click="deleteImage(index)">&times;</span>
                                <img :src="image.url"/>
                            </div>
                            <!-- <button type="buttom"> Upload</button> -->
                        </div>
                    </div>
                </div>
            </div>
            <div v-show="process === 'update' " class="row">
                <div class="col-sm-6">
                    <div class="row ml-3" v-b-hover="handleHover">
                        <h3 class="text-primary mt-3">{{ $t('container.edit') }}</h3>
                        <span class="text-danger" style="font-size: 30px; font-weight: bold; cursor: pointer" @click="process = 'info' ">&times;</span>
                        <span v-show="isHovered" class="text-danger">{{ $t('container.cancelReturn')  }} </span>
                    </div>
                    <form class="theme-form">
                        <div class="row">
                            <div class="form-group col-sm-4">
                                <label for="contNoUpdate" class="f-w-600 ml-3 text-primary" style="font-size: 16px">{{ $t("container.contNo") }}</label>                                                 
                                <input  v-model="contNoUpdate" class="form-control text-dark bg-secondary" type="text" id="contNoUpdate" disabled>
                            </div>                            
                            <div class="form-group col-sm-5 ml-3">
                                <label for="ownerUpdate" class="f-w-600 ml-3 text-primary" style="font-size: 16px;"  >{{ $t("container.owner") }}</label> 
                                <input  v-model="ownerUpdate" class="form-control text-dark bg-secondary" type="text" id="ownerUpdate" disabled>
                                <!-- <v-select label="ownerUpdate"  style="font-size:16px;" v-model="ownerUpdate" :options="agentList" :placeholder="$t('container.ownerPh')">                                        
                                </v-select> -->
                            </div>                                
                        </div>
                        <div class="row ml-3">
                            <div class="row">
                                <div class="col-sm-12">
                                    <b-form-group class="f-w-600 text-primary" style="font-size: 16px"  :label="$t('container.contSize')" v-slot="{ ariaDescribedby }">
                                        <b-form-radio-group
                                            v-model="contSizeUpdate"
                                            :options="optionsContSize"
                                            :aria-describedby="ariaDescribedby"
                                            name="contSize-options"
                                            plain
                                            stacked
                                            size="lg"
                                        ></b-form-radio-group>
                                    </b-form-group>
                                </div>  
                            </div>
                            <div class="row">
                                <div class="col-sm-12 ml-5">
                                    <b-form-group class="f-w-600 text-primary" style="font-size: 16px"  :label="$t('container.contType')" v-slot="{ ariaDescribedby }">
                                        <b-form-radio-group
                                            v-model="contTypeUpdate"
                                            :options="optionsContType"
                                            :aria-describedby="ariaDescribedby"
                                            name="contType-options"
                                            plain
                                            stacked
                                            size="lg"
                                        ></b-form-radio-group>
                                    </b-form-group>
                                </div>  
                            </div>
                        </div>
                        <h4 class="text-warning">{{ $t('container.completeInfo') }}</h4>
                        <div class=" row ml-3">
                            <div class="form-group mt-2">
                                <b-form-group class="f-w-600 text-primary" style="font-size: 16px" :label="$t('container.contGrade')" v-slot="{ ariaDescribedby }">
                                    <b-form-radio-group
                                        v-model="contGradeUpdate"
                                        :options="optionsContGrade"
                                        :aria-describedby="ariaDescribedby"
                                        name="contGrade-options"
                                        size="lg"                                            
                                        plain
                                        stacked
                                    ></b-form-radio-group>
                                </b-form-group>
                            </div> 
                            <div class="form-group ml-5 ">
                                <label for="maxGrossUpdate" class="col-form-label f-w-600 text-primary" style="font-size: 16px">{{ $t("container.maxGross") }}</label>
                                <input v-model="maxGrossUpdate" class="form-control" type="text" id="maxGrossUpdate" :placeholder="$t('container.maxGrossPh')" >
                            </div>
                            <div class="form-group ml-5">
                                <label for="tareUpdate" class="col-form-label  f-w-600 text-primary" style="font-size: 16px">{{ $t("container.tare") }}</label>
                                <input v-model="tareUpdate" class="form-control" type="text" id="tareUpdate" :placeholder="$t('container.tarePh')" >
                            </div> 
                            <div class="form-group ml-5 mt-2">
                                <label for="detDateUpdate" class="f-w-600 text-primary" style="font-size: 16px">{{ $t("container.detDate") }}</label>
                                <datepicker input-class="datepicker-here form-control digits"  :disabled-dates="disabledDates"  :format="format" v-model="detDateUpdate" ></datepicker>
                            </div>                            
                        </div>
                        <div class="form-group">
                            <button class="btn btn-primary btn-block" style="font-size:20px"  type="button" @click="handleUpdate">{{ $t('container.update') }}</button>
                        </div>
                    </form>
                </div>
                <div class="col-sm-6">
                    <div class=" card">
                        <div class="top">
                            <span class="text-primary" style="font-size: 20px;">{{ $t('container.dragDrop') }}</span>
                        </div>
                        <div class="drag-area" @dragover.prevent="onDragOverUpdate" @dragleave.prevent="onDragLeaveUpdate" @drop.prevent="onDropUpdate">  
                            <span class="text-info" v-if="!isDraggingUpdate">
                                {{ $t('container.dragDropHere') }}
                                    <span class="select" role="button" @click="selectFilesUpdate">
                                    {{ $t('container.choose') }}
                                    </span>
                            </span>
                            <div v-else class="select">{{ $t('container.dropHere') }}</div>
                            <!-- <input name="file" type="file"  accept="image/*"  class="file" ref="fileInput" multiple @change="onFileSelect"/> -->
                            <input name="file" type="file" accept="image/*" class="file" ref="fileInputUpdate" @change="onFileSelectUpdate"/>
                        </div>
                        <div v-if="imagesUpdate.length != 0" class="container">
                            <div class="image" v-for="(image, index) in imagesUpdate" :key="index" >
                                <span class="delete" @click="deleteImageUpdate(index)">&times;</span>
                                <img :src="image.url" :alt="$t('container.noNewImg')"/>
                            </div>
                        </div>
                        <div v-else class="container">
                            <div class="image">
                                <img :src="urlUpdate" :alt="$t('container.noImg')"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <!-- Container-fluid Ends-->
    </div>
</template>
<script>
import { agentList, saveWithId ,contListAll, verifyExstContNo, deleteItem ,uploadImage } from "../../firebase"
import Datepicker from 'vuejs-datepicker';
export default {
	data(){
		return{
            process : "info",
            isHovered: false,
            exstContId: '',
            disabledDates: {
                to: new Date(Date.parse(new Date())-86400000),
            },
            compId : '',
            format: "yyyy-MM-dd",
            agentList : [],
            contNo : '',
            contType: '',
            optionsContType : [
                {text : 'Dry' , value : 'dry'},
                {text : 'Reefer' , value : 'reefer'},
                {text : 'Flat-rack' , value : 'flatRack'},
                {text : 'Open Top' , value : 'openTop'},
                {text : 'Ventilated' , value : 'vent'},
                {text : 'ISO Tank' , value : 'isoTank'}
            ],
            contSize: '',
            optionsContSize : [
                {text : "20'GP" , value : "20GP"},
                {text : "20'DC" , value : "20DC"},
                {text : "20'ST" , value : "20ST"},
                {text : "40'GP" , value : "40GP"},
                {text : "40'ST" , value : "40ST"},
                {text : "40'HC" , value : "40HC"},
                {text : "40'HQ" , value : "40HQ"},
                {text : "45'GP" , value : "45GP"},
                {text : "45'G1" , value : "45G1"},
            ],
            contGrade: '',
            optionsContGrade : [
                {text : 'A' , value : 'A'},
                {text : 'B' , value : 'B'},
                {text : 'C' , value : 'C'}
            ],             
            maxGross: '',
            tare: '',            
            // contCertGrade: '',
            owner: '',
            detDate : '',
            detTS : 0,
            images: [],
            isDragging: false,
            url: '',
            contIdUpdate: '',
            contNoUpdate : '',            
            ownerUpdate: '',
            contTypeUpdate: '',
            contSizeUpdate: '',
            contGradeUpdate: '',
            maxGrossUpdate: '',
            tareUpdate: '',
            detDateUpdate: "",
            detTSUpdate: 0,
            imagesUpdate : [], 
            isDraggingUpdate: false,  
            urlUpdate : '',
            // contCertGradeUpdate: '',
            items: [],
            fields: [
                { key: 'index', label: this.$t('seal.item'), sortable: true, },  
                { key: 'contId', label: this.$t('container.contId'), sortable: true },  
                { key: 'contImage', label: this.$t('container.contNo'), variant: 'info'},                
                // { key: 'contNo', label: this.$t('release.contNo'), sortable: true, },                   
                { key: 'owner', label: this.$t('release.agent'), sortable: true },  
                { key: 'contType', label: this.$t('release.contType'), sortable: true },
                { key: 'contSize', label: this.$t('release.contSize'), sortable: true},
                { key: 'contGrade', label: this.$t('release.contGrade'), sortable: true},
                { key: 'maxGross', label: this.$t('container.maxGross'), sortable: true},
                { key: 'tare', label: this.$t('container.tare'), sortable: true },
                { key: 'detDate', label: this.$t('require.detDate'), sortable: true, },                  
                // { key: 'contCertGrade', label: this.$t('container.contCertGrade'), sortable: true},
                { key: 'edit', label: this.$t('container.edit'), variant: 'warning'},
                { key: 'delete', label: this.$t('matchTx.actions'), variant: 'danger'}
            ],
            totalRows: 1,
            currentPage: 1,
            perPage: 5,
            pageOptions: [5, 10, 15, { value: 100, text: "Show a lot" }],
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
            imageUriModal: '',
            modalTitle : ''
		}
	},
	components: {
		Datepicker
	},
    computed: {
      sortOptions() {
        // Create an options list from our fields
        return this.fields
          .filter(f => f.sortable)
          .map(f => {
            return { text: f.label, value: f.key }
          })
      },
    },
	mounted(){
        this.compId= localStorage.getItem("compId")
        agentList((res)=>{
            this.agentList = res
            console.log(`this.agentList =>${JSON.stringify(this.agentList , null ,2)}`)
        })
        contListAll(this.compId , (res)=>{
            this.items = res
            this.totalRows = res.length
        })
	},
	methods:{
        onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length
        this.currentPage = 1
      },
        handleHover(hovered) {
            this.isHovered = hovered
        },
        showModal() {
            this.$refs['cont-modal'].show()         
        },
        async overwriteModal() {
            await this.overwriteAtContId(this.exstContId).then(()=>{ 
                this.exstContId = '' //cleanup if next roumd again.
                this.$refs['cont-modal'].hide()            
            })
        },
        async doNotModal() {
            this.exstContId = ''
            await this.refresh()
            this.$refs['cont-modal'].hide()
        },
        async saveCont_noImg(){
            let d = new Date()
            let yyyy = d.getFullYear().toString()
            let contId = yyyy.substring(2,4)+'CN'+`${Math.floor(Math.random() * (9000000 - 1000000 + 1) + 1000000)}`
            let detDate = this.detDate == '' ? '' : this.$moment(this.detDate).format("YYYY-MM-DD")
            let detTS =   this.detDate == '' ? 0 : Date.parse(detDate + ' ' + '23:59:59')
            let payload = {
                compId: this.compId,
                contId : contId,
                contNo : this.contNo,
                contType: this.contType,
                contSize: this.contSize,
                maxGross: this.maxGross,
                tare: this.tare,            
                contGrade: this.contGrade,
                // contCertGrade: this.contCertGrade,
                owner: this.owner,
                detDate : detDate,
                detTS : detTS,
                imageUri : this.url,
                timestamp: Date.parse(d),
                active: true
            }
            // console.log ('payload=>'+JSON.stringify(payload , null, 2))
            await saveWithId('Container', contId, payload).then(async()=>{
                await this.refresh()
                this.$toasted.show(this.$t('reuse.successSave'), {theme: 'bubble', position: 'bottom-center', type: 'success' , duration: 5000});                                                    
            })              
        },
        async saveCont(){
            await uploadImage('container', this.images[0], async(url)=>{
                this.url = url
                let d = new Date()
                let yyyy = d.getFullYear().toString()
                let contId = yyyy.substring(2,4)+'CN'+`${Math.floor(Math.random() * (9000000 - 1000000 + 1) + 1000000)}`
                let detDate =  this.detDate == '' ? '' : this.$moment(this.detDate).format("YYYY-MM-DD")
                let detTS =  this.detDate == '' ? 0 : Date.parse(detDate + ' ' + '23:59:59')
                let payload = {
                    compId: this.compId,
                    contId : contId,
                    contNo : this.contNo,
                    contType: this.contType,
                    contSize: this.contSize,
                    maxGross: this.maxGross,
                    tare: this.tare,            
                    contGrade: this.contGrade,
                    // contCertGrade: this.contCertGrade,
                    owner: this.owner,
                    detDate : detDate,
                    detTS : detTS,
                    imageUri : url,
                    timestamp: Date.parse(d),
                    active: true
                }
                // console.log ('payload=>'+JSON.stringify(payload , null, 2))
                await saveWithId('Container', contId, payload).then(async()=>{
                    await this.refresh()
                    this.$toasted.show(this.$t('reuse.successSave'), {theme: 'bubble', position: 'bottom-center', type: 'success' , duration: 5000});                                                    
                })
            })                
        },
        async overwriteAtContId(contId){
            await uploadImage('container', this.images[0], async(url)=>{
                this.url = url
                // console.log(`url => ${url}`)
                let detDate =  this.detDate == '' ? '' : this.$moment(this.detDate).format("YYYY-MM-DD")
                let detTS =  this.detDate == '' ? 0 : Date.parse(detDate + ' ' + '23:59:59')
                let payload = {
                    compId: this.compId,
                    contId : contId,
                    contNo : this.contNo,
                    contType: this.contType,
                    contSize: this.contSize,
                    maxGross: this.maxGross,
                    tare: this.tare,            
                    contGrade: this.contGrade,
                    // contCertGrade: this.contCertGrade,
                    owner: this.owner,
                    detDate : detDate,
                    detTS : detTS,
                    imageUri : url,
                    timestamp: Date.parse(new Date()),
                    active: true
                }
                // console.log ('payload=>'+JSON.stringify(payload , null, 2))
                await saveWithId('Container', contId, payload).then(async()=>{
                    await this.refresh()
                    this.$toasted.show(this.$t('reuse.successSave'), {theme: 'bubble', position: 'bottom-center', type: 'success' , duration: 5000});                                                    
                })
            })                
        },
        async handleSave(){
            let msg = 'completed'
            if (this.contNo ==='') {
                msg = 'container.addContNo'
            } else if (this.contType ===''){
                msg = 'container.selContType'
            } else if (this.contSize ===''){
                msg = 'container.selContSize'
            // } else if (this.maxGross ===''){
            //     msg = 'container.addMaxGross'
            // } else if (this.tare ===''){
            //     msg = 'container.addTare'
            // } else if (this.contGrade ===''){
            //     msg = 'container.selContGrade'
            // } else if (this.contCertGrade ===''){
            //     msg = 'container.selContCertGrade'
            } else if (this.owner ===''){
                msg = 'container.addOwner'
            // } else if (this.detDate ===''){
            //     msg = 'container.addDetDate'
            // } else if (this.images.length === 0){
            //     msg = 'container.addImage'
            // } else if (this.images.length > 1){
            //     msg = 'container.oneImageOnly'
            }  
            if (msg === 'completed'){
                // verfy for existing BookingId? If not, process saveBkgId.
                verifyExstContNo(this.contNo , async(res)=>{
                    if (res) {
                        this.exstContId = res
                        this.showModal()
                    } else {
                        if (this.images.length === 0) {
                            this.saveCont_noImg()
                        } else {
                            if (this.images.length > 1) {
                                this.$toasted.show(this.$t('container.oneImageOnly'), {theme: 'bubble', type: 'error' , position: 'bottom-center', duration: 10000});    
                            } else {
                               this.saveCont() 
                            }
                        }                      
                    }
                })
            }else{
                this.$toasted.show(this.$t(msg), {theme: 'bubble', type: 'error' , position: 'bottom-center', duration: 5000});     
            }
        },
        selectFiles(){
            this.$refs.fileInput.click()
        },
        onFileSelect(event){
            const files = event.target.files;
            console.log(`files_1 => ${JSON.stringify(event.target.files , null , 2)}`)
            if (files.length === 0) return 
            for (let i = 0; i < files.length; i++) {
                console.log(`Container, select,files[i] => ${files[i].type}`)
                if (files[i].type.split("/")[0] != "image") continue;
                if (!this.images.some((e)=> e.name === files[i].name)){
                  this.images.push({name:files[i].name, url: URL.createObjectURL(files[i])});  // for Multiple images
                  //this.images[0] = {name:files[i].name, url: URL.createObjectURL(files[i])}  // replace for 1 last image only.
                }
            }
            console.log(`images => ${JSON.stringify(this.images , null , 2)}`)
        },
        deleteImage(index){
            this.images.splice(index , 1)
        },
        onDragOver(event){
            event.preventDefault();
            this.isDragging = true
            event.dataTransfer.dropEffect = "copy";
        },
        onDragLeave(event){
            event.preventDefault();
            this.isDragging = false
        },
        onDrop(event){
            event.preventDefault();
            this.isDragging = false;
            const files = event.dataTransfer.files;
            for (let i = 0; i < files.length; i++) {
                console.log(`Container,DragDrop files[i]Type => ${files[i].type}`)
                if (files[i].type.split("/")[0] != "image") continue;
                if (!this.images.some((e)=> e.name === files[i].name)){
                    // this.images.push({name:files[i].name, url: URL.createObjectURL(files[i])});  // for Multiple images
                    this.images[0] = {name:files[i].name, url: URL.createObjectURL(files[i])} // replace for 1 last image only.
                }
            }
        },

        actDelete(item) {
            this.$bvModal.msgBoxConfirm(this.$t('container.confirmDeleteMsg'), {
            title: this.$t('matchTx.plsConfirm'),
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'warning',
            okTitle: this.$t('matchTx.yes'),
            cancelTitle: this.$t('matchTx.no'),
            footerClass: 'p-2',
            hideHeaderClose: false,
            centered: true
            })
            .then(async(value) => {
                if (value) {
                    await deleteItem( "Container", item.contId)
                    await this.refresh()                    
                    this.$toasted.show( this.$t('container.deleted'), {theme: 'bubble', type: 'error' , position: 'bottom-center', duration: 5000});  
                } else {
                return
                }
            })
            .catch(error => {
                this.$toasted.show( `Action-Delete: ${error}`, {theme: 'bubble', type: 'error' , position: 'bottom-center', duration: 5000});  
            })
        },    
        async refresh(){
            await contListAll(this.compId , (res)=>{
                this.items = res
                this.totalRows = res.length
                this.contNo = ''
                this.contType = ''
                this.contSize = ''
                this.maxGross = ''
                this.tare= ''       
                this.contGrade= ''
                // this.contCertGrade= ''
                this.owner = ''
                this.detDate = ''
                this.detDateTS = 0
                this.images = []
            })
        },

        openModalImage(item, index, button) {
            this.imageUriModal = item.imageUri
            this.modalTitle = item.contNo
            this.$root.$emit('bv::show::modal', "contModal" , button)
        },
        resetInfoModal() {
            this.modalTitle = ''
            this.imageUriModal = ''
        },

        async handleUpdate(){
            let msg = 'completed'
            if (this.contTypeUpdate ===''){
                msg = 'container.selContType'
            } else if (this.contSizeUpdate ===''){
                msg = 'container.selContSize'
            } else if (this.maxGrossUpdate ===''){
                msg = 'container.addMaxGross'
            } else if (this.tareUpdate ===''){
                msg = 'container.addTare'
            } else if (this.contGradeUpdate ===''){
                msg = 'container.selContGrade'
            // } else if (this.contCertGrade ===''){
            //     msg = 'container.selContCertGrade'
            } else if (this.detDateUpdate ===''){
                msg = 'container.addDetDate'
            }  
            if (msg === 'completed'){
                let imgUpdateQty = this.imagesUpdate.length 
                if (imgUpdateQty === 0) {
                    if (this.urlUpdate == '') {
                        this.$toasted.show(this.$t('container.addImage'), {theme: 'bubble', type: 'error' , position: 'bottom-center', duration: 10000});     
                    } else {
                        this.updateContExstImg() 
                    }
                } else {
                    if (imgUpdateQty > 1) {
                        this.$toasted.show(this.$t('container.oneImageOnly'), {theme: 'bubble', type: 'error' , position: 'bottom-center', duration: 10000});     
                    } else {
                        this.updateContNewImg()    
                    }
                }
            }else{
                this.$toasted.show(this.$t(msg), {theme: 'bubble', type: 'error' , position: 'bottom-center', duration: 5000});     
            }
        },
        async updateContExstImg(){
            let detDate =  this.detDateUpdate == '' ? '' : this.$moment(this.detDateUpdate).format("YYYY-MM-DD")
            let detTS = this.detDateUpdate == '' ? 0 :  Date.parse(detDate + ' ' + '23:59:59')
            let payload = {
                compId: this.compId,
                contId : this.contIdUpdate,
                contNo : this.contNoUpdate,
                contType: this.contTypeUpdate,
                contSize: this.contSizeUpdate,
                maxGross: this.maxGrossUpdate,
                tare: this.tareUpdate,            
                contGrade: this.contGradeUpdate,
                // contCertGrade: this.contCertGradeUpdate,
                owner: this.ownerUpdate,
                detDate : detDate,
                detTS : detTS,
                imageUri : this.urlUpdate,
                timestamp: Date.parse(new Date()),
                active: true
            }
            console.log ('payload_updateContExstImg=>'+JSON.stringify(payload , null, 2))
            await saveWithId('Container', this.contIdUpdate, payload).then(async()=>{
                await this.refreshUpdate()
                this.$toasted.show(this.$t('reuse.successSave'), {theme: 'bubble', position: 'bottom-center', type: 'success' , duration: 5000});                                                    
            })
        },
        async updateContNewImg(){
            await uploadImage('container', this.imagesUpdate[0], async(url)=>{
                this.urlUpdate = url
                let detDate = this.detDateUpdate == '' ? '' :  this.$moment(this.detDateUpdate).format("YYYY-MM-DD")
                let detTS = this.detDateUpdate == '' ? 0 :  Date.parse(detDate + ' ' + '23:59:59')
                let payload = {
                    compId: this.compId,
                    contId : this.contIdUpdate,
                    contNo : this.contNoUpdate,
                    contType: this.contTypeUpdate,
                    contSize: this.contSizeUpdate,
                    maxGross: this.maxGrossUpdate,
                    tare: this.tareUpdate,            
                    contGrade: this.contGradeUpdate,
                    // contCertGrade: this.contCertGradeUpdate,
                    owner: this.ownerUpdate,
                    detDate : detDate,
                    detTS : detTS,
                    imageUri : url,
                    timestamp: Date.parse(new Date()),
                    active: true
                }
                console.log ('payload_updateContNewImg=>'+JSON.stringify(payload , null, 2))
                await saveWithId('Container', this.contIdUpdate, payload).then(async()=>{
                    await this.refreshUpdate()
                    this.$toasted.show(this.$t('reuse.successSave'), {theme: 'bubble', position: 'bottom-center', type: 'success' , duration: 5000});                                                    
                })
            })                
        },
        async refreshUpdate(){
            await contListAll(this.compId , (res)=>{
                this.items = res
                this.totalRows = res.length
                this.contIdUpdate = ''
                this.contNoUpdate = ''
                this.contTypeUpdate = ''
                this.contSizeUpdate = ''
                this.maxGrossUpdate = ''
                this.tareUpdate= ''       
                this.contGradeUpdate= ''
                // this.contCertGradeUpdate= ''
                this.ownerUpdate = ''
                this.detDateUpdate = ''
                this.detDateTSUpdate = 0
                this.imagesUpdate = []
                this.process = 'info'  // close update submenu
            })
        },

        actUpdate(item) {
            this.contIdUpdate = item.contId
            this.contNoUpdate = item.contNo,            
            this.ownerUpdate = item.owner,
            this.contTypeUpdate = item.contType,
            this.contSizeUpdate = item.contSize,
            this.contGradeUpdate = item.contGrade,
            this.maxGrossUpdate = item.maxGross,
            this.tareUpdate = item.tare,
            this.detDateUpdate = item.detDate,
            this.detTSUpdate = item.detTS,
            this.urlUpdate = item.imageUri  
            this.process = "update"   // to pop up Update subMenu
            console.log(`urlUpdate => ${JSON.stringify(item , null , 2)}`)
        }, 
        selectFilesUpdate(){
            this.$refs.fileInputUpdate.click()
        },
        onFileSelectUpdate(event){
            const files = event.target.files;
            // console.log(`files_update => ${JSON.stringify(event.target.files , null , 2)}`)
            if (files.length === 0) return 
            for (let i = 0; i < files.length; i++) {
                // console.log(`Container, select,files[i],Update => ${files[i].type}`)
                if (files[i].type.split("/")[0] != "image") continue;
                if (!this.imagesUpdate.some((e)=> e.name === files[i].name)){
                  this.imagesUpdate.push({name:files[i].name, url: URL.createObjectURL(files[i])});  // for Multiple images
                  //this.images[0] = {name:files[i].name, url: URL.createObjectURL(files[i])}  // replace for 1 last image only.
                }
            }
            // console.log(`images => ${JSON.stringify(this.imagesUpdate , null , 2)}`)
        },
        deleteImageUpdate(index){
            this.imagesUpdate.splice(index , 1)
        },
        onDragOverUpdate(event){
            event.preventDefault();
            this.isDraggingUpdate = true
            event.dataTransfer.dropEffect = "copy";
        },
        onDragLeaveUpdate(event){
            event.preventDefault();
            this.isDraggingUpdate = false
        },
        onDropUpdate(event){
            event.preventDefault();
            this.isDraggingUpdate = false;
            const files = event.dataTransfer.files;
            for (let i = 0; i < files.length; i++) {
                console.log(`Container,DragDrop files[i]Type, Update => ${files[i].type}`)
                if (files[i].type.split("/")[0] != "image") continue;
                if (!this.imagesUpdate.some((e)=> e.name === files[i].name)){
                    // this.images.push({name:files[i].name, url: URL.createObjectURL(files[i])});  // for Multiple images
                    this.imagesUpdate[0] = {name:files[i].name, url: URL.createObjectURL(files[i])} // replace for 1 last image only.
                }
            }
        },
    }
}
</script>
<style scoped>
.card{
    width:100%;
    padding:10px;
    box-shadow: 0 0 5px #ffdfdf;
    border-radius: 5px;
    overflow: hidden;
}
.card .top{
    text-align: center;
}
.card p{
    font-weight: bold;
    color: #fe0000;
}
.card button{
    outline: 0;
    border: 0;
    color : #ffff;
    border-radius: 4px;
    font-weight: 40;
    padding: 8px 13px;
    width: 100%;
    background: #fe0000;
}
.card .drag-area{
    height : 100px;
    border-radius: 5px;
    border: 2px dashed #00d4fe;
    background: #f4f3f9;
    color : #fe0000;
    display:flex;
    justify-content: center;
    align-items: center;
    user-select: center;
    -webkit-user-select: none;
    margin-top: 5px;
}
.card .darg-area .visible{
    font-size: 18px
}
.card .select{
    color: #5256ad;
    margin-left: 5px;
    cursor: pointer;
    transition: 0.4s;
}
.card .select :hover{
    opacity: 0.6;
}
.card .container{
    height : auto;
    width: 100%;
    display:flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    max-height: 450px;
    position: relative;
    margin-bottom: 8px;
    padding-top: 15px;
}
.card .container .image{
    width: 480px;
    margin-right: 5px;
    height: 360px;
    position: relative;
    margin-bottom: 8px;
}
.card .container .image img{
    width: 100%;
    height: 100%;
    border-radius: 5px;
}
.card .container .image span{
    position:absolute;
    top: -2px;
    right: 9px;
    font-size: 30px;
    cursor: pointer;
}
.card input,
.card .drsg-area .on-drop,
.card .drag-area.dragover .visible{
    display: none;
}
.delete{
    z-index: 999;
    color:#fe0000;
}
.style-chooser .vs__search::placeholder,
.style-chooser .vs__dropdown-toggle,
.style-chooser .vs__dropdown-menu {
  background: #dfe5fb;
  border: none;
  color: #394066;
  /* text-transform: lowercase;
  font-variant: small-caps; */
}

.style-chooser .vs__clear,
.style-chooser .vs__open-indicator {
  fill: #394066;
}
</style>
