<template>
  <div>
    <Breadcrumbs main="matchTx.dashboard" title="matchTx.header" />
    <b-container fluid>
      <!-- <h3 class="mb-1 mt-4 f-w-600">{{ $t('matchTx.header') }}</h3> -->
      <div>
          <div class="d-flex flex-row justify-content-between mx-1"> 
            <div>
              <h4 class="mb-1 mt-1 ml-3 f-w-600">{{ $t('matchTx.reqrReceiver') }}</h4> 
            </div>
            <div>
              <b-dropdown id="dropdown-left" size="md" :text=" $t('matchTx.exportData')" variant="info" class="m-2">
                <b-dropdown-text style="width: 300px;">
                  {{ $t('matchTx.exportDataMsg') }}
                </b-dropdown-text>
                <b-dropdown-divider></b-dropdown-divider>
                <b-dropdown-divider></b-dropdown-divider>
                <b-dropdown-item class="m-2" style="font-size: 20px;"  @click="back7days">{{ $t('matchTx.back7days') }}</b-dropdown-item>
                <b-dropdown-divider></b-dropdown-divider>
                <b-dropdown-item class="m-2" style="font-size: 20px;"  @click="back30days" >{{ $t('matchTx.back30days') }}</b-dropdown-item>
                <b-dropdown-divider></b-dropdown-divider>
                <b-dropdown-item  class="m-2"  style="font-size: 20px;" @click="inYear">{{ $t('matchTx.inYear') }}</b-dropdown-item>
              </b-dropdown>
            </div>          
          </div>
          <b-row>
            <b-col lg="5" class="my-1">
              <b-form-group
                :label="$t('matchTx.sort')"
                label-for="sort-by-select-reqr"
                label-cols-sm="3"
                label-align-sm="right"
                label-size="lg"
                class="mb-0"
                v-slot="{ ariaDescribedbyReqr }"
              >
                <b-input-group size="lg">
                  <b-form-select
                    id="sort-by-select-reqr"
                    v-model="sortByReqr"
                    :options="sortOptionsReqr"
                    :aria-describedby="ariaDescribedbyReqr"
                    class="w-70 text-info"
                  >
                    <template #first>
                      <option value="">-- none --</option>
                    </template>
                  </b-form-select>

                  <b-form-select
                    v-model="sortDescReqr"
                    :disabled="!sortByReqr"
                    :aria-describedby="ariaDescribedbyReqr"
                    size="lg"
                    class="w-30 text-info"
                  >
                    <option :value="false">{{$t('matchTx.asc')}}</option>
                    <option :value="true">{{$t('matchTx.desc')}}</option>
                  </b-form-select>
                </b-input-group>
              </b-form-group>
            </b-col>

            <b-col lg="5" class="my-1">
              <b-form-group
                :label="$t('matchTx.initSort')"
                label-for="initial-sort-select-reqr"
                label-cols-sm="3"
                label-align-sm="right"
                label-size="lg"
                class="mb-0"
              >
                <b-form-select
                  id="initial-sort-select-reqr"
                  v-model="sortDirectionReqr"
                  :options="['asc', 'desc', 'last']"
                  size="lg"
                  class="text-info"
                ></b-form-select>
              </b-form-group>
            </b-col>
            <b-col lg="5" class="my-1">
              <b-form-group
                :label="$t('matchTx.filter')"
                label-for="filter-input-reqr"
                label-cols-sm="3"
                label-align-sm="right"
                label-size="lg"
                class="mb-0"
              >
                <b-input-group size="sm">
                  <b-form-input
                    id="filter-input-reqr"
                    v-model="filterReqr"
                    type="search"
                    :placeholder="$t('matchTx.typeToSearch')"
                    class="text-info"
                    style="font-size: 18px;"
                  ></b-form-input>
                  <b-input-group-append>
                    <b-button size="md" variant="primary" :disabled="!filterReqr" @click="filterReqr = ''">
                      {{ $t('matchTx.clear') }}
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col lg="5" class="my-1">
              <b-form-group
                v-model="sortDirectionReqr"
                :label="$t('matchTx.filterOn')"
                :description= "$t('matchTx.uncheck')"
                label-cols-sm="3"
                label-align-sm="right"
                label-size="lg"
                class="mb-0"
                v-slot="{ ariaDescribedbyReqr }"
              >
                <b-form-checkbox-group
                  v-model="filterOnReqr"
                  :aria-describedby="ariaDescribedbyReqr"
                  class="mt-1 text-info"
                  plain
                >
                  <b-form-checkbox value="agent">{{ $t('matchTx.agent') }}</b-form-checkbox>
                  <b-form-checkbox value="bkgId">{{ $t('matchTx.bkgId') }}</b-form-checkbox>
                  <b-form-checkbox value="contNo">{{ $t('matchTx.contNo') }}</b-form-checkbox>     
                  <b-form-checkbox value="contSize">{{ $t('matchTx.contSize') }}</b-form-checkbox>   
                  <b-form-checkbox value="contType">{{ $t('matchTx.contType') }}</b-form-checkbox>   
                  <b-form-checkbox value="contGrade">{{ $t('matchTx.contGrade') }}</b-form-checkbox>
                  <b-form-checkbox value="sealNo">{{ $t('matchTx.sealNo') }}</b-form-checkbox>  
                </b-form-checkbox-group>
              </b-form-group>
            </b-col>
          </b-row>
        <b-table
          sticky-header
          head-variant="light"
          table-variant="info"
          show-empty
          sort-icon-left
          stacked="md"
          :sort-by.sync="sortByReqr"
          :sort-desc.sync="sortDescReqr"
          :sort-direction="sortDirectionReqr"
          :filter="filterReqr"
          :filter-included-fields="filterOnReqr"
          :items="itemsReqr"
          :fields="fieldsReqr"
          :current-page="currentPageReqr"
          :per-page="perPageReqr"
          @filtered="onFilteredReqr"
        >
          <template #cell(contImageReqr)="row" >
            <b-button  size="md" @click="openModalImageReqr(row.item, row.index, $event.target )" class="m-1">
                {{ row.item.contNo }}
            </b-button>
          </template>
          <template #cell(actReqr)="row" >
            <b-button  v-show="row.item.actReqr.cancel" size="md" variant="danger" @click="actReqrCancel(row.item)" class="m-1 ">
              {{ $t('matchTx.cancel') }}
            </b-button>
            <b-button  v-show="row.item.actReqr.accept" size="md" variant="success" @click="actReqrAccept(row.item)" class="m-1">
              {{ $t('matchTx.accept') }}
            </b-button>
            <b-button  v-show="row.item.actReqr.reject" size="md" variant="danger" @click="actReqrReject(row.item)" class="m-1">
              {{ $t('matchTx.reject') }}
            </b-button>
            <b-button  v-show="row.item.actReqr.attachSeal" size="md" variant="info" @click="actReqrAttachSeal(row.item)" class="m-1">
              {{ $t('matchTx.attachSeal') }}
            </b-button>
            <b-button  v-show="row.item.actReqr.updateSeal" size="md" variant="warning" @click="actReqrUpdateSeal(row.item)" class="m-1">
              {{ $t('matchTx.updateSeal') }}
            </b-button>
            <b-button  v-show="row.item.actReqr.terminate" size="md" variant="danger" @click="actReqrTerminate(row.item)" class="m-1">
              {{ $t('matchTx.terminate') }}
            </b-button>
            <b-button  v-show="row.item.actReqr.transfer" size="md" variant="warning" @click="actReqrTransfer(row.item)" class="m-1">
              {{ $t('matchTx.transfer') }}
            </b-button>
            <b-button  v-show="row.item.actReqr.eir" size="md" variant="info" @click="actReqrEir(row.item)" class="m-1">
              {{ $t('matchTx.eir') }}
            </b-button>
            <b-button v-show="row.item.jbReqr == 'shipsRej'" size="md" variant="warning" @click="openModalViewShipsRejectReqr(row.item, row.index, $event.target )" class="m-1">
              {{ $t('matchTx.viewShipsReject') }}
            </b-button>
          </template>
          <template #empty>
              <h5>{{ $t('reuse.noRecord') }}</h5>
          </template>
        </b-table>
        <b-row>
          <div class="row col-sm-4 ">
            <b-col class="my-1 ml-3">
              <b-pagination
                v-model="currentPageReqr"
                :total-rows="totalRowsReqr"
                :per-page="perPageReqr"
                align="fill"
                class="my-0"
              ></b-pagination>
            </b-col>   
            <b-col sm="3" md="6" class="my-1">
              <b-form-group
                :label= "$t('require.perPage')"
                label-for="per-page-select"
                label-cols-sm="6"
                label-cols-md="4"
                label-cols-lg="3"
                label-align-sm="right"
                label-size="sm"
                class="mb-0"
              >
                <b-form-select
                  id="per-page-select"
                  v-model="perPageReqr"
                  :options="pageOptionsReqr"
                ></b-form-select>
              </b-form-group>
            </b-col>
          </div>
        </b-row>
        <!-- Info modal Reqr Attch Seal-->
        <div>
          <!-- <b-button @click="showSealNoModal=true" variant="primary">Show Modal</b-button> -->
          <b-modal
            id="reqrEir-modal" 
            :title="this.$t('matchTx.eirTitle')"
            v-model="showEirModal"
            header-bg-variant="warning"
            header-text-variant="dark"
            body-bg-variant="light"
            body-text-variant="dark"
            size="lg"
            hide-footer
          >
            <b-form inline>
              <label class="mx-2">{{$t('matchTx.refNo')}}</label> 
              <h5> {{ selected_mTxId }}-EIR</h5> 
            </b-form>
            <b-col>
              <b-form inline>
                <label class="mx-2" >{{ $t('matchTx.compNameLocalRelsEir') }}</label>
                <b-form-input class="text-primary"
                  v-model="selectedCompNameLocalRels"
                ></b-form-input>                  
              </b-form>
              <b-input-group prepend="@" class="mb-4 mr-sm-2 mb-sm-0">
                <b-form-input class="text-primary"
                  v-model="selectedContRelsAddress"
                ></b-form-input>                  
              </b-input-group>                
            </b-col>
            <b-col>
              <b-form inline>
                <label class="mx-2" >{{ $t('matchTx.compNameLocalReqrEir') }}</label>
                <b-form-input class="text-primary"
                  v-model="selectedCompNameLocalReqr"
                ></b-form-input>                  
              </b-form>
              <b-input-group prepend="@" class="mb-2 mr-sm-2 mb-sm-0">
                <b-form-input class="text-primary"
                  v-model="selectedContReqrAddress"
                ></b-form-input>                  
              </b-input-group>
            </b-col>
            <div class="divider my-2"> </div>
            <b-row>
              <b-col>
                <label class="mx-2">{{$t('matchTx.contNoEir')}} : </label> 
                <h5 class="ml-2"> {{ selectedContNo }}</h5> 
              </b-col>
              <b-col>
                <label class="mx-2">{{$t('matchTx.typeSize')}} : </label> 
                <h5 class="ml-2"> {{selectedContType}} : {{ selectedContSize }}</h5> 
              </b-col>
              <b-col>
                <label class="mx-2">{{$t('matchTx.sealNoEir')}} : </label> 
                <h5 class="ml-2"> {{selectedSealNo}} </h5> 
              </b-col>
              <b-col>
                <label class="mx-2">{{$t('matchTx.tareEir')}} : </label> 
                <h5 class="ml-2"> {{selectedContTare}} </h5> 
              </b-col>
            </b-row>
            <b-row class="mt-2">
              <b-col>
                <label class="mx-2">{{$t('matchTx.bkgIdEir')}} : </label> 
                <h5 class="ml-2"> {{ selectedBkgId }}</h5> 
              </b-col>
              <b-col>
                <label class="mx-2">{{$t('matchTx.agentEir')}} : </label> 
                <h5 class="ml-2"> {{selectedAgent}} </h5> 
              </b-col>
            </b-row>
            <div class="divider my-2"> </div>
            <b-row>
              <b-col class="ml-2">
                <label class="mx-2 text-danger f-w-800" style="font-size: 16px;" >{{$t('matchTx.remark')}} : </label> 
                <b-form-input class="text-primary"
                  v-model="remark"
                ></b-form-input> 
              </b-col>
            </b-row>
            <b-col>
              <b-form inline>
                <label class="mx-2" >{{ $t('matchTx.inspector') }}</label>
                <b-form-input class="text-primary"
                  v-model="selectedCompNameLocalInsp"
                ></b-form-input>                  
              </b-form>
              <b-input-group prepend="@" class="mb-4 mr-sm-2 mb-sm-0">
                <b-form-input class="text-primary"
                  v-model="selectedContInspAddress"
                ></b-form-input>                  
              </b-input-group>               
            </b-col>
            <div class="divider"> </div>
            <div >
              <b-button
                variant="primary"
                size="md"
                class="float-right"
                @click="clickEir_Issue"
              >
              {{ $t('matchTx.issue') }}
              </b-button>              
              <b-button
                variant="warning"
                size="md"
                class="float-right"
                @click="clickEir_NO"
              >
                {{ $t('matchTx.no') }}
              </b-button>
            </div>
          </b-modal>
          <b-modal
            :id="actReqrAttachSealModal.id" 
            :title="actReqrAttachSealModal.title"
            v-model="showSealNoModal"
            header-bg-variant="info"
            header-text-variant="dark"
            body-bg-variant="light"
            body-text-variant="dark"
            hide-footer
          >
            <b-row class="mb-1">
              <b-col cols="4">{{ $t('matchTx.selSealNo') }}</b-col>
              <b-col>
                <b-form-select
                  v-model="selectedSealNo"
                  :options="sealNos"
                ></b-form-select>
              </b-col>
            </b-row>
            <div class="w-100">
              <span class="float-left">{{$t('matchTx.sealNo')}} : </span> <span> {{ selectedSealNo }}</span> 
              <b-button
                variant="primary"
                size="sm"
                class="float-right"
                @click="clickAttachSeal_OK"
              >
                OK
              </b-button>
            </div>
          </b-modal>
          <b-modal
            :id="actReqrUpdateSealModal.id" 
            :title="actReqrUpdateSealModal.title"
            v-model="showUpdateSealNoModal"
            header-bg-variant="info"
            header-text-variant="dark"
            body-bg-variant="light"
            body-text-variant="dark"
            hide-footer
          >
            <b-row class="mb-1">
              <b-col cols="4">{{ $t('matchTx.selSealNo') }}</b-col>
              <b-col>
                <b-form-select
                  v-model="selectedSealNo"
                  :options="sealNos"
                ></b-form-select>
              </b-col>
            </b-row>
            <div class="w-100">
              <span class="float-left">{{$t('matchTx.sealNo')}} : </span> <span> {{ selectedSealNo }}</span> 
              <b-button
                variant="primary"
                size="sm"
                class="float-right"
                @click="clickUpdateSeal_OK"
              >
                OK
              </b-button>
            </div>
          </b-modal>
          <b-modal
            :id="actReqrTransferModal.id" 
            :title="actReqrTransferModal.title"
            v-model="showTransferModal"
            header-bg-variant="info"
            header-text-variant="dark"
            body-bg-variant="light"
            body-text-variant="dark"
            hide-footer
          >
            <b-row class="mb-1">
              <b-col cols="4">{{ $t('matchTx.selBkgId') }}</b-col>
              <b-col>
                <b-form-select
                  v-model="selectedBkgId"
                  :options="bkgIds"
                ></b-form-select>
              </b-col>
            </b-row>
            <div class="w-100">
              <span class="float-left">{{$t('matchTx.bkgId')}} : </span> <span> {{ selectedBkgId }}</span> 
              <b-button
                variant="primary"
                size="sm"
                class="float-right"
                @click="clickBkgId_OK"
              >
                OK
              </b-button>
            </div>
          </b-modal>
            <!-- Info modal Container Image Reqr-->
          <b-modal id="contModalReqr" :title="modalTitleReqr" ok-only @hide="resetInfoModalReqr">
            <div class="card me-5" style=" width:15rem">
                <img  class="card-img-top" :src="imageUriModalReqr" alt="Image Preview - Require"/>
            </div>
          </b-modal>
            <!-- Info modal Container Image Rels-->
          <b-modal id="contModalRels" :title="modalTitleRels" ok-only @hide="resetInfoModalRels">
            <div class="card me-5" style=" width:15rem">
                <img  class="card-img-top" :src="imageUriModalRels" alt="Image Preview - Release"/>
            </div>
          </b-modal>
          <b-modal id="viewShipsRejectModalReqr" :title="$t('matchTx.viewShipsRejectTitle')" ok-only @hide="resetInfoModalShipsRejectReqr">
            <div class="card me-5" style=" width:30rem">
                <h6 class="card me-5"> {{ shipsRejectRemarkReqr }}</h6>
            </div>
          </b-modal>
          <b-modal id="viewShipsRejectModalRels" :title="$t('matchTx.viewShipsRejectTitle')" ok-only @hide="resetInfoModalShipsRejectRels">
            <div class="card me-5" style=" width:30rem">
                <h6 class="card me-5"> {{ shipsRejectRemarkRels   }}</h6>
            </div>
          </b-modal>
        </div>

      </div>
      <div class="divider"> </div>
      <div>
        <b-row>
          <h4 class="my-1 ml-4 f-w-600">{{ $t('matchTx.relsProposer') }}</h4> 
          <b-row>
            <b-col lg="6" class="my-1">
              <b-form-group
                :label="$t('matchTx.sort')"
                label-for="sort-by-select-rels"
                label-cols-sm="3"
                label-align-sm="right"
                label-size="lg"
                class="mb-0"
                v-slot="{ ariaDescribedbyRels }"
              >
                <b-input-group size="lg">
                  <b-form-select
                    id="sort-by-select-rels"
                    v-model="sortByRels"
                    :options="sortOptionsRels"
                    :aria-describedby="ariaDescribedbyRels"
                    class="w-70 text-info"
                  >
                    <template #first>
                      <option value="">-- none --</option>
                    </template>
                  </b-form-select>

                  <b-form-select
                    v-model="sortDescRels"
                    :disabled="!sortByRels"
                    :aria-describedby="ariaDescribedbyRels"
                    size="lg"
                    class="w-30 text-info"
                  >
                    <option :value="false">{{$t('matchTx.asc')}}</option>
                    <option :value="true">{{$t('matchTx.desc')}}</option>
                  </b-form-select>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col lg="6" class="my-1">
              <b-form-group
                :label="$t('matchTx.initSort')"
                label-for="initial-sort-select-rels"
                label-cols-sm="3"
                label-align-sm="right"
                label-size="lg"
                class="mb-0"
              >
                <b-form-select
                  id="initial-sort-select-rels"
                  v-model="sortDirectionRels"
                  :options="['asc', 'desc', 'last']"
                  size="lg"
                  class="text-info"
                ></b-form-select>
              </b-form-group>
            </b-col>
            <b-col lg="6" class="my-1">
              <b-form-group
                :label="$t('matchTx.filter')"
                label-for="filter-input-rels"
                label-cols-sm="3"
                label-align-sm="right"
                label-size="lg"
                class="mb-0"
              >
                <b-input-group size="sm">
                  <b-form-input
                    id="filter-input-rels"
                    v-model="filterRels"
                    type="search"
                    :placeholder="$t('matchTx.typeToSearch')"
                    class="text-info"
                    style="font-size: 18px;"
                  ></b-form-input>
                  <b-input-group-append>
                    <b-button size="md" variant="primary" :disabled="!filterRels" @click="filterRels = ''">
                      {{ $t('matchTx.clear') }}
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col lg="6" class="my-1">
              <b-form-group
                v-model="sortDirectionRels"
                :label="$t('matchTx.filterOn')"
                :description= "$t('matchTx.uncheck')"
                label-cols-sm="3"
                label-align-sm="right"
                label-size="lg"
                class="mb-0"
                v-slot="{ ariaDescribedbyRels }"
              >
                <b-form-checkbox-group
                  v-model="filterOnRels"
                  :aria-describedby="ariaDescribedbyRels"
                  class="mt-1 text-info"
                  plain
                >
                  <b-form-checkbox value="agent">{{ $t('matchTx.agent') }}</b-form-checkbox>
                  <b-form-checkbox value="bkgId">{{ $t('matchTx.bkgId') }}</b-form-checkbox>
                  <b-form-checkbox value="contNo">{{ $t('matchTx.contNo') }}</b-form-checkbox>     
                  <b-form-checkbox value="contSize">{{ $t('matchTx.contSize') }}</b-form-checkbox>   
                  <b-form-checkbox value="contType">{{ $t('matchTx.contType') }}</b-form-checkbox>   
                  <b-form-checkbox value="contGrade">{{ $t('matchTx.contGrade') }}</b-form-checkbox>
                </b-form-checkbox-group>
              </b-form-group>
            </b-col>
          </b-row>
        </b-row>
        <b-table
          sticky-header
          head-variant="light"
          table-variant="success"
          show-empty
          sort-icon-left
          stacked="md"
          :sort-by.sync="sortByRels"
          :sort-desc.sync="sortDescRels"
          :sort-direction="sortDirectionRels"
          :filter="filterRels"
          :filter-included-fields="filterOnRels"
          :items="itemsRels"
          :fields="fieldsRels"
          :current-page="currentPageRels"
          :per-page="perPageRels"
          @filtered="onFilteredRels"      
          >
          <template #cell(contImageRels)="row" >
            <b-button  size="md" @click="openModalImageRels(row.item, row.index, $event.target )" class="m-1">
                {{ row.item.contNo }}
            </b-button>
          </template>
          <template #cell(actRels)="row" >
            <b-button  v-show="row.item.actRels.cancel" size="md" variant="danger" @click="actReqrCancel(row.item)" class="m-1 ">
              {{ $t('matchTx.cancel') }}
            </b-button>
            <b-button  v-show="row.item.actRels.accept" size="md" variant="success" @click="actReqrAccept(row.item)" class="m-1">
              {{ $t('matchTx.accept') }}
            </b-button>
            <b-button  v-show="row.item.actRels.reject" size="md" variant="danger" @click="actReqrReject(row.item)" class="m-1">
              {{ $t('matchTx.reject') }}
            </b-button>
            <b-button  v-show="row.item.jbRels == 'shipsRej'" size="md" variant="warning" @click="openModalViewShipsRejectRels(row.item, row.index, $event.target )" class="m-1">
              {{ $t('matchTx.viewShipsReject') }}
            </b-button>
          </template>
          <template #empty>
              <h5>{{ $t('reuse.noRecord') }}</h5>
          </template>
        </b-table>
        <b-row>
          <div class="row col-sm-4 ">
            <b-col class="my-1 ml-3">
              <b-pagination
                v-model="currentPageRels"
                :total-rows="totalRowsRels"
                :per-page="perPageRels"
                align="fill"
                class="my-0"
              ></b-pagination>
            </b-col>   
            <b-col sm="3" md="6" class="my-1">
              <b-form-group
                :label="$t('require.perPage')"
                label-for="per-page-select"
                label-cols-sm="6"
                label-cols-md="4"
                label-cols-lg="3"
                label-align-sm="right"
                label-size="sm"
                class="mb-0 "
              > 
                <b-form-select
                  id="per-page-select"
                  v-model="perPageRels"
                  :options="pageOptionsRels"
                ></b-form-select>
              </b-form-group>
            </b-col>          
          </div>
        </b-row>
      </div>
      <div>
        <vue-html2pdf
          :show-layout="false"
          :float-layout="true"
          :enable-download="true"
          :preview-modal="true"
          :paginate-elements-by-height="1400"
          :filename="`EIR${selected_mTxId}`"
          :pdf-quality="2"
          :manual-pagination="false"
          pdf-format="a4"
          pdf-orientation="portrait"
          pdf-content-width="800px"
          ref="html2Pdf"
        >
          <section slot="pdf-content">
            <section class="pdf-item" >
              <div class="col-sm-12">
                  <h2  class="text-center text-dark mt-5 mb-4">{{ $t('matchTx.eirTitle') }}</h2>
                  <div class="ml-2 d-flex flex-row justify-content-between">
                    <div class="text-center ml-5"><img src="../../assets/images/conex-logo.png" alt="conex"></div>                              
                    <div class="mr-5 mt-2">
                      <div class="row">
                          <h5 class="text-dark f-w-800 mx-2">{{ $t('matchTx.date') }} :</h5>
                          <h5 class="text-dark ml-1">{{ $moment().format(" DD/MMM/YYYY") }}</h5>   
                      </div>
                      <div class="row">
                          <h5 class="text-dark f-w-800 mx-2">{{ $t('matchTx.refNo') }} :</h5>
                          <h5 class="text-dark ml-1">{{selected_mTxId}}-EIR</h5>   
                      </div>                            
                    </div>
                  </div>                                      
              </div>                                  
              <div class="d-flex flex-row mt-5 col-sm-12">
                <div class="align-self-start  ml-4 border border-dark col-sm-6">
                  <b-card-group>
                    <b-card :img-src="require('../../assets/images/EIR_cont.png')" img-alt="Image" img-top></b-card>
                  </b-card-group>                      
                </div>
                <div class="align-self-strech border border-dark col-sm-5" >
                  <b-card-group class="d-flex flex-column justify-content-end">
                    <b-card >
                      <h5 class="text-dark" >{{ $t('matchTx.inspector') }} :</h5>
                      <h6 class="text-primary"> {{ selectedCompNameLocalInsp }}</h6>
                      <h6 class="text-primary"> {{ selectedContInspAddress }}</h6>  
                    </b-card>
                  </b-card-group>                    
                  <b-card-group>
                    <b-card >
                      <b-card-text>
                        <h5> {{ $t('matchTx.remark') }}</h5>
                        <h6 class="text-primary"> {{ remark }}</h6>
                      </b-card-text>
                    </b-card>
                  </b-card-group> 
                </div>
              </div>
              <div>
           
                <b-row class="my-3">
                  <b-col>
                    <label class="ml-5">{{$t('matchTx.contNoEir')}} : </label> 
                    <h5 class="ml-5"> {{ selectedContNo }}</h5> 
                  </b-col>
                  <b-col>
                    <label class="ml-3">{{$t('matchTx.typeSize')}} : </label> 
                    <h5 class="ml-3"> {{selectedContType}} : {{ selectedContSize }} </h5> 
                  </b-col>
                  <b-col>
                    <label class="ml-3">{{$t('matchTx.sealNoEir')}} : </label> 
                    <h5 class="ml-3"> {{selectedSealNo}} </h5> 
                  </b-col>
                  <b-col>
                    <label class="ml-3">{{$t('matchTx.tareEir')}} : </label> 
                    <h5 class="ml-3"> {{selectedContTare}} </h5> 
                  </b-col>
                </b-row>
  

                <b-row >
                  <b-col>
                    <label class="ml-5">{{$t('matchTx.bkgIdEir')}} : </label> 
                    <h5 class="ml-5"> {{ selectedBkgId }}</h5> 
                  </b-col>
                  <b-col>
                    <label class="ml-3">{{$t('matchTx.agentEir')}} : </label> 
                    <h5 class="ml-3"> {{selectedAgent}} </h5> 
                  </b-col>
                </b-row>
                <div class="divider mt-3 mx-5"> </div>   

                <b-card-group deck>
                  <b-card class="ml-4">
                    <h5 class="text-dark" >{{ $t('matchTx.compNameLocalRelsEir') }}</h5>
                    <h6 class="text-primary"> {{ selectedCompNameLocalRels }}</h6>
                    <h6 class="text-primary"> {{ selectedContRelsAddress }}</h6>        
                  </b-card>
                  <b-card>
                    <h5 class="text-dark" >{{ $t('matchTx.compNameLocalReqrEir') }}</h5>
                    <h6 class="text-primary"> {{ selectedCompNameLocalReqr }}</h6>
                    <h6 class="text-primary"> {{ selectedContReqrAddress }}</h6>        
                  </b-card>
                </b-card-group>

                <div class="divider mb-2 mx-5"> </div>
              </div>
              <div class="mt-3">
                <b-row >
                  <b-col>
                    <h4 class="ml-5">{{$t('matchTx.driver')}} : </h4> 
                    <div class="divider my-5 mx-5"> </div>
                  </b-col>
                  <b-col>
                    <h4 class="ml-3">{{$t('matchTx.tel')}} : </h4> 
                    <div class="divider my-5 mx-3"> </div>
                  </b-col>
                </b-row>
              </div>                                                                              
            </section>     
          </section>
        </vue-html2pdf>
      </div>
    </b-container>
  </div>
</template>
<script>

import {shipReviewRemark, uaTxWallet, matchTxsRels,bkgIdListAgentGroup, makeMatchTxTrStep2, matchTxsReqr, updateStatus, updateActive, updateSealMatchTx, updateContActivebyContNo, sealNoListAgentGroup, updateMatchTx2StepN , xlsxRecvr, xlsxPropsr,xlsxInternal, xlsxTransfer } from "../../firebase"
import {exportXlsx ,getRandomInt} from "../../utilities/util" 
import {relsCost , reqrCost, reuCost, transferCost} from '../../constants/config'
import VueHtml2pdf from 'vue-html2pdf';
  export default {
    components: { VueHtml2pdf },
    name: 'matching',
    data() {
      return {
        selectedCompNameLocalRels: '',
        selectedContRelsAddress: '',
        selectedCompNameLocalReqr: '',        
        selectedContReqrAddress: '',
        selectedCompNameLocalInsp: '',        
        selectedContInspAddress: '',
        selectedContNo: '',
        selectedContSize: '',
        selectedContType: '',
        selectedContGrade: '',
        selectedContTare: '',
        selectedAgent: '',
        remark: '',
        compId : '',
        itemsReqr: [],
        itemsRels: [],
        showEirModal: false,
        showSealNoModal: false,
        showUpdateSealNoModal: false,
        sealNos: [],
        selectedSealNo: '',
        currentSealNo: '',
        showTransferModal: false,
        bkgIds: [],
        selectedBkgId: '',
        selected_mTxId : '',       // for Reqr Transfer action charge
        selected_compIdReqr: '',   // for Reqr Transfer charge
        fieldsReqr: [
          { key: 'mTxId', label: this.$t('matchTx.mTxId'), sortable: true, },  
          { key: 'bkgId', label: this.$t('require.bkgId'), sortable: true, },     
          { key: 'contImageReqr', label: this.$t('matchTx.contNo'), sortable: true, variant: 'info'},  
          // { key: 'contNo', label: this.$t('matchTx.contNo'), sortable: true, },          
          { key: 'detDate', label: this.$t('matchTx.detDate'), sortable: true, }, 
          { key: 'contType', label: this.$t('matchTx.contType'), sortable: true, },
          { key: 'contSize', label: this.$t('matchTx.contSize'), sortable: true, },
          { key: 'contGrade', label: this.$t('matchTx.contGrade'), sortable: true, },
          { key: 'maxGross', label: this.$t('container.maxGross'), sortable: true, },
          { key: 'tare', label: this.$t('container.tare'), sortable: true, },
          { key: 'sealNo', label: this.$t('matchTx.sealNo'), sortable: true, },
          { key: 'agent', label: this.$t('matchTx.agent'), sortable: true, },                
          { key: 'compNameLocalRels', label: this.$t('matchTx.compNameLocalRels'), sortable: true, },                
          { key: 'contRelsAddress', label: this.$t('matchTx.contRelsAddress'), sortable: false},
          { key: 'jobTypeRels', label: this.$t('matchTx.jobTypeRels'), sortable: true, 
              formatter: value => {
                return this.$t(`require.${value}`)
          }}, 
          { key: 'distance', label: this.$t('matchTx.distance'), sortable: true, },                
          { key: 'compNameLocalReqr', label: this.$t('matchTx.compNameLocalReqr'), sortable: true, },                
          { key: 'contReqrAddress', label: this.$t('matchTx.contReqrAddress'), sortable: false, },
          { key: 'jobTypeReqr', label: this.$t('matchTx.jobTypeReqr'), sortable: true, 
              formatter: value => {
                return this.$t(`require.${value}`)
          }},           
          { key: 'timestamp', label: this.$t('shipApproval.date'), sortable: true, 
                    formatter: value => {
                    return this.$moment(value).format("YY-MM-DD @ HH:mm")
          }},
          { key: 'msReqr', label: this.$t('matchTx.matchStatus'), sortable: false, variant: 'warning',
              formatter: value => {
                 return this.$t(`matchTx.${value}`)
              }}, 
          { key: 'jbReqr', label: this.$t('matchTx.jobStatus'), sortable: true, variant: 'warning',
              formatter: value => {
                return this.$t(`matchTx.${value}`)
              }}, 
          { key: 'actReqr', label: this.$t('matchTx.actions'), variant: 'warning' }
        ],
        fieldsRels: [
          { key: 'mTxId', label: this.$t('matchTx.mTxId'), sortable: true, },  
          { key: 'bkgId', label: this.$t('require.bkgId'), sortable: true, },     
          { key: 'contImageRels', label: this.$t('matchTx.contNo'),sortable: true, variant: 'info'},  
          // { key: 'contNo', label: this.$t('matchTx.contNo'), sortable: true, },          
          { key: 'detDate', label: this.$t('matchTx.detDate'), sortable: true, }, 
          { key: 'contType', label: this.$t('matchTx.contType'), sortable: true, },
          { key: 'contSize', label: this.$t('matchTx.contSize'), sortable: true, },
          { key: 'contGrade', label: this.$t('matchTx.contGrade'), sortable: true, },
          { key: 'maxGross', label: this.$t('container.maxGross'), sortable: true, },
          { key: 'tare', label: this.$t('container.tare'), sortable: true, },
          { key: 'agent', label: this.$t('matchTx.agent'), sortable: true, },                
          { key: 'compNameLocalRels', label: this.$t('matchTx.compNameLocalRels'), sortable: true, },                
          { key: 'contRelsAddress', label: this.$t('matchTx.contRelsAddress'), sortable: false, },
          { key: 'jobTypeRels', label: this.$t('matchTx.jobTypeRels'), sortable: true, 
            formatter: value => {
              return this.$t(`require.${value}`)
            }},   
          { key: 'distance', label: this.$t('matchTx.distance'), sortable: true, },                   
          { key: 'compNameLocalReqr', label: this.$t('matchTx.compNameLocalReqr'), sortable: true, },                
          { key: 'contReqrAddress', label: this.$t('matchTx.contReqrAddress'), sortable: false, },
          { key: 'jobTypeReqr', label: this.$t('matchTx.jobTypeReqr'), sortable: true, 
            formatter: value => {
              return this.$t(`require.${value}`)
          }},           
          { key: 'timestamp', label: this.$t('shipApproval.date'), sortable: true, 
            formatter: value => {
              return this.$moment(value).format("YY-MM-DD @ HH:mm")
          }},
          { key: 'msRels', label: this.$t('matchTx.matchStatus'), sortable: false,variant: 'warning',
              formatter: value => {
                return this.$t(`matchTx.${value}`)
              }}, 
          { key: 'jbRels', label: this.$t('matchTx.jobStatus'), sortable: true, variant: 'warning',
              formatter: value => {
                return this.$t(`matchTx.${value}`)
              }}, 
          { key: 'actRels', label: this.$t('matchTx.actions'), variant: 'warning'}
        ],
        totalRowsReqr: 1,
        currentPageReqr: 1,
        perPageReqr: 10,
        pageOptionsReqr: [5, 10, 15, { value: 100, text: "Show a lot" }],
        sortByReqr: 'timestamp',
        sortDescReqr: true,
        sortDirectionReqr: 'asc',
        filterReqr: null,
        filterOnReqr: [],
        actReqrAttachSealModal: {
          id: 'reqrAttachSeal-modal',
          title: this.$t('matchTx.attachSeal'),
        },
        actReqrUpdateSealModal: {
          id: 'reqrUpdateSeal-modal',
          title: this.$t('matchTx.updateSeal'),
        },
        actReqrTransferModal: {
          id: 'reqrTransfer-modal',
          title: this.$t('matchTx.transfer'),
        },
        totalRowsRels: 1,
        currentPageRels: 1,
        perPageRels: 10,
        pageOptionsRels: [5, 10, 15, { value: 100, text: "Show a lot" }],
        sortByRels: 'timestamp',
        sortDescRels: true,
        sortDirectionRels: 'asc',
        filterRels: null,
        filterOnRels: [],
        imageUriModalReqr: '',
        modalTitleReqr : '',
        imageUriModalRels: '',
        modalTitleRels : '',
        shipsRejectRemarkRels : '',
        shipsRejectRemarkReqr : ''
      }
    },
    computed: {
      sortOptionsReqr() {
        // Create an options list from our fields
        return this.fieldsReqr
          .filter(f => f.sortable)
          .map(f => {
            return { text: f.label, value: f.key }
          })
      },
      sortOptionsRels() {
        // Create an options list from our fields
        return this.fieldsRels
          .filter(f => f.sortable)
          .map(f => {
            return { text: f.label, value: f.key }
          })
      }
    },
    
    mounted() {
      this.compId= localStorage.getItem("compId")
      matchTxsReqr(this.compId , (res)=>{
        this.itemsReqr = res 
        this.totalRowsReqr = res.length
        // console.log(`[matchTxReqr => ${JSON.stringify(res , null , 2)}]`)
      })
      matchTxsRels(this.compId , (res)=>{
        this.itemsRels = res
        this.totalRowsRels = res.length
        // console.log(`[matchTxRels => ${JSON.stringify(res , null , 2)}]`)
      })
    },
    methods: {
      onFilteredReqr(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRowsReqr = filteredItems.length
        this.currentPageReqr = 1
      },
      onFilteredRels(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRowsRels = filteredItems.length
        this.currentPageRels = 1
      },
      async refreshMatchTx() {
        this.selectedSealNo = ""
        this.selected_mTxId = ""
        this.selected_compIdReqr = ""
        await matchTxsReqr(this.compId , (res)=>{
          this.itemsReqr = res
          this.totalRowsReqr = res.length
        })
         await matchTxsRels(this.compId , (res)=>{
          this.itemsRels = res
          this.totalRowsRels = res.length
        })
      },
      async actReqrCancel(item) {
        this.$bvModal.msgBoxConfirm(this.$t('matchTx.confirmCancelMsg'), {
          title: this.$t('matchTx.plsConfirm'),
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: this.$t('matchTx.yes'),
          cancelTitle: this.$t('matchTx.no'),
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true
        })
          .then(async(value) => {
            if (value) {
              // updateStatus('Release', item.relsId, 'Release')// reverse status to "Release"
              item.txSearcher == "Release" ? updateStatus('Require', item.reqrId, 'Require') : updateStatus('Release', item.relsId, 'Release')// return  status of Poster for recycle.
              item.txSearcher == "Release" ? updateContActivebyContNo( item.contNo , true ): null
              await updateMatchTx2StepN('MatchTx',item.mTxId , 1.1).then(async()=>{
                // no reuse case at this step
                let slipId = `${this.$moment().format("YYMMDDHH")}-${getRandomInt(10000, 90000)}`
                if (item.txSearcher == "Release") {
                  await uaTxWallet(relsCost, item.compIdRels, slipId, "auto", "refund", "cancel release in match",item.mTxId )   // Rels Refund  
                } else {
                  await uaTxWallet(reqrCost, item.compIdReqr, slipId, "auto", "refund", "cancel require in match",item.mTxId )  // Reqr Refund
                }
                await this.refreshMatchTx()
              })

            } else {
              return
            }
          })
          .catch(error => {
            this.$toasted.show( `Action-Cancel : ${error}`, {theme: 'bubble', type: 'error' , position: 'bottom-center', duration: 10000});  
          })
      },
      actReqrReject(item) {
        this.$bvModal.msgBoxConfirm(this.$t('matchTx.confirmRejectMsg'), {
          title: this.$t('matchTx.plsConfirm'),
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'warning',
          okTitle: this.$t('matchTx.yes'),
          cancelTitle: this.$t('matchTx.no'),
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true
        })
          .then(async(value) => {
            if (value) {
              // updateStatus('Release', item.relsId, 'Release')// reverse status to "Release"
              item.txSearcher == "Release" ? updateStatus('Require', item.reqrId, 'Require') : updateStatus('Release', item.relsId, 'Release')// return  status of Poster for recycle.
              item.txSearcher == "Release" ? updateContActivebyContNo( item.contNo , true ): null
              await updateMatchTx2StepN( 'MatchTx',item.mTxId , 1.2).then(async()=>{
                // no reuse case at this step
                let slipId = `${this.$moment().format("YYMMDDHH")}-${getRandomInt(10000, 90000)}`
                if (item.txSearcher == "Release") {
                  await uaTxWallet(relsCost, item.compIdRels, slipId, "auto", "refund","reject release in match", item.mTxId )   // Rels Refund  
                } else {
                  await uaTxWallet(reqrCost, item.compIdReqr, slipId, "auto", "refund", "reject require in match",item.mTxId )  // Reqr Refund
                }
                await this.refreshMatchTx()
              })
            } else {
              return
            }
          })
          .catch(error => {
            this.$toasted.show( `Action-Reject-Require : ${error}`, {theme: 'bubble', type: 'error' , position: 'bottom-center', duration: 10000});  
          })
      },     
      
      actReqrAccept(item) {
        this.$bvModal.msgBoxConfirm(this.$t('matchTx.confirmAcceptMsg'), {
          title: this.$t('matchTx.plsConfirm'),
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'success',
          okTitle: this.$t('matchTx.yes'),
          cancelTitle: this.$t('matchTx.no'),
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true
        })
          .then(async (value) => {
            if (value) {
              await updateMatchTx2StepN('MatchTx',item.mTxId , 2).then(async()=>{
                await this.refreshMatchTx()
              })
            } else {
              return
            }
          })
          .catch(error => {
            this.$toasted.show( `Action-Accept-Require : ${error}`, {theme: 'bubble', type: 'error' , position: 'bottom-center', duration: 10000});  
          })
      },
      async actReqrEir(item) {
        console.log(`[actReqrEir], item =>  ${JSON.stringify(item, null, 2)}`)
        this.selected_mTxId = item.mTxId
        this.selectedCompNameLocalRels= item.compNameLocalRels
        this.selectedContRelsAddress= item.contRelsAddress        
        this.selectedCompNameLocalReqr= item.compNameLocalReqr
        this.selectedContReqrAddress= item.contReqrAddress
        this.selectedCompNameLocalInsp= item.compNameLocalRels
        this.selectedContInspAddress= item.contRelsAddress    
        this.selectedContNo= item.contNo
        this.selectedContSize= item.contSize
        this.selectedContType= item.contType
        this.selectedContTare= item.tare
        this.selectedAgent= item.agent
        this.selectedBkgId= item.bkgId
        this.selectedSealNo= item.sealNo
        this.remark = `${this.$t('matchTx.grade')} : ${item.contGrade}`
        this.showEirModal=true
      },
      clickEir_Issue(){
        let msg = 'completed'
            if (this.selectedCompNameLocalRels ===''){
                msg = this.$t('matchTx.compNameLocalRelsMsg')
            } else if (this.selectedContRelsAddress ===''){
                msg = this.$t('matchTx.contRelsAddressMsg')                
            } else if (this.selectedCompNameLocalReqr ===''){
                msg = this.$t('matchTx.compNameLocalReqrMsg')
            } else if (this.selectedContReqrAddress ===''){
                msg = this.$t('matchTx.contReqrAddressMsg')
            }  
            if (msg === 'completed'){
                this.generateReport()
                this.showEirModal= false
            }else{
                this.$toasted.show(this.$t(msg), {theme: 'bubble', type: 'error' , position: 'bottom-center', duration: 5000});     
            }
      },
      clickEir_NO(){
        this.selected_mTxId = ''
        this.selectedCompNameLocalRels= ''
        this.selectedCompNameLocalReqr= ''
        this.selectedContRelsAddress= ''
        this.selectedContReqrAddress= ''
        this.selectedContNo= ''
        this.selectedContSize= ''
        this.selectedContType= ''
        this.selectedContGrade= ''
        this.selectedContTare= ''
        this.selectedAgent= ''
        this.selectedBkgId= ''
        this.selectedSealNo= ''
        this.showEirModal= false
      },
      async actReqrAttachSeal(item) {
        console.log(`[actReqrAttachSeal], item =>  ${JSON.stringify(item, null, 2)}`)
        this.selected_mTxId = item.mTxId
        await sealNoListAgentGroup(this.compId, item.agent, (res)=>{
          this.sealNos = res
          this.showSealNoModal=true
        })
      },
      async clickAttachSeal_OK(){
        if (!this.selectedSealNo ) {
          this.$toasted.show( this.$t('matchTx.noSeal'), {theme: 'bubble', type: 'error' , position: 'bottom-center', duration: 10000});  
        } else {
          await updateMatchTx2StepN('MatchTx', this.selected_mTxId , 4 , this.selectedSealNo)
          .then(async()=>{
            await updateActive('Seal' , this.selectedSealNo , false)
            this.showSealNoModal= false
            this.refreshMatchTx()
          })
        }
      },
      async actReqrUpdateSeal(item) {
        console.log(`[actReqrAttachSeal], item =>  ${JSON.stringify(item, null, 2)}`)
        this.selected_mTxId = item.mTxId
        this.currentSealNo = item.sealNo
        await sealNoListAgentGroup(this.compId, item.agent, (res)=>{
          this.sealNos = res
          this.showUpdateSealNoModal=true
        })
      },
      async clickUpdateSeal_OK(){
        if (!this.selectedSealNo ) {
          this.$toasted.show( this.$t('matchTx.noSeal'), {theme: 'bubble', type: 'error' , position: 'bottom-center', duration: 5000});  
        } else {
          await updateSealMatchTx(this.selected_mTxId, this.selectedSealNo)
          .then(async()=>{
            await updateActive('Seal' , this.selectedSealNo , false)
            await updateActive('Seal' , this.currentSealNo , true)
            this.showUpdateSealNoModal= false
            this.refreshMatchTx()
          })
        }
      },
      actReqrTerminate(item){
        this.$bvModal.msgBoxConfirm(this.$t('matchTx.confirmTerminateMsg'), {
          title: item.txSearcher != "Reuse" ? this.$t('matchTx.plsConfirmTerminate') : this.$t('matchTx.plsConfirm') ,
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: this.$t('matchTx.yes'),
          cancelTitle: this.$t('matchTx.no'),
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true
        })
          .then(async(value) => {
            if (value) {
              if (item.txSearcher == "Reuse") {
                await updateMatchTx2StepN('MatchTx',item.mTxId , 4.2).then(async()=>{
                  let slipId = `${this.$moment().format("YYMMDDHH")}-${getRandomInt(10000, 90000)}`
                  updateStatus('Reuse', item.reqrId, 'Terminated')
                  updateContActivebyContNo( item.contNo, true )
                  item.sealNo ? await updateActive('Seal' , item.sealNo , true) : null
                  await uaTxWallet(reuCost / 2, item.compIdReqr, slipId, "auto", "refund", "terminate reuse in match", item.mTxId)
                  await this.refreshMatchTx()
                })
              } else {
                let nowTS = new Date().valueOf()
                let earlyDetTS = item.detTS - nowTS
                if (earlyDetTS < 259200000 ) {    // 3 days early
                  this.$toasted.show( `${this.$t('matchTx.early3DaysTerminateMsg')}`, {theme: 'bubble', type: 'error' , position: 'bottom-center', duration: 10000});  
                } else {
                  item.txSearcher == "Require" ? updateStatus('Release', item.relsId, 'Release') : null
                  // no refund to Reqr (should be receiver but terminate )
                  await updateMatchTx2StepN('MatchTx',item.mTxId , 4.2).then(async()=>{
                    item.sealNo ? await updateActive('Seal' , item.sealNo , true) : null
                    await this.refreshMatchTx()
                  })
                }
              }
            } else {
              return
            }
          })
          .catch(error => {
            this.$toasted.show( `Action-Terminate : ${error}`, {theme: 'bubble', type: 'error' , position: 'bottom-center', duration: 10000});  
          })
      },

      async actReqrTransfer(item) {
        console.log(`[actReqrTransfer], item =>  ${JSON.stringify(item, null, 2)}`)
        this.selected_mTxId = item.mTxId
        this.selected_compIdReqr = item.compIdReqr
        await bkgIdListAgentGroup(this.compId, item.agent, item.bkgId,(res)=>{
          this.bkgIds = res
          this.showTransferModal=true
        })
      },
      async clickBkgId_OK(){
        if (!this.selectedBkgId ) {
          this.$toasted.show( this.$t('matchTx.noBkgId'), {theme: 'bubble', type: 'error' , position: 'bottom-center', duration: 10000});  
        } else {
          let slipId = `${this.$moment().format("YYMMDDHH")}-${getRandomInt(10000, 90000)}`
          await makeMatchTxTrStep2(this.selected_mTxId , this.selectedBkgId).then(async()=>{   // make new MatchTx - Transfer for ship approval 
            await updateMatchTx2StepN('MatchTx', this.selected_mTxId , 4.1 , this.selectedBkgId)  // step up current MatchTx to step 4.1 as status: cancel , jbreqr: transfer
            await uaTxWallet(transferCost * -1, this.selected_compIdReqr, slipId, "auto", "expense", "rights transfer", this.selected_mTxId)
            this.showTransferModal= false
            this.refreshMatchTx()    
          })
        }
      },
      openModalImageReqr(item, index, button) {
            this.imageUriModalReqr = item.imageUri
            this.modalTitleReqr = item.contNo
            this.$root.$emit('bv::show::modal', "contModalReqr" , button)
        },
      resetInfoModalReqr() {
        this.modalTitleReqr = ''
        this.imageUriModalReqr = ''
      },
      openModalImageRels(item, index, button) {
        this.imageUriModalRels = item.imageUri
        this.modalTitleRels = item.contNo
        this.$root.$emit('bv::show::modal', "contModalRels" , button)
      },
      resetInfoModalRels() {
        this.modalTitleRels = ''
        this.imageUriModalRels = ''
      },
      async openModalViewShipsRejectRels(item, index, button) {
        await shipReviewRemark(item.mTxId , (res)=>{
          this.shipsRejectRemarkRels = res
        })  
        this.$root.$emit('bv::show::modal', "viewShipsRejectModalRels" , button)
      },
      resetInfoModalShipsRejectRels() {
        this.shipsRejectRemarkRels = ''
      },

      async openModalViewShipsRejectReqr(item, index, button) {
        // console.log(`openModalViewShipsRejectReqr => ${JSON.stringify(item , null , 2)}`)
        await shipReviewRemark(item.mTxId , (res)=>{
          this.shipsRejectRemarkReqr = res
        })  
        this.$root.$emit('bv::show::modal', "viewShipsRejectModalReqr" , button)
      },

      resetInfoModalShipsRejectReqr() {
        this.shipsRejectRemarkReqr = ''
      },
      async exportItems (compId , startAt, endAt, cb ){
        await xlsxRecvr( compId, startAt , endAt, async(reqr)=>{
          await xlsxPropsr( compId, startAt , endAt, async(rels)=>{
            let i = reqr.concat(rels)
            await xlsxInternal( compId, startAt , endAt, async(reu)=>{
              i = i.concat(reu)
              await xlsxTransfer(compId, startAt, endAt, (trn)=>{
                i = i.concat(trn)
                cb(i)
              })
            })
          })
        })
      },
      async back7days(){
        let endAt = Date.parse(new Date())
        let startAt =  endAt - 604800000 
        let name = `conex_${this.$moment(startAt).format("YYYY-MM-DD")}_${this.$moment(endAt).format("YYYY-MM-DD")}`
        try {
          this.exportItems(this.compId, startAt, endAt , (res)=>{
            exportXlsx(res, name)
            // console.log("[exportItems] res =>"+ JSON.stringify(res, null , 2)) 
          })
        } catch (error) {
          this.$toasted.show(`${this.$t('matchTx.errExportData')}: ${error}`, {theme: 'bubble', type: 'error' , position: 'bottom-center', duration: 5000});     
        }
      },
      async back30days(){
        let endAt = Date.parse(new Date())
        let startAt =  endAt - 18446400000 
        let name = `conex_${this.$moment(startAt).format("YYYY-MM-DD")}_${this.$moment(endAt).format("YYYY-MM-DD")}`
        try {
          this.exportItems(this.compId, startAt, endAt , (res)=>{
            exportXlsx(res, name)
            // console.log("[exportItems] res =>"+ JSON.stringify(res, null , 2)) 
          })
        } catch (error) {
          this.$toasted.show(`${this.$t('matchTx.errExportData')}: ${error}`, {theme: 'bubble', type: 'error' , position: 'bottom-center', duration: 5000});     
        }
      },
      async inYear(){
        let endAt = Date.parse(new Date())
        let startAt = Date.parse(new Date("2024/01/01"))
        let name = `conex_${this.$moment(startAt).format("YYYY-MM-DD")}_${this.$moment(endAt).format("YYYY-MM-DD")}`
        try {
          this.exportItems(this.compId, startAt, endAt , (res)=>{
            exportXlsx(res, name)
            // console.log("[exportItems] res =>"+ JSON.stringify(res, null , 2)) 
          })
        } catch (error) {
          this.$toasted.show(`${this.$t('matchTx.errExportData')}: ${error}`, {theme: 'bubble', type: 'error' , position: 'bottom-center', duration: 5000});     
        }
      },
      generateReport () {
            this.$refs.html2Pdf.generatePdf()
        },
    }
  }
</script>
<style scoped>
    .cont {
        width: 50%;
        height: 50%;
        border-radius: 5px;
    }
    .divider {
        height: 1px;
        background-color: #0f0f0f;
        margin:5px
    }
</style>