import { initializeApp } from 'firebase/app'
import { getFirestore , doc, deleteDoc, updateDoc, increment, documentId,setDoc ,query, where, collection, getDocs} from 'firebase/firestore'
import { getAuth , deleteUser , updatePassword , sendPasswordResetEmail} from 'firebase/auth'
import {getStorage , ref , uploadBytes, getDownloadURL,} from 'firebase/storage'
// const firebaseConfig = {
//   apiKey: 'AIzaSyCfCvlxaHmg3YgR07aJlw0pUiokj2Y7srw',
//   authDomain: 'conex-a535b.firebaseapp.com',
//   projectId: 'conex-a535b',
//   storageBucket: 'conex-a535b.appspot.com',
//   messagingSenderId: '954213072224',
//   appId: '1:954213072224:web:5405ee241c20bbd844b205'
// };
const firebaseConfig = {
  apiKey: 'AIzaSyCfCvlxaHmg3YgR07aJlw0pUiokj2Y7srw',
  authDomain: 'conex-a535b.web.app',
  projectId: 'conex-a535b',
  storageBucket: 'conex-a535b.appspot.com',
  messagingSenderId: '954213072224',
  appId: '1:954213072224:web:5405ee241c20bbd844b205'
};

export const getNameCompId= async( compId )=>{
  const q = query(collection(db, "Company"), 
    where(documentId(), "==", compId)
  );
  const querySnapshot = await getDocs(q);
  const name = querySnapshot.docs.map((doc) => {
    return doc.data().compNameLocal
  });
  return name[0]
}

export const getWalletData = async( cb)=>{
  let wData = []
  const querySnapshot = await getDocs(collection(db, "Wallet"));
  querySnapshot.forEach(async(doc) => {
    let d = doc.data()
    wData.push({
      compNameLocal : await getNameCompId(d.compId),
      ...d
    })
  });
  cb(wData)
}
export const getRefillSlip = async( startAt, endAt, cb)=>{
  let rData = []
  const q = query(collection(db, "uaTx"),
    where('timestamp', '>=' , startAt),  
    where('timestamp', '<=' , endAt),
    where("acctType", "in", ["refillCC" , "refill" ,"adjust" , "refillOL"])
  );
  const querySnapshot = await getDocs(q);
  querySnapshot.docs.forEach((doc) => {
    let d = doc.data()
    console.log(`d=> ${JSON.stringify(d)}`)
    rData.push({
      date : new Date(d.timestamp),
      // compNameLocal : await getNameCompId(d.payerId),  // this call is not work
      ...d
    })
  })
  cb(rData)
}
// export const getRefillSlip = async( startAt, endAt, cb)=>{
//   const q = query(collection(db, "uaTx"),
//     where('timestamp', '>=' , startAt),  
//     where('timestamp', '<=' , endAt),
//     where("acctType", "in", ["refillCC" , "refill" ,"adjust" , "refillOL"])
//   );
//   const querySnapshot = await getDocs(q);
//   const list = querySnapshot.docs.map((doc) => {
//     let d = doc.data()
//     return {
//       date : new Date(d.timestamp),
//       ...d
//     }
//   })
//   cb(list)
// }
const app = initializeApp(firebaseConfig)
export const db = getFirestore(app)
export const authUser = getAuth(app)
export const storage = getStorage(app)

export const googleMapApiKey= 'AIzaSyChfMusRyXTeN11PyREyY2p_P8LaONJFDo'  // project id : conex-a535b
// export const googleMapApiKey= 'AIzaSyCd-qv9-Wciodcem4bRrB8TIxSJtREi3W0'  // project id : conex-432423

const getPictureBlob = (uri) => {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.onload = function () {
      resolve(xhr.response);
    };
    xhr.onerror = function (e) {
      console.log(e);
      reject(new TypeError('Network request failed'));
    };
    xhr.responseType = 'blob';
    xhr.open('GET', uri, true);
    xhr.send(null);
  });
}

export const uploadImage = async( bucket, fileURL , cb)=>{
  const file = await getPictureBlob(fileURL.url)
  const storageRef = ref(storage, `${bucket}/${fileURL.name}`)
  await uploadBytes(storageRef , file)
  .then(async(snapshot)=>{
    // console.log(`snapshot => ${JSON.stringify(snapshot , null ,2)}`)
    await getDownloadURL(storageRef)
    .then((url)=>{
      cb(url)
    })
  })
}

export const dowmloadFile = async( bucket, fileURL , cb)=>{
  const file = await getPictureBlob(fileURL.url)
  const storageRef = ref(storage, `${bucket}/${fileURL.name}`)
    // console.log(`snapshot => ${JSON.stringify(snapshot , null ,2)}`)
    await getDownloadURL(storageRef)
    .then((url)=>{
      cb(url)
    })

}

export const sumUpWallet =async(amount, compId) =>{
  console.log(`sumUpWallet, compId => ${compId}`)
  console.log(`sumUpWallet, amount => ${amount}`)
  if (!isNaN(amount)) {
    try {
      await updateDoc(doc(db, 'Wallet', compId), { 
        balanced: increment(amount),
        timestamp: new Date().valueOf()
      });
    } catch (e) {
        console.error("Error sumUp Wallet: ", e);
    } 
  }

}

export const uaTxWallet = async( cost, compId, slipId, paymentMethod, acctType, reason ,remark)=>{
  await sumUpWallet(cost, compId)
  const uaTx = {
      slipId : slipId,
      payerId : compId,        
      paymentMethod : paymentMethod,
      amount : cost,
      acctType : acctType,
      reason : reason,
      remark : remark,
      timestamp : new Date().valueOf()
  }
  await saveWithId('uaTx', uaTx.slipId, uaTx)
}

export const getBalance =async(compId, callback)=>{
  const q = query(collection(db,'Wallet'), where ("compId", "==", compId))
  const walletQuerySnapshot = await getDocs(q) 
  const bal = walletQuerySnapshot.docs.map((doc) => {
      return doc.data().balanced                       
  })  
  callback(bal[0])   
}

export const changePassword = async(password , cb)=>{ 
  await updatePassword(authUser.currentUser, password).then(() => {
    cb( "success")
  }).catch((err) => {
    console.log(`changePassword error : !${err}`)
    cb ("failed")
  });
}
export const passwordResetEmail = async(email , cb)=>{ 
  await sendPasswordResetEmail(authUser, email).then(() => {
    cb( "success")
  }).catch((err) => {
    console.log(`resetPassword error code: ${err.code}`)
    cb (err.code)
  });
}
export const changeName = async(userId ,name , cb)=>{
  await updateDoc(doc(db, "Users", userId), { name: name}).then(()=>{
    cb ("success")
  }).catch((err)=>{
    cb ("failed")
  })
}

export const changePhone = async(userId ,phone, cb)=>{
  await updateDoc(doc(db, "Users", userId), { phone: phone}).then(()=>{
    cb ("success")
  }).catch((err)=>{
    cb ("failed")
  })
}

export const agentList = async(cb)=>{
  const q = query(collection(db, "Company"), 
    where("active", "==", true),
    where("busType", "==", "agent"))
  const querySnapshot = await getDocs(q);
  const list = querySnapshot.docs.map((doc) => {
    return doc.data().compNameEng
  });
  cb(list)
}

export const agentAtBkgId = async(bkgId, compId, cb)=>{
  const q = query(collection(db, "BookingID"), 
    where("bkgId", "==", bkgId),
    where("compId", "==", compId)
  );
  const querySnapshot = await getDocs(q);
  const list = querySnapshot.docs.map((doc) => {
    return doc.data().agent
  });
  cb(list[0])
}
export const agentAtCompId = async(compId, cb)=>{
  const q = query(collection(db, "Company"), 
    where("compId", "==", compId)
  );
  const querySnapshot = await getDocs(q);
  const list = querySnapshot.docs.map((doc) => {
    return doc.data().compNameEng
  });
  cb(list[0])
}
export const agentAtContNo = async(contNo, cb)=>{
  const q = query(collection(db, "Container"), 
    where("contNo", "==", contNo)
  );
  const querySnapshot = await getDocs(q);
  const list = querySnapshot.docs.map((doc) => {
    let d=doc.data()
    return {
      agent : d.owner,
      contType : d.contType,
      contSize : d.contSize,
      contGrade : d.contGrade,
      detDate: d.detDate,
      detTS: d.detTS,
      imageUri : d.imageUri,
      maxGross : d.maxGross,
      tare : d.tare
    }
  });
  cb(list[0])
}
export const contAgent = async(contNo, cb)=>{
  const q = query(collection(db, "Container"), 
    where("contNo", "==", contNo)
  );
  const querySnapshot = await getDocs(q);
  const list = querySnapshot.docs.map((doc) => {
    return doc.data().owner
  });
  cb(list[0])
}
export const bkgIdList = async(compId, cb)=>{
  const q = query(collection(db, "BookingID"), 
    where("compId", "==", compId),
    where("active", "==", true)
  );
  const querySnapshot = await getDocs(q);
  const list = querySnapshot.docs.map((doc) => {
    return doc.data().bkgId 
  });
  cb(list)
}
export const bkgIdListAll = async( compId , cb)=>{
  const q = query(collection(db, "BookingID"), 
    // where("active", "==", true),
    where("compId", "==", compId)
  );
  const querySnapshot = await getDocs(q);
  const list = querySnapshot.docs.map((doc) => {
    return doc.data()
  });
  cb(list)
}
export const getDataWithId = async(collectionName, refField ,id, cb)=>{
  const q = query(collection(db, collectionName), where(refField, "==", id));
  const querySnapshot = await getDocs(q);
  const list = querySnapshot.docs.map((doc) => {
    return doc.data()
  });
  // console.log(`data list =>${JSON.stringify(list)}`)
  cb(list[0])
}
export const bkgFileUri = async(bkgId , compId)=>{
  const q = query(collection(db, "BookingID"), 
    where("bkgId", "==", bkgId),
    where("compId", "==", compId),
  );
  const querySnapshot = await getDocs(q);
  const uri = querySnapshot.docs.map((doc) => {
    return doc.data().fileUri
  });
  return uri[0]
}
export const compListAll = async(cb)=>{
  const q = query(collection(db, "Company")
  );
  const querySnapshot = await getDocs(q);
  const list = querySnapshot.docs.map((doc) => {
    return doc.data()
  });
  cb(list)
}
export const compListAllRefill = async(cb)=>{
  const q = query(collection(db, "Company"),
  where("active", "==", true),
  where("busType", "==", "logist"),
  );
  const querySnapshot = await getDocs(q);
  const list = querySnapshot.docs.map((doc) => {
    return doc.data()
  });
  cb(list)
}
export const contList = async(compId, cb)=>{
  let list = []
  const q = query(collection(db, "Container"), 
    where("active", "==", true),
    where("compId", "==", compId)
  );
  const querySnapshot = await getDocs(q);
  querySnapshot.docs.forEach((doc) => {
    let d = doc.data()
    if ((d.contGrade != '') && (d.detDate != '') && (d.maxGross != '') && (d.maxGross != '') &&  (d.imageUri != '') ) {
      list.push(d.contNo)
    }
  });

  cb(list)
}
export const contListAll = async(compId, cb)=>{
  const q = query(collection(db, "Container"), 
    where("active", "==", true),
    where("compId", "==", compId)
  );
  const querySnapshot = await getDocs(q);
  const list = querySnapshot.docs.map((doc) => {
    return doc.data()
  });
  cb(list)
}

export const contListAgentNoDet = async(agent, cb)=>{
  const q = query(collection(db, "Container"), 
    where("active", "==", true),
    where("owner", "==", agent),
    where("detDate", "==", ""),
  );
  const querySnapshot = await getDocs(q);
  const list = querySnapshot.docs.map((doc) => {
    let d = doc.data()
    return {
      contId : d.contId,
      contNo : d.contNo,
      contType : d.contType,
      contSize : d.contSize,
      owner : d.owner,
      detDate : d.detDate
    }
  });
  cb(list)
}
export const contListAgentDet = async(agent, cb)=>{
  const q = query(collection(db, "Container"), 
    where("active", "==", true),
    where("owner", "==", agent),
    where("detDate", "!=", ""),
  );
  const querySnapshot = await getDocs(q);
  const list = querySnapshot.docs.map((doc) => {
    let d = doc.data()
      return {
        contId : d.contId,
        contNo : d.contNo,
        contType : d.contType,
        contSize : d.contSize,
        owner : d.owner,
        detDate : d.detDate,
        timestamp : d.timestamp
      }
  });
  cb(list)
}

export const deleteAccount = async(userId, cb)=>{   
  await updateActive("Users", userId, false).then(async()=>{
    await deleteUser(authUser.currentUser).then(() => {
      cb ("success")
    }).catch((err)=>{
      console.log(`updateUsersActive to False, error : !${err}`)
      cb ("failed")
    })
  }).catch((err) => {
    console.log(`deleteUser error : !${err}`)
    cb ("failed")
  });
}

export const deleteItem = async( coltn, id)=>{
  await deleteDoc(doc(db, coltn, id))
}

export const chartDataType = async(compId, compAct,txSearcher,startAt, cb)=>{
  const q = query(collection(db, "MatchTx"),
    where('stepNo', 'in', [4 , 4.1, 4.2]), 
    where('txSearcher', 'in', txSearcher), 
    where(`${compAct}`, '==', compId),  
    where('timestamp', '>=' , startAt), 
  );
  const querySnapshot = await getDocs(q);
  const list = querySnapshot.docs.map((doc) => {
    let d = doc.data()
    if (d.length > 0 ) {
         let mm = new Date(d.timestamp).getMonth() + 1
    let MM = mm < 10 ?  `0${mm}` : mm.toString()
    let yyyy = new Date(d.timestamp).getFullYear()
     return parseInt( yyyy+MM) 
    } 
  });
  cb(list)
}

export const billRecvr = async(compId, startAt, endAt, cb)=>{
  const q = query(collection(db, "MatchTx"),
    where('stepNo', 'in', [ 4, 4.1, 4.2]), 
    where('txSearcher', 'in', ["Require", "Release"]), 
    where('compIdReqr', '==', compId),  
    where('timestamp', '>=' , startAt),  
    where('timestamp', '<=' , endAt),  
  );
  const querySnapshot = await getDocs(q);
  const list = querySnapshot.docs.map((doc) => {
    let d = doc.data()
    return {
      mTxId: d.mTxId,
      timestamp: d.timestamp,
      date: new Date(d.timestamp),
      jobMethCat : `${d.txSearcher}-Receiver`,
      bkgId: d.bkgId, 
      sealNo: d.sealNo, 
      contNo: d.contNo,            
      contType: d.contType,
      contSize: d.contSize,
      contGrade: d.contGrade,
      agent: d.agent,                
      company_Giver: d.compNameLocalRels,               
      address_Giver: d.contRelsAddress,          
      company_Receiver: d.compNameLocalReqr,                
      address_Receiver: d.contReqrAddress,
      amount: 450
    }
  });
  cb(list)
}

export const billPropsr = async(compId, startAt, endAt, cb)=>{
  const q = query(collection(db, "MatchTx"),
    where('stepNo', 'in', [ 4, 4.1, 4.2]), 
    where('txSearcher', 'in', ["Require", "Release"]), 
    where('compIdRels', '==', compId),  
    where('timestamp', '>=' , startAt),  
    where('timestamp', '<=' , endAt),  
  );
  const querySnapshot = await getDocs(q);
  const list = querySnapshot.docs.map((doc) => {
    let d = doc.data()
    return {
      mTxId: d.mTxId,
      timestamp: d.timestamp,
      date: new Date(d.timestamp),
      jobMethCat : `${d.txSearcher}-Proposer`,
      bkgId: d.bkgId, 
      sealNo: d.sealNo, 
      contNo: d.contNo,            
      contType: d.contType,
      contSize: d.contSize,
      contGrade: d.contGrade,
      agent: d.agent,                
      company_Giver: d.compNameLocalRels,               
      address_Giver: d.contRelsAddress,          
      company_Receiver: d.compNameLocalReqr,                
      address_Receiver: d.contReqrAddress,
      amount: 450
    }
  });
  cb(list)
}

export const billInternal = async(compId, startAt, endAt, cb)=>{
  const q = query(collection(db, "MatchTx"),
    where('stepNo', 'in', [ 4, 4.1, 4.2]), 
    where('txSearcher', '==', "Reuse"), 
    where('compIdRels', '==', compId),  
    where('timestamp', '>=' , startAt),  
    where('timestamp', '<=' , endAt),  
  );
  const querySnapshot = await getDocs(q);
  const list = querySnapshot.docs.map((doc) => {
    let d = doc.data()

    return {
      mTxId: d.mTxId,
      timestamp: d.timestamp, 
      date: new Date(d.timestamp),
      jobMethCat : `${d.txSearcher}-Internal`,
      bkgId: d.bkgId, 
      sealNo: d.sealNo, 
      contNo: d.contNo,            
      contType: d.contType,
      contSize: d.contSize,
      contGrade: d.contGrade,
      agent: d.agent,                
      company_Giver: d.compNameLocalRels,               
      address_Giver: d.contRelsAddress,          
      company_Receiver: d.compNameLocalReqr,                
      address_Receiver: d.contReqrAddress,
      amount: 900
    }
  });
  cb(list)
}
export const billTransfer = async(compId, startAt, endAt, cb)=>{
  const q = query(collection(db, "MatchTx"),
    where('stepNo', 'in', [ 4, 4.1, 4.2]), 
    where('txSearcher', '==', "Transfer"), 
    where('compIdReqr', '==', compId),  
    where('timestamp', '>=' , startAt),  
    where('timestamp', '<=' , endAt),  
  );
  const querySnapshot = await getDocs(q);
  const list = querySnapshot.docs.map((doc) => {
    let d = doc.data()

    return {
      mTxId: d.mTxId,
      timestamp: d.timestamp, 
      date: new Date(d.timestamp),
      jobMethCat : "Transfer",
      bkgId: d.bkgId, 
      sealNo: d.sealNo, 
      contNo: d.contNo,            
      contType: d.contType,
      contSize: d.contSize,
      contGrade: d.contGrade,
      agent: d.agent,                
      company_Giver: d.compNameLocalRels,               
      address_Giver: d.contRelsAddress,          
      company_Receiver: d.compNameLocalReqr,                
      address_Receiver: d.contReqrAddress,
      amount: 100
    }
  });
  cb(list)
}

export const billRecvrAdmin = async( startAt, endAt, cb)=>{
  const q = query(collection(db, "MatchTx"),
    where('stepNo', 'in', [ 4, 4.1, 4.2]), 
    where('txSearcher', 'in', ["Require", "Release"]), 
    // where('compIdReqr', '==', compId),  
    where('timestamp', '>=' , startAt),  
    where('timestamp', '<=' , endAt),  
  );
  const querySnapshot = await getDocs(q);
  const list = querySnapshot.docs.map((doc) => {
    let d = doc.data()
    return {
      mTxId: d.mTxId,
      timestamp: d.timestamp,
      date: new Date(d.timestamp),
      jobMethCat : `${d.txSearcher}-Receiver`,
      bkgId: d.bkgId, 
      sealNo: d.sealNo, 
      contNo: d.contNo,            
      contType: d.contType,
      contSize: d.contSize,
      contGrade: d.contGrade,
      agent: d.agent,                
      company_Giver: d.compNameLocalRels,               
      address_Giver: d.contRelsAddress,          
      company_Receiver: d.compNameLocalReqr,                
      address_Receiver: d.contReqrAddress,
      amount: 450
    }
  });
  cb(list)
}

export const billPropsrAdmin = async( startAt, endAt, cb)=>{
  const q = query(collection(db, "MatchTx"),
    where('stepNo', 'in', [ 4, 4.1, 4.2]), 
    where('txSearcher', 'in', ["Require", "Release"]), 
    // where('compIdRels', '==', compId),  
    where('timestamp', '>=' , startAt),  
    where('timestamp', '<=' , endAt),  
  );
  const querySnapshot = await getDocs(q);
  const list = querySnapshot.docs.map((doc) => {
    let d = doc.data()
    return {
      mTxId: d.mTxId,
      timestamp: d.timestamp,
      date: new Date(d.timestamp),
      jobMethCat : `${d.txSearcher}-Proposer`,
      bkgId: d.bkgId, 
      sealNo: d.sealNo, 
      contNo: d.contNo,            
      contType: d.contType,
      contSize: d.contSize,
      contGrade: d.contGrade,
      agent: d.agent,                
      company_Giver: d.compNameLocalRels,               
      address_Giver: d.contRelsAddress,          
      company_Receiver: d.compNameLocalReqr,                
      address_Receiver: d.contReqrAddress,
      amount: 450
    }
  });
  cb(list)
}

export const billInternalAdmin = async( startAt, endAt, cb)=>{
  const q = query(collection(db, "MatchTx"),
    where('stepNo', 'in', [ 4, 4.1, 4.2]), 
    where('txSearcher', '==', "Reuse"), 
    // where('compIdRels', '==', compId),  
    where('timestamp', '>=' , startAt),  
    where('timestamp', '<=' , endAt),  
  );
  const querySnapshot = await getDocs(q);
  const list = querySnapshot.docs.map((doc) => {
    let d = doc.data()

    return {
      mTxId: d.mTxId,
      timestamp: d.timestamp, 
      date: new Date(d.timestamp),
      jobMethCat : `${d.txSearcher}-Internal`,
      bkgId: d.bkgId, 
      sealNo: d.sealNo, 
      contNo: d.contNo,            
      contType: d.contType,
      contSize: d.contSize,
      contGrade: d.contGrade,
      agent: d.agent,                
      company_Giver: d.compNameLocalRels,               
      address_Giver: d.contRelsAddress,          
      company_Receiver: d.compNameLocalReqr,                
      address_Receiver: d.contReqrAddress,
      amount: 900
    }
  });
  cb(list)
}
export const billTransferAdmin = async( startAt, endAt, cb)=>{
  const q = query(collection(db, "MatchTx"),
    where('stepNo', 'in', [ 4, 4.1, 4.2]), 
    where('txSearcher', '==', "Transfer"), 
    // where('compIdReqr', '==', compId),  
    where('timestamp', '>=' , startAt),  
    where('timestamp', '<=' , endAt),  
  );
  const querySnapshot = await getDocs(q);
  const list = querySnapshot.docs.map((doc) => {
    let d = doc.data()

    return {
      mTxId: d.mTxId,
      timestamp: d.timestamp, 
      date: new Date(d.timestamp),
      jobMethCat : "Transfer",
      bkgId: d.bkgId, 
      sealNo: d.sealNo, 
      contNo: d.contNo,            
      contType: d.contType,
      contSize: d.contSize,
      contGrade: d.contGrade,
      agent: d.agent,                
      company_Giver: d.compNameLocalRels,               
      address_Giver: d.contRelsAddress,          
      company_Receiver: d.compNameLocalReqr,                
      address_Receiver: d.contReqrAddress,
      amount: 100
    }
  });
  cb(list)
}

export const slipComp = async(compId, startAt, endAt, cb)=>{
  console.log(`compId for slipComp =>${compId}`)
  const q = query(collection(db, "uaTx"),
    where('payerId', '==', compId),  
    where('timestamp', '>=' , startAt),  
    where('timestamp', '<=' , endAt),  
  );
  const querySnapshot = await getDocs(q);
  const list = querySnapshot.docs.map((doc) => {
    let d = doc.data()
    return {
      date : new Date(d.timestamp),
      ...d
    }
  })
  cb(list)
}

export const xlsxRecvr = async(compId, startAt, endAt, cb)=>{
  const q = query(collection(db, "MatchTx"),
    where('stepNo', 'in', [ 4, 4.1, 4.2]), 
    where('txSearcher', 'in', ["Require", "Release"]), 
    where('compIdReqr', '==', compId),  
    where('timestamp', '>=' , startAt),  
    where('timestamp', '<=' , endAt),  
  );
  const querySnapshot = await getDocs(q);
  const list = querySnapshot.docs.map((doc) => {
    let d = doc.data()
    return {
      เลขที่จับคู่: d.mTxId,
      วันที่บันทึก: new Date(d.timestamp),
      ประเภทงาน : `${d.txSearcher}-Receiver`,
      เลขจอง: d.bkgId, 
      เลขซีล: d.sealNo, 
      เลขตู้: d.contNo,
      วันกำหนดคืนตู้: d.detDate,      
      ชนิดตู้: d.contType,
      ขนาดตู้: d.contSize,
      เกรดตู้: d.contGrade,
      สายเรือ: d.agent,                
      ผู้ให้: d.compNameLocalRels,               
      ที่อยู่ส่ง: d.contRelsAddress,          
      ผู้รับ: d.compNameLocalReqr,                
      ที่อยู่รับ: d.contReqrAddress,
      สถานะการจับคู่: d.msReqr,
      สถานะงาน: d.jbReqr
    }
  });
  cb(list)
}
export const xlsxPropsr = async(compId, startAt, endAt, cb)=>{
  const q = query(collection(db, "MatchTx"),
    where('stepNo', 'in', [ 4, 4.1, 4.2]), 
    where('txSearcher', 'in', ["Require", "Release"]), 
    where('compIdRels', '==', compId),  
    where('timestamp', '>=' , startAt),  
    where('timestamp', '<=' , endAt),  
  );
  const querySnapshot = await getDocs(q);
  const list = querySnapshot.docs.map((doc) => {
    let d = doc.data()
    return {
      เลขที่จับคู่: d.mTxId,
      วันที่บันทึก: new Date(d.timestamp),
      ประเภทงาน : `${d.txSearcher}-Proposer`,
      เลขจอง: d.bkgId, 
      เลขซีล: d.sealNo, 
      เลขตู้: d.contNo,
      วันกำหนดคืนตู้: d.detDate,      
      ชนิดตู้: d.contType,
      ขนาดตู้: d.contSize,
      เกรดตู้: d.contGrade,
      สายเรือ: d.agent,                
      ผู้ให้: d.compNameLocalRels,               
      ที่อยู่ส่ง: d.contRelsAddress,          
      ผู้รับ: d.compNameLocalReqr,                
      ที่อยู่รับ: d.contReqrAddress,
      สถานะการจับคู่: d.msReqr,
      สถานะงาน: d.jbReqr
    }
  });
  cb(list)
}
export const xlsxInternal = async(compId, startAt, endAt, cb)=>{
  const q = query(collection(db, "MatchTx"),
    where('stepNo', 'in', [ 4, 4.1, 4.2]), 
    where('txSearcher', '==', "Reuse"), 
    where('compIdReqr', '==', compId),  
    where('timestamp', '>=' , startAt),  
    where('timestamp', '<=' , endAt),  
  );
  const querySnapshot = await getDocs(q);
  const list = querySnapshot.docs.map((doc) => {
    let d = doc.data()
    return {
      เลขที่จับคู่: d.mTxId,
      วันที่บันทึก: new Date(d.timestamp),
      ประเภทงาน : `${d.txSearcher}-Reuse`,
      เลขจอง: d.bkgId, 
      เลขซีล: d.sealNo, 
      เลขตู้: d.contNo,
      วันกำหนดคืนตู้: d.detDate,      
      ชนิดตู้: d.contType,
      ขนาดตู้: d.contSize,
      เกรดตู้: d.contGrade,
      สายเรือ: d.agent,                
      ผู้ให้: d.compNameLocalRels,               
      ที่อยู่ส่ง: d.contRelsAddress,          
      ผู้รับ: d.compNameLocalReqr,                
      ที่อยู่รับ: d.contReqrAddress,
      สถานะการจับคู่: d.msReqr,
      สถานะงาน: d.jbReqr
    }
  });
  cb(list)
}
export const xlsxTransfer = async(compId, startAt, endAt, cb)=>{
  const q = query(collection(db, "MatchTx"),
    where('stepNo', 'in', [ 4, 4.1, 4.2]), 
    where('txSearcher', '==', "Transfer"), 
    where('compIdReqr', '==', compId),  
    where('timestamp', '>=' , startAt),  
    where('timestamp', '<=' , endAt),  
  );
  const querySnapshot = await getDocs(q);
  const list = querySnapshot.docs.map((doc) => {
    let d = doc.data()
    return {
      เลขที่จับคู่: d.mTxId,
      วันที่บันทึก: new Date(d.timestamp),
      ประเภทงาน : 'Transfer',
      เลขจอง: d.bkgId, 
      เลขซีล: d.sealNo, 
      เลขตู้: d.contNo,
      วันกำหนดคืนตู้: d.detDate,      
      ชนิดตู้: d.contType,
      ขนาดตู้: d.contSize,
      เกรดตู้: d.contGrade,
      สายเรือ: d.agent,                
      ผู้ให้: d.compNameLocalRels,               
      ที่อยู่ส่ง: d.contRelsAddress,          
      ผู้รับ: d.compNameLocalReqr,                
      ที่อยู่รับ: d.contReqrAddress,
      สถานะการจับคู่: d.msReqr,
      สถานะงาน: d.jbReqr
    }
  });
  cb(list)
}
// export const matchTxsReqr = async(compId, cb)=>{
//   let lastWeek = Date.parse(new Date())-658800000
//   let list = []
//   const q = query(collection(db, "MatchTx"),
//     // where('detTS', '>=' , now),  
//     where('active', '==', true), 
//     where('compIdReqr', '==', compId),


//   );
//   const querySnapshot = await getDocs(q);
//   querySnapshot.docs.forEach((doc) => {
//     let data = doc.data()
//     if (data.detTS >= lastWeek) {
//       list.push(data)
//     }
//   });
//   cb(list)
// }
// export const matchTxsRels = async(compId, cb)=>{
//   let lastWeek = Date.parse(new Date())-658800000
//   let list = []
//   const q = query(collection(db, "MatchTx"),
//     //  where('detTS', '>=' , now),  
//     where('active', '==', true),
//     where('compIdRels', '==', compId),
//   );
//   const querySnapshot = await getDocs(q);
//   querySnapshot.docs.forEach((doc) => {
//     let data = doc.data()
//     if (data.detTS >= lastWeek) {
//       list.push(data)
//     }
//   });
//   cb(list)
// }
export const matchTxsReqr = async(compId, cb)=>{
  const q = query(collection(db, "MatchTx"),
    where('active', '==', true), 
    where('compIdReqr', '==', compId),
  );
  const querySnapshot = await getDocs(q);
  const list = querySnapshot.docs.map((doc) => {
    return doc.data()
  });
  cb(list)
}
export const matchTxsRels = async(compId, cb)=>{
  const q = query(collection(db, "MatchTx"),
    where('active', '==', true),
    where('compIdRels', '==', compId),
  );
  const querySnapshot = await getDocs(q);
  const list = querySnapshot.docs.map((doc) => {
    return doc.data()
  });
  cb(list)
}

export const matchTxsAgent = async(agent, cb)=>{
  let lastWeek = Date.parse(new Date())-658800000
  let list = []
  const q = query(collection(db, "MatchTx"),
    // where('detTS', '>=' , now),   
    where('active', '==', true),
    where('stepNo', '==', 2),      
    where('agent', '==', agent)
  );
  const querySnapshot = await getDocs(q);
  querySnapshot.docs.forEach((doc) => {
    let data = doc.data()
    if (data.detTS >= lastWeek) {
      list.push ({
        actAgent:{
          cancel:false, 
          accept: true, 
          reject:true, 
          attachSeal:false,
          terminate:false,
          transfer:false,
        },
        ...data
      }) 
    }
  });
  cb(list)
}
export const postedByCompId = async(collectionName, compId, status, cb)=>{
  const q = query(collection(db, collectionName),
    where('compId', '==', compId),
    where('status', '==', status), 
  );
  const querySnapshot = await getDocs(q);
  const list = querySnapshot.docs.map((doc) => {
      return doc.data()
  });
  cb(list)
}
export const postedByAdmin = async(collectionName, status, cb)=>{
  const q = query(collection(db, collectionName),
    where('status', '==', status), 
  );
  const querySnapshot = await getDocs(q);
  const list = querySnapshot.docs.map((doc) => {
      return doc.data()
  });
  cb(list)
}

// export const getStatusByReqrId = async(reqrId, cb)=>{
//   const q = query(collection(db, "Require"),
//     where('reqrId', '==', reqrId),
//   );
//   const querySnapshot = await getDocs(q);
//   const status = querySnapshot.docs.map((doc) => {
//       return doc.data().status
//   });
//   cb(status[0])
// }
// export const getStatusByRelsId = async(relsId, cb)=>{
//   const q = query(collection(db, "Release"),
//     where('relsId', '==', relsId),
//   );
//   const querySnapshot = await getDocs(q);
//   const status = querySnapshot.docs.map((doc) => {
//       return doc.data().status
//   });
//   cb(status[0])
// }
export const getStatusByDocId = async(collectionName, docId, cb)=>{
  const q = query(collection(db, collectionName),
    where(documentId(), "==", docId)
  );
  const querySnapshot = await getDocs(q);
  const status = querySnapshot.docs.map((doc) => {
      return doc.data().status
  });
  cb(status[0])
}

export const sealNoList = async(compId, cb)=>{
  const q = query(collection(db, "Seal"), 
    where("active", "==", true),
    where("compId", "==", compId)
  );
  const querySnapshot = await getDocs(q);
  const list = querySnapshot.docs.map((doc) => {
    return doc.data().sealNo
  });
  cb(list)
}
const getAgentGroup = async( agent, cb)=>{
  const q = query(collection(db, "AgentGroup"), 
    where("agent", "==", agent),
  );
  const querySnapshot = await getDocs(q);
  const list = querySnapshot.docs.map((doc) => {
    return doc.data().group
  });
  cb(list[0])
}
export const contListAgentGroup = async(compId, agent, cb)=>{
  await getAgentGroup(agent, async(agentGroup)=>{
    let list = []
    const q = query(collection(db, "Container"), 
      where("active", "==", true),
      where("compId", "==", compId)
    );
    const querySnapshot = await getDocs(q);
    querySnapshot.docs.forEach((doc) => {
      let d = doc.data()
      if (agentGroup.includes(d.owner) && (d.contGrade != '') && (d.detDate != '') && (d.maxGross != '') && (d.maxGross != '') &&  (d.imageUri != '')){
        list.push(d.contNo)
      }
    });
    cb(list)
  })
}
export const sealNoListAgentGroup = async(compId, agent, cb)=>{
  await getAgentGroup(agent, async(agentGroup)=>{
    let list = []
    const q = query(collection(db, "Seal"), 
      where("active", "==", true),
      where("compId", "==", compId)
    );
    const querySnapshot = await getDocs(q);
    querySnapshot.docs.forEach((doc) => {
      let d = doc.data()
      if (agentGroup.includes(d.agent)){
        list.push(d.sealNo)
      }
    });
    cb(list)
  })
}
export const bkgIdListAgentGroup = async(compId, agent, curBkgId,cb)=>{
  await getAgentGroup(agent, async(agentGroup)=>{
    let list = []
    const q = query(collection(db, "BookingID"), 
      where("active", "==", true),
      where("compId", "==", compId),
      where("bkgId", "!=", curBkgId)
    );
    const querySnapshot = await getDocs(q);
    querySnapshot.docs.forEach((doc) => {
      let d = doc.data()
      if (agentGroup.includes(d.agent)){
        list.push(d.bkgId)
      }
    });
    cb(list)
  })
}
export const sealNoListAll = async(compId, cb)=>{
  const q = query(collection(db, "Seal"), 
    where("active", "==", true),
    where("compId", "==", compId)
  );
  const querySnapshot = await getDocs(q);
  const list = querySnapshot.docs.map((doc) => {
    return doc.data()
  });
  cb(list)
}
export const compIdByCompName = async(nameLocal, cb)=>{
  const q = query(collection(db, "Company"), 
    where("active", "==", true),
    where("compNameLocal", "==", nameLocal)
  );
  const querySnapshot = await getDocs(q);
  const compId = querySnapshot.docs.map((doc) => {
      return  doc.data().compId
  });
  cb(compId[0])
}
export const compNameLocalByCompId = async(compId)=>{
  const q = query(collection(db, "Company"), 
    where("active", "==", true),
    where("compId", "==", compId)
  );
  const querySnapshot = await getDocs(q);
  const compNameLocal = querySnapshot.docs.map((doc) => {
      return  doc.data().compNameLocal
  });
  return compNameLocal[0]
}
export const compNameEngByCompId = async(compId , cb)=>{
  const q = query(collection(db, "Company"), 
    where("active", "==", true),
    where("compId", "==", compId)
  );
  const querySnapshot = await getDocs(q);
  const compNameEng = querySnapshot.docs.map((doc) => {
      return  doc.data().compNameEng
  });
  cb (compNameEng[0])
}
// export const compNameList = async(busType, cb)=>{
//   if (busType == 'agent') {
//     const q = query(collection(db, "Company"), 
//       where("active", "==", true),
//       where("busType", "==", 'agent'),
//     );
//     const querySnapshot = await getDocs(q);
//     const list = querySnapshot.docs.map((doc) => {
//       return  doc.data().compNameLocal
//     });
//     cb(list)
//   } else {
//     const q = query(collection(db, "Company"), 
//     where("active", "==", true),
//     where("busType", "!=", 'agent'),
//   );
//     const querySnapshot = await getDocs(q);
//     const list = querySnapshot.docs.map((doc) => {
//       return  doc.data().compNameLocal
//     });
//     cb(list)
//   }
// }
export const compNameList = async(opt, cb)=>{
  const q = query(collection(db, "Company"), 
    where("active", "==", true),
    where("busType", `${opt}`, 'agent'),
  );
  const querySnapshot = await getDocs(q);
  const list = querySnapshot.docs.map((doc) => {
    return  doc.data().compNameLocal
  });
  cb(list)
}
export const compNameListLogist = async(cb)=>{
  const q = query(collection(db, "Company"), 
    where("active", "==", true),
    where("busType", "==", "logist"),
  );
  const querySnapshot = await getDocs(q);
  const list = querySnapshot.docs.map((doc) => {
      return  doc.data().compNameLocal
  });
  cb(list)
}
export const locByCompId = async(compId, cb)=>{
  const q = query(collection(db, "Company"), 
    where("active", "==", true),
    where("compId", "==", compId)
  );
  const querySnapshot = await getDocs(q);
  const list = querySnapshot.docs.map((doc) => {
    let d=doc.data()
      return  {
        compLoc : d.compLoc,
        compAddress : d.compAddress
      }
  });
  cb(list[0])
}
export const saveWithId = async(collection, id , payload)=>{
  await setDoc(doc(db, collection, id), payload);
}
export const mergeObj = async(collection, id ,obj)=>{
  await setDoc(doc(db, collection, id),obj, {merge:true});
}
export const updateContActivebyContNo = async(contNo , state)=>{
  let lastMonth = Date.parse(new Date())-2635200000
  const q = query(collection(db, "Container"), 
  where("contNo", "==", contNo),
  where("detTS", ">=", lastMonth)
  );
  const querySnapshot = await getDocs(q);
  const contId = querySnapshot.docs.map((doc) => {
    return doc.data().contId
  })
    await updateDoc(doc(db, 'Container', contId[0]), {active: state});
}
export const verifyExstContNo = async(contNo , cb)=>{
  let lastMonth = Date.parse(new Date())-2635200000
  const q = query(collection(db, "Container"), 
  where("contNo", "==", contNo),
  where("detTS", ">=", lastMonth)
  );
  const querySnapshot = await getDocs(q);
  const contId = querySnapshot.docs.map((doc) => {
    return doc.data().contId
  })
  cb(contId[0])
}
export const updateMatchTx2StepN= async(collection, id, stepN , sealNo = '')=>{
  let obj
  switch (stepN) {
    case 1.1:
      obj = {
        stepNo: 1.1,       
        msReqr: "cancel",
        msReqrTS: Date.parse(new Date()),
        jbReqr:"cancel",
        jbReqrTS:Date.parse(new Date()),
        actReqr:{
          cancel:false, 
          accept: false, 
          reject:false, 
          attachSeal:false,
          terminate:false,
          transfer:false,
        },  
        actRels:{
          cancel:false, 
          accept: false, 
          reject:false, 
          attachSeal:false,
          terminate:false,
          transfer:false,
        },
        msRels: "cancel",
        msRelsTS:Date.parse(new Date()),      
        jbRels:"cancel",
        jbRelsTS:Date.parse(new Date()),
        status: "cancel"
      }
      break;
    case 1.2:
      obj = {
        stepNo: 1.2,       
        msReqr: "reject",
        msReqrTS: Date.parse(new Date()),
        jbReqr: "reject",
        jbReqrTS:Date.parse(new Date()),
        actReqr:{
          cancel:false, 
          accept: false, 
          reject:false, 
          attachSeal:false,
          terminate:false,
          transfer:false,
        },  
        actRels:{
          cancel:false, 
          accept: false, 
          reject:false, 
          attachSeal:false,
          terminate:false,
          transfer:false,
        },
        msRels: "reject",
        msRelsTS:Date.parse(new Date()),      
        jbRels:"reject",
        jbRelsTS:Date.parse(new Date()),
        status: "reject"
      }
      break;
    case 2:
      obj = {
        stepNo: 2,          
        jbReqr:"waitApproval",
        jbReqrTS:Date.parse(new Date()),
        actReqr:{
          cancel:false, 
          accept: false, 
          reject:false, 
          attachSeal:false,
          terminate:false,
          transfer:false,
        },  
        actRels:{
          cancel:false, 
          accept: false, 
          reject:false, 
          attachSeal:false,
          terminate:false,
          transfer:false,
        },                        
        jbRels:'waitApproval',
        jbRelsTS:Date.parse(new Date()),
        status: "matched"
      }
      break;
    case 2.1:
      obj = {
        stepNo: 2.1,       
        jbReqr:'shipsRej',
        jbReqrTS:Date.parse(new Date()),
        actReqr:{
          cancel:false, 
          accept: false, 
          reject:false, 
          attachSeal:false,
          terminate:false,
          transfer:false,
        },  
        actRels:{
          cancel:false, 
          accept: false, 
          reject:false, 
          attachSeal:false,
          terminate:false,
          transfer:false,
        },     
        jbRels:'shipsRej',
        jbRelsTS:Date.parse(new Date()),
        status: "shipsRej"
      }
      break;
    case 3:
      obj = {
        stepNo: 3,         
        msReqr: 'approved',
        msReqrTS: Date.parse(new Date()),
        jbReqr: 'activeTrans',
        jbReqrTS:Date.parse(new Date()),
        actReqr:{
          cancel:false, 
          accept: false, 
          reject:false, 
          attachSeal:true,
          terminate:true,
          transfer:true,
        },  
        actRels:{
          cancel:false, 
          accept: false, 
          reject:false, 
          attachSeal:false,
          terminate:false,
          transfer:false,
        },
        msRels: 'approved',
        msRelsTS:Date.parse(new Date()),                                        
        jbRels: 'activeTrans',
        jbRelsTS:Date.parse(new Date()),
        status: "matched",
      }
      break;
    case 4:
      obj = {
        stepNo: 4,         
        msReqr: 'completed',
        msReqrTS: Date.parse(new Date()),
        jbReqr:'completed',
        jbReqrTS:Date.parse(new Date()),
        actReqr:{
          cancel:false, 
          accept: false, 
          reject:false, 
          attachSeal:false,
          terminate:true,
          transfer:true,
          eir:true,
          updateSeal:true
        },  
        actRels:{
          cancel:false, 
          accept: false, 
          reject:false, 
          attachSeal:false,
          terminate:false,
          transfer:false,
          eir:false,
          updateSeal:false
        },
        msRels: 'completed',
        msRelsTS:Date.parse(new Date()),                                        
        jbRels: 'completed',
        jbRelsTS:Date.parse(new Date()),
        status: "completed",
        timestamp: Date.parse(new Date()),
        sealNo : sealNo 
      }
      break;
    case 4.1:
      obj = {
        stepNo: 4.1,         
        msReqr: 'transferred',
        msReqrTS: Date.parse(new Date()),
        jbReqr:'transferred',
        jbReqrTS:Date.parse(new Date()),
        actReqr:{
          cancel:false, 
          accept: false, 
          reject:false, 
          attachSeal:false,
          terminate:false,
          transfer:false,
          updateSeal:false
        },  
        actRels:{
          cancel:false, 
          accept: false, 
          reject:false, 
          attachSeal:false,
          terminate:false,
          transfer:false,
          updateSeal:false
        },
        msRels: 'transferred',
        msRelsTS:Date.parse(new Date()),                                        
        jbRels: 'transferred',
        jbRelsTS:Date.parse(new Date()),
        status: "transferred",
        timestamp: Date.parse(new Date()),
      }
      break;
    case 4.2:
      obj = {
        stepNo: 4.2,         
        msReqr: 'terminated',
        msReqrTS: Date.parse(new Date()),
        jbReqr:'terminated',
        jbReqrTS:Date.parse(new Date()),
        actReqr:{
          cancel:false, 
          accept: false, 
          reject:false, 
          attachSeal:false,
          terminate:false,
          transfer:false,
          updateSeal:false
        },  
        actRels:{
          cancel:false, 
          accept: false, 
          reject:false, 
          attachSeal:false,
          terminate:false,
          transfer:false,
          updateSeal:false
        },
        msRels: 'terminated',
        msRelsTS:Date.parse(new Date()),                                        
        jbRels: 'terminated',
        jbRelsTS:Date.parse(new Date()),
        status: "terminated",
        timestamp: Date.parse(new Date()),
      }
      break;
    default:
      break;
  }
  console.log(`data=> ${JSON.stringify(obj , null , 2)}`)
  // await updateDoc(doc(db, collection, id), obj);
  await setDoc(doc(db, collection, id),obj, {merge:true});
}
export const makeMatchTxTrStep2 = async(mTxId , newBkgId)=>{
  const q = query(collection(db, "MatchTx"),  
    where('mTxId', '==', mTxId)
  );
  const querySnapshot = await getDocs(q);
  const mTx = querySnapshot.docs.map((doc) => {
    let d = doc.data()
    Object.assign(d, {
      mTxId : d.mTxId + "TR",
      bkgId : newBkgId,
      jobTypeReqr : 'Transfer',
      jobTypeRels : 'Transfer',
      stepNo : 2,
      status : "matched",
      actReqr:{
        cancel:false, 
        accept: false, 
        reject:false, 
        attachSeal:false,
        terminate:false,
        transfer:false,
      },  
      actRels:{
        cancel:false, 
        accept: false, 
        reject:false, 
        attachSeal:false,
        terminate:false,
        transfer:false,
      }, 
      jbReqr :  "waitApproval",
      jbRels :  "waitApproval",
      msReqr : 'matched',
      msRels : 'matched',
      txSearcher : "Transfer",
      timestamp : Date.parse(new Date())
    })
    return d
  });
  console.log(`[makeMatchTxTrStep2], mTx[0] =>  ${JSON.stringify(mTx[0], null, 2)}`)
  await setDoc(doc(db, "MatchTx", mTx[0].mTxId), mTx[0]);
}
export const updateStatus= async(collection, id, status )=>{
  await updateDoc(doc(db, collection, id), { status: status});
}
export const updateActive= async(collection, id, state )=>{
  await updateDoc(doc(db, collection, id), { active: state});
}
export const updateSealMatchTx= async(mTxId , sealNo)=>{
  await updateDoc(doc(db, "MatchTx", mTxId), { sealNo: sealNo});
}
export const userInfoByUid= async(uid, cb)=>{
  const q = query(collection(db, "Users"), 
  where("uid", "==", uid)
  );
  const querySnapshot = await getDocs(q);
  const user = querySnapshot.docs.map((doc) => {
    return doc.data()
  })
  cb(user[0])
}
export const userInfoByUserId= async(userId, cb)=>{
  const q = query(collection(db, "Users"), 
  where("active", "==", true), 
  where("userId", "==", userId)
  );
  const querySnapshot = await getDocs(q);
  const user = querySnapshot.docs.map((doc) => {
    return doc.data()
  })
  cb(user[0])
}
export const userListAll = async(cb)=>{
  const q = query(collection(db, "Users")
  );
  const querySnapshot = await getDocs(q);
  const list = querySnapshot.docs.map((doc) => {
    return doc.data()
  });
  cb(list)
}
export const mTxIdReuByReuId= async(reuId, cb)=>{
  const q = query(collection(db, "MatchTx"), 
    where("reqrId", "==", reuId)
  );
  const querySnapshot = await getDocs(q);
  const m = querySnapshot.docs.map((doc) => {
    let d = doc.data()
    return {
      mTxId : d.mTxId,
      msReqr : d.msReqr,
      msRels : d.msRels
    }
  })
  cb(m[0])
}
export const shipReviewByAgent= async(agent, startAt, endAt, cb)=>{
  const q = query(collection(db, "ShipReport"), 
    where('agent', '==', agent),  
    where('timestamp', '>=' , startAt),  
    where('timestamp', '<=' , endAt),  
  );
  const querySnapshot = await getDocs(q);
  const list = querySnapshot.docs.map((doc) => {
    return doc.data()
  })
  cb(list)
}
export const shipReviewRemark= async(mTxId, cb)=>{
  const q = query(collection(db, "ShipReport"), 
    where('mTxId', '==', mTxId),    
  );
  const querySnapshot = await getDocs(q);
  const list = querySnapshot.docs.map((doc) => {
    return doc.data().remark
  })
  cb(list[0])
}
