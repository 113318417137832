<template>
    <div>
      <Breadcrumbs main="walletReport.report" title="walletReport.header" />
      <b-container fluid>
        <div class="row"> 
          <div class="col-sm-3 ml-3 mt-4">
            <b-button class="my-2 f-w-600" style="font-size:18px;" variant="outline-primary" block @click="exportExcel">
              {{ $t('refillSlip.export') }}
            </b-button>      
          </div> 
        </div>  
        <div>
        <b-table
          sticky-header
          head-variant="light"
          table-variant="info"
          show-empty
          sort-icon-left
          stacked="md"      
          :items="items"
          :fields="fields"
          v-model="visibleRows"
          :current-page="currentPage"
          :per-page="perPage"
        >
          <template slot="bottom-row">
            <td></td>
            <td class="text-right"><span style="font-size: 18px;">{{ $t('refillSlip.total') }} </span></td>
            <!-- this is a computed prop that adds up all the expenses in the visible rows -->
            <td><span style="font-size: 18px;"> {{ totalExpenses }} </span></td>          
            <td></td>
          </template>
          <template #empty>
              <h5>{{ $t('refillSlip.noRecord') }}</h5>
          </template>
        </b-table>
        <b-row>
          <div class="row col-sm-6 ">
            <b-col class="my-1 ml-3">
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                align="fill"
                class="my-0"
              ></b-pagination>
            </b-col>   
            <b-col sm="3" md="6" class="my-1">
              <b-form-group
                :label= "$t('refillSlip.perPage')"
                label-for="per-page-select"
                label-cols-sm="8"
                label-cols-md="6"
                label-cols-lg="4"
                label-align-sm="right"
                label-size="sm"
                class="mb-0"
              >
                <b-form-select
                  id="per-page-select"
                  v-model="perPage"
                  :options="pageOptions"
                ></b-form-select>
              </b-form-group>
            </b-col>
          </div>
        </b-row>
      </div>
    </b-container>
    </div>
  </template>
  <script>
  import { getWalletData } from "../../firebase"
  import {exportXlsx} from "../../utilities/util"
    export default {
      data() {
        return {
          format: "yyyy-MM-dd",
          startDate : '',
          endDate : '',
          items: [],
          fields: [
            { key: 'compId', label: this.$t('refill.compId'), sortable: true, },
            { key: 'compNameLocal', label: this.$t('refill.compNameLocal'), sortable: true, },
            { key: 'balanced', label: this.$t('walletReport.balanced'), sortable: true, variant: 'warning'},                
            { key: 'timestamp', label: this.$t('refillSlip.date'), sortable: true, 
              formatter: value => {
              return this.$moment(value).format("YY-MM-DD @ HH:mm")
            }},
          ],
          totalRows: 1,
          currentPage: 1,
          perPage: 10,
          pageOptions: [5, 10, 15, { value: 100, text: "Show a lot" }],
          visibleRows: []
        }
      },
      computed: {
        totalExpenses() {
          return this.items.reduce((accum, item) => {
            // Assuming expenses is the field you want to total up
            return accum + parseFloat(item.balanced)
          }, 0.00)
        }
      },
      mounted() {
        this.getWallets()
      },
      methods: {
        async getWallets(){
          await getWalletData((res)=>{
            this.items = res
            this.totalRows = res.length
          })
        },
  
        exportExcel() {
          if (this.items.length == 0) {
            this.$toasted.show(this.$t('refillSlip.noData'), {theme: 'bubble', type: 'error' , position: 'bottom-center', duration: 5000}); 
          } else {
            exportXlsx(this.items , `wallets-${this.$moment().format("YYYY-MM-DD-HH-mm")}`)
          }
        },
      }
    }
  </script>