<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-12 p-0">
                <!-- <div class="text-center mb-1"><img src="../../assets/images/conex-logo.png" alt=""></div> -->
                <h1 class="text-center">{{ $t('adjustBal.title') }}</h1>
                <b-alert v-model="showAlertRefilled" variant="warning" dismissible class="col-sm-12">
                    <h5 class="alert-heading">{{ $t(`${this.$t("refill.attn")}`)}}<span class="text-dark">{{ $t(`${selectedCompNameLocal}`) }}</span></h5>
                    <div>
                        <span>{{ $t(`${this.$t("refill.compId")}: `) }}<span class="text-dark">{{ $t(`${selectedCompId}`) }} </span></span>
                        <span>{{ $t(`${this.$t("refill.contactPhone")}: `) }}<span class="text-dark">{{ $t(`${selectedContactPhone}`) }} </span></span>
                    </div>
                    <div>
                        <span>{{ $t(`${this.$t("adjustBal.adjusted")} `) }}</span>
                        <span class="text-dark mx-2">{{ amount  }}</span>
                        <span>{{ $t(`${this.$t("refill.mUnit")} =>${this.$t("refill.wallet")} :`) }}</span>
                        <span class="text-dark mx-2">{{ balanced}}</span>
                        <span>{{ $t(`${this.$t("refill.mUnit")}`) }}</span>
                    </div>
                </b-alert>
                <b-modal ref="adjust-modal" :title="$t('adjustBal.title')" hide-footer>
                    <div class="d-block">
                        <span class="f-w-600 text-primary" style="font-size:16px">{{ $t('adjustBal.adjustMsg') }}<span class="text-success">{{ selectedCompNameLocal }} </span></span>
                    </div>
                    <!-- <b-button class="my-2" style="font-size:18px;" variant="outline-warning" block @click="refillModal">
                        {{ $t('require.dontPost') }}
                    </b-button>                             -->
                    <div class="col-sm-12">                                   
                        <form @submit.stop.prevent="adjustModal">
                            <div class="row ml-2">                                                   
                                <label class="f-w-600 text-dark mt-1 mr-2" style="font-size:16px;">{{ $t("refill.amount")}}:</label>
                                <input type="number" class=" f-w-600 text-primary" style="font-size:16px" v-model="amount">   
                            </div>
                            <div class="row ml-2">
                                <label class="f-w-600 text-dark mt-1 mr-2" style="font-size:16px;">{{ $t("refill.remarkAdjust") }}: </label> 
                                <input type="text" class="f-w-600 text-secondary form-control form-control-lg" style="font-size:16px" v-model="remark"> 
                            </div>
                        </form>  
                        <b-button-group>
                            <b-button  class="mt-1" size="lg" variant="danger"  @click="cancelModal">
                                <span style="font-size: 16px;">
                                    {{ $t("refill.cancel") }}
                                </span>                                                                             
                            </b-button>                       
                            <b-button class="mt-1 ml-4" size="lg" variant="info"  @click="adjustModal">
                                <span style="font-size: 16px;">
                                    {{ $t("adjustBal.adjust") }}
                                </span>                                                                             
                            </b-button>     
                        </b-button-group>                                   
                    </div>   
                </b-modal>
                <div class="row">
                    <b-col lg="3" class="ml-3 mt-3">
                        <b-form-group
                        :label="$t('refill.filter')"
                        label-for="filter-input"
                        label-cols-sm="3"
                        label-align-sm="right"
                        label-size="lg"
                        class="mb-0"
                        >
                        <b-input-group size="sm">
                            <b-form-input
                            id="filter-input"
                            v-model="filter"
                            type="search"
                            :placeholder="$t('refill.typeToSearch')"
                            class="text-info"
                            style="font-size: 18px;"
                            ></b-form-input>
                            <b-input-group-append>
                            <b-button size="md" variant="primary" :disabled="!filter" @click="filter = ''">
                                {{ $t('refill.clear') }}
                            </b-button>
                            </b-input-group-append>
                        </b-input-group>
                        </b-form-group>
                    </b-col>
                    <b-col lg="5" class="my-1">
                        <b-form-group
                        v-model="sortDirection"
                        :label="$t('refill.filterOn')"
                        :description= "$t('refill.uncheck')"
                        label-cols-sm="3"
                        label-align-sm="right"
                        label-size="lg"
                        class="mb-0"
                        v-slot="{ ariaDescribedby }"
                        >
                        <b-form-checkbox-group
                            v-model="filterOn"
                            :aria-describedby="ariaDescribedby"
                            class="mt-1 text-info"
                            plain
                        >
                        <b-form-checkbox value="compId">{{ $t('refill.compId') }}</b-form-checkbox>                                                                
                            <b-form-checkbox value="compNameLocal">{{ $t('refill.compNameLocal') }}</b-form-checkbox>
                            <b-form-checkbox value="compNameEng">{{ $t('refill.compNameEng') }}</b-form-checkbox>
                            <b-form-checkbox value="contactPhone">{{ $t('refill.contactPhone') }}</b-form-checkbox>   
                        </b-form-checkbox-group>
                        </b-form-group>
                    </b-col>                                         
                </div>
                <b-table
                sticky-header
                head-variant="light"
                table-variant="warning"
                show-empty
                sort-icon-left
                stacked="md"
                :sort-direction="sortDirection"
                :filter="filter"
                :filter-included-fields="filterOn"
                :items="items"
                :fields="fields"
                :current-page="currentPage"
                :per-page="perPage"
                @filtered="onFiltered"
                >
                    <template #cell(adjust)="row" >
                        <b-button pill size="sm" @click="openModalAdjust(row.item, row.index, $event.target )" class="m-1">
                        <span class="f-w-600" style="font-size: 16px;"> {{ $t('adjustBal.adjust') }}</span>
                        </b-button>
                    </template>
                </b-table>
                <b-row>
                    <div class="row col-sm-8 ">
                        <b-col class="my-1 ml-3">
                            <b-pagination
                            v-model="currentPage"
                            :total-rows="totalRows"
                            :per-page="perPage"
                            align="fill"
                            class="my-0"
                            ></b-pagination>
                        </b-col>   
                        <b-col sm="3" md="6" class="my-1">
                            <b-form-group
                            :label= "$t('refill.perPage')"
                            label-for="per-page-select"
                            label-cols-sm="6"
                            label-cols-md="4"
                            label-cols-lg="3"
                            label-align-sm="right"
                            label-size="sm"
                            >
                            <b-form-select
                                id="per-page-select"
                                v-model="perPage"
                                :options="pageOptions"
                            ></b-form-select>
                            </b-form-group>
                        </b-col>
                    </div>
                </b-row>
            </div>
        </div>
    </div>
 </template>
 
 <script>
 // import { createUserWithEmailAndPassword } from 'firebase/auth'
 import { compListAllRefill, saveWithId, getBalance, sumUpWallet} from "../../firebase"
 export default {
     name: 'adjustBal',
 
     data() {
         return{
            selectedCompId : '',            
            selectedCompNameLocal : '',
            selectedCompNameEng : '',
            selectedContactPhone : '',
            amount: 0,
            balanced: null,
            remark : '',         
            isBusy: false,
            adminId : '',
            // isHovered: false,
            showAlertRefilled : false,
            items: [],
            fields: [
                { key: 'compId', label: this.$t('refill.compId'), sortable: true },  
                { key: 'compNameLocal', label: this.$t('refill.compNameLocal'), sortable: true},
                { key: 'compNameEng', label: this.$t('refill.compNameEng'), sortable: true},        
                { key: 'contactPhone', label: this.$t('refill.contactPhone'), sortable: true},            
                { key: 'adjust', label: this.$t('refill.action'), variant: 'warning'},
            ],
            totalRows: 1,
            currentPage: 1,
            perPage: 5,
            pageOptions: [5, 10, 15, { value: 100, text: "Show a lot" }],
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
            // modalTitle : ''
         }
     },
     components: {
     },
     mounted(){
        this.adminId = localStorage.getItem("userId")        
        compListAllRefill((res)=>{
            this.items = res
        })    
     },
     methods: {
        onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length
        this.currentPage = 1
      },
        openModalAdjust(item, index, button) {
            this.selectedCompId = item.compId
            this.selectedCompNameLocal = item.compNameLocal
            this.selectedCompNameEng= item.compNameEng
            this.selectedContactPhone= item.contactPhone
            this.amount = 0
            this.remark = ''
            this.$refs['adjust-modal'].show()
            this.isBusy = false
            this.showAlertRefilled = false 
            this.$root.$emit('bv::show::modal', "adjustModal" , button)
        },

        cancelModal() {
            this.$refs['adjust-modal'].hide()
            this.amount = 0
            this.showAlertRefilled = false
        },
        async adjustModal() {
            if (this.amount == 0) {
                this.$toasted.show( `${this.$t("refill.refillAmountMsg")}`, {theme: 'bubble', type: 'danger' , position: 'bottom-center', duration: 5000}); 
            } else {
                this.amount = parseInt(this.amount)
                await sumUpWallet(this.amount, this.selectedCompId).then(()=>{
                    this.$refs['adjust-modal'].hide()   
                    getBalance(this.selectedCompId, (res)=>{
                        this.balanced = res
                        this.$toasted.show( `${this.$t("adjustBal.adjust")} ${this.amount} ${this.$t("refill.mUnit")} ${this.$t("refill.done")}=>${this.$t("refill.wallet")} : ${res} ${this.$t("refill.mUnit")}`, {theme: 'bubble', type: 'success' , position: 'bottom-center', duration: 5000});   
                        this.showAlertRefilled = true
                    })   
                }) 
                const uaTx = {
                    slipId : `${this.$moment().format("YYMMDDHH")}-${Math.floor(Math.random() * (90000 - 10000 + 1) + 10000)}`,
                    payerId : this.selectedCompId,   
                    paymentMethod : "none",
                    amount : this.amount,
                    acctType : "adjust",
                    reason : this.remark || "adjust Balanced",
                    timestamp : new Date().valueOf(),
                }
                await saveWithId('uaTx', uaTx.slipId, uaTx)
            }
        },
     }
 }
 </script>
 