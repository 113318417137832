<template>
	<div>
		<Breadcrumbs main="company.resource" title="company.header"/>
		<!-- Container-fluid starts-->
		<div class="container-fluid">
            <div v-show="process === 'add' ">
                <div class="row ml-1" v-b-hover="handleHover">
                        <h3 class="text-primary">{{ $t('company.add') }}</h3>
                        <span class="text-danger" style="font-size: 30px; font-weight: bold; cursor: pointer" @click="process = 'info' ">&times;</span>
                        <span v-show="isHovered" class="text-danger">{{ $t('company.cancelReturn')  }} </span>
                </div>
                <div class="row">
                    <div class="col-sm-6">
                        <form class="theme-form">
                            <div class="form-group">
                                <label for="compNameLocal" class="col-form-label pt-0">{{ $t("company.compNameLocal") }}</label>                                                 
                                <input  v-model="compNameLocal" class="form-control" type="text" id="compNameLocal" :placeholder="$t('company.compNameLocalPh')" >
                            </div>      
                            <div class="form-group">
                                <label for="compNameEng" class="col-form-label pt-0">{{ $t("company.compNameEng2") }}</label>    
                                <input v-model="compNameEng" class="form-control" type="text" id="compNameEng" :placeholder="$t('company.compNameEngPh')" >
                            </div>
                            <div class="form-group">
                                <label for="busType" class="form-label">{{ $t("company.busType") }}</label>
                                <select class="form-control mb-1" id="busType" v-model="busType">
                                    <option value="logist">{{ $t("company.logist") }}</option>
                                    <option value="agent">{{ $t("company.agent") }}</option>
                                    <option value="cert">{{ $t("company.cert") }}</option>    
                                    <option value="conex">{{ $t("company.conex") }}</option>                                             
                                </select>
                            </div>
                            <div class="form-group text-primary">
                                <div class="row">
                                    <label class="col-form-label pt-0 ml-3">{{ $t("company.compAddress") }}</label>    
                                    <a href="#googleMap"><u class=" ml-2 fw-bold text-info" style="font-size:15px" @click="showAC"> <i class="icofont icofont-map-search mr-2 " style="font-size:25px"></i>Google Map </u></a>                                
                                </div>

                                <input v-show="compAddress != ''" v-model="compAddress" class="form-control text-info" type="text" id="compAddress" :placeholder="$t('company.compAddressPh') " >
                                <h6 class=" ml-3 text-info"> Latitude : {{ compLoc.lat }}  ,  Longitude : {{ compLoc.lng }}</h6>
                            </div>
                            <div class="form-group">
                                <label for="contactEmail" class="col-form-label pt-0">{{ $t("company.contactEmail") }}</label>
                                <input v-model="contactEmail" class="form-control" type="email" id="contactEmail" :placeholder="$t('company.contactEmailPh')" >
                            </div>
                            <div class="form-group">
                                <label for="contactPhone" class="col-form-label pt-0">{{ $t("company.contactPhone") }}</label>
                                <input v-model="contactPhone" class="form-control" type="text" id="contactPhone" :placeholder="$t('company.contactPhonePh')">
                            </div>
                            <div class="form-group">
                                <label for="taxId" class="col-form-label pt-0">{{ $t("company.taxId") }}</label>                            
                                <input v-model="taxId" class="form-control" type="text" id="taxId" :placeholder="$t('company.taxIdPh')">
                            </div>
                            <div class="form-group mt-3 mb-0">
                                <button class="btn btn-primary btn-block" type="button" @click="handleSave">{{ $t('company.save') }}</button>
                            </div>
                        </form>
                    </div>
                    <div class="col-sm-6" id="googleMap">
                        <section class="ui one column centered grid mt-5" v-show="showAutoComplete" style="position:relative; z-index: 5;">
                            <div class="column"  >
                                <form class="ui left segment small form" >
                                    <div class="ui message red" v-show="error">
                                        {{ error }}
                                    </div>
                                    <div class="field" >
                                        <div class="row mb-3 col-sm-12">
                                            <div class=" col-sm-1">
                                                <i class="icon times text-primary" style="font-size: 24px;"  @click="offAC" ></i>                                                    
                                            </div>
                                            <div class="col-sm-11">
                                                <span class="text-warning"> {{ $t('company.donotEnterKey') }}</span>       
                                            </div>                                    
                                        </div>
                                        <div class="ui right icon input large" :class="{loading:spinner}">
                                            <input 
                                                type="text"
                                                v-b-popover.hover="$t('company.inputLocNameMsg')" 
                                                :title="$t('company.inputLocName')"  
                                                id="autocompleteComp"
                                                v-model="address"
                                                onkeypress= "return event.keyCode != 13"
                                            >
                                            <i 
                                                class="dot circle link icon" 
                                                @click="locatorButtonPressed"
                                                v-b-popover.hover.top="$t('company.useCurrPosition')"
                                            ></i>
                                        </div>
                                    </div>
                                    <div class="row" @click="clearAddress">
                                        <i class="icon eraser f-10 ml-4 text-danger"  style="font-size: 18px;"></i>
                                        <h5 class="ml3 text-info" > {{ $t('company.clearAddress') }} </h5>
                                    </div>
                                </form>                               
                            </div>
                        </section> 
                        <section id="map" style="background-color: lightblue;"></section>   
                    </div>
                </div>
            </div>
            <div v-show="process === 'update' ">
                <div class="row ml-1" v-b-hover="handleHoverUpdate">
                        <h3 class="text-primary">{{ $t('company.edit') }}</h3>
                        <span class="text-danger" style="font-size: 30px; font-weight: bold; cursor: pointer" @click="process = 'info' ">&times;</span>
                        <span v-show="isHoveredUpdate" class="text-danger">{{ $t('company.cancelReturn')  }} </span>
                </div>
                <div class="row">
                    <div class="col-sm-6" id="googleMapUpdate">
                        <form class="theme-form">
                            <div class="form-group">
                                <label for="compNameLocalUpdate" class="col-form-label pt-0">{{ $t("company.compNameLocal") }}</label>                                                 
                                <input  v-model="compNameLocalUpdate" class="form-control" type="text" id="compNameLocalUpdate" :placeholder="$t('company.compNameLocalPh')" >
                            </div>      
                            <div class="form-group">
                                <label for="compNameEngUpdate" class="col-form-label pt-0">{{ $t("company.compNameEng") }}</label>    
                                <input v-model="compNameEngUpdate" class="form-control bg-light " type="text" id="compNameEngUpdate" disabled>
                            </div>
                            <div class="form-group">
                                <label for="busTypeUpdate" class="form-label">{{ $t("company.busType") }}</label>
                                <input v-model="busTypeUpdate" class="form-control bg-light" type="text" id="busTypeUpdate"  disabled>
                            </div>
                            <div class="form-group text-primary">
                                <div class="row">
                                    <label class="col-form-label pt-0 ml-3">{{ $t("company.compAddress") }}</label>    
                                    <a href="#googleMapUpdate"><u class=" ml-2 fw-bold text-info" style="font-size:15px" @click="showACUpdate"> <i class="icofont icofont-map-search mr-2 " style="font-size:25px"></i>Google Map </u></a>                                
                                </div>

                                <input v-model="compAddressUpdate" class="form-control text-info" type="text" id="compAddressUpdate" :placeholder="$t('company.compAddressPh') " >
                                <h6 class=" ml-3 text-info"> Latitude : {{ compLocUpdate.lat }}  ,  Longitude : {{ compLocUpdate.lng }}</h6>
                            </div>
                            <div class="form-group">
                                <label for="contactEmailUpdate" class="col-form-label pt-0">{{ $t("company.contactEmail") }}</label>
                                <input v-model="contactEmailUpdate" class="form-control" type="email" id="contactEmailUpdate" :placeholder="$t('company.contactEmailPh')" >
                            </div>
                            <div class="form-group">
                                <label for="contactPhoneUpdate" class="col-form-label pt-0">{{ $t("company.contactPhone") }}</label>
                                <input v-model="contactPhoneUpdate" class="form-control" type="text" id="contactPhoneUpdate" :placeholder="$t('company.contactPhonePh')">
                            </div>
                            <div class="form-group">
                                <label for="taxIdUpdate" class="col-form-label pt-0">{{ $t("company.taxId") }}</label>                            
                                <input v-model="taxIdUpdate" class="form-control" type="text" id="taxIdUpdate" :placeholder="$t('company.taxIdPh')">
                            </div>
                            <div class="form-group mt-3 mb-0">
                                <button class="btn btn-primary btn-block" type="button" @click="handleUpdate">{{ $t('company.update') }}</button>
                            </div>
                        </form>
                    </div>
                    <div class="col-sm-6" >
                        <section class="ui one column centered grid mt-5" v-show="showAutoCompleteUpdate" style="position:relative; z-index: 5;">
                            <div class="column" >
                                <form class="ui left segment small form" >
                                    <div class="ui message red" v-show="errorUpdate">
                                        {{ errorUpdate }}
                                    </div>
                                    <div class="field" >
                                        <div class="row mb-3 col-sm-12">
                                            <div class=" col-sm-1">
                                                <i class="icon times text-primary" style="font-size: 24px;"  @click="offACUpdate" ></i>                                                    
                                            </div>
                                            <div class="col-sm-11">
                                                <span class="text-warning"> {{ $t('company.donotEnterKey') }}</span>       
                                            </div>                                    
                                        </div>
                                        <div class="ui right icon input large" :class="{loading:spinnerUpdate}">
                                            <input 
                                                type="text"
                                                id="autocompleteCompUpdate"
                                                v-model="addressUpdate"
                                                onkeypress= "return event.keyCode != 13;"
                                            >
                                            <i class="dot circle link icon" @click="locatorButtonPressedUpdate" ></i>
                                        </div>
                                    </div>
                                    <div class="row" @click="clearAddressUpdate">
                                        <i class="icon eraser f-10 ml-4 text-danger"  style="font-size: 18px;"></i>
                                        <h5 class="ml3 text-info" > {{ $t('company.clearAddress') }} </h5>
                                    </div>
                                </form>                               
                            </div>
                        </section> 
                        <section id="mapUpdate" style="background-color: lightblue;"></section>   
                    </div>
                </div>
            </div>            
            <div>
                <div class="row">
                    <h4 class="mb-1 mt-4 ml-3 f-w-600">{{ $t('company.allCompList') }}</h4>
                    <b-button pill size="sm" @click="processAdd" class="bg-primary mb-2 mt-3 ml-3 ">
                        <span class="f-w-600 text-white" style="font-size: 18px;">{{ $t('company.add') }} </span>
                    </b-button>                    
                </div>
                <div class="row">
                    <b-col lg="3" class="ml-1 mt-1">
                        <b-form-group
                        :label="$t('matchTx.filter')"
                        label-for="filter-input"
                        label-cols-sm="3"
                        label-align-sm="right"
                        label-size="lg"
                        class="mb-0"
                        >
                        <b-input-group size="sm">
                            <b-form-input
                            id="filter-input"
                            v-model="filter"
                            type="search"
                            :placeholder="$t('matchTx.typeToSearch')"
                            class="text-info"
                            style="font-size: 18px;"
                            ></b-form-input>
                            <b-input-group-append>
                            <b-button size="md" variant="primary" :disabled="!filter" @click="filter = ''">
                                {{ $t('matchTx.clear') }}
                            </b-button>
                            </b-input-group-append>
                        </b-input-group>
                        </b-form-group>
                    </b-col>
                    <b-col lg="8" class="my-1">
                        <b-form-group
                        v-model="sortDirection"
                        :label="$t('matchTx.filterOn')"
                        :description= "$t('matchTx.uncheck')"
                        label-cols-sm="5"
                        label-align-sm="right"
                        label-size="lg"
                        class="mb-0"
                        v-slot="{ ariaDescribedby }"
                        >
                        <b-form-checkbox-group
                            v-model="filterOn"
                            :aria-describedby="ariaDescribedby"
                            class="mt-1 text-info"
                            plain
                        >
                            <b-form-checkbox value="compId">{{ $t('company.compId') }}</b-form-checkbox>                            
                            <b-form-checkbox value="compNameLocal">{{ $t('company.compNameLocal') }}</b-form-checkbox>
                            <b-form-checkbox value="compNameEng">{{ $t('company.compNameEng') }}</b-form-checkbox>                                                      
                            <b-form-checkbox value="contactEmail">{{ $t('company.contactEmail') }}</b-form-checkbox>  
                            <b-form-checkbox value="compAddress">{{ $t('company.address') }}</b-form-checkbox>   
                            <b-form-checkbox value="contactPhone">{{ $t('company.contactPhone') }}</b-form-checkbox>   
                        </b-form-checkbox-group>
                        </b-form-group>
                    </b-col>                          
                </div>                
                <b-table
                    sticky-header
                    head-variant="light"
                    table-variant="success"
                    show-empty
                    sort-icon-left
                    stacked="md"
                    sort-direction="asc"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    :items="items"
                    :fields="fields"
                    :tbody-tr-class="rowClass"
                    :current-page="currentPage"
                    :per-page="perPage"
                    @filtered="onFiltered"
                >
                    <template #cell(edit)="row" >
                        <b-button pill size="sm" @click="actUpdate(row.item)" class="m-1">
                           <span class="f-w-600" style="font-size: 14px;"> {{ $t('company.update') }}</span>
                        </b-button>
                    </template>
                    <template #cell(actActive)="row" >
                        <b-button pill v-show="row.item.active" size="sm" variant="danger" @click="actSuspend(row.item)" class="m-1">
                            <span class="f-w-600" style="font-size: 14px;"> {{ $t('company.suspend') }}</span>
                        </b-button>
                        <b-button pill v-show="!row.item.active" size="sm" variant="success" @click="actAble(row.item)" class="m-1">
                            <span class="f-w-600" style="font-size: 14px;"> {{ $t('company.able') }}</span>
                        </b-button>
                    </template>
                </b-table>
                <b-row>
                    <div class="row col-sm-8 ">
                        <b-col class="my-1 ml-3">
                            <b-pagination
                            v-model="currentPage"
                            :total-rows="totalRows"
                            :per-page="perPage"
                            align="fill"
                            class="my-0"
                            ></b-pagination>
                        </b-col>   
                        <b-col sm="3" md="6" class="my-1">
                            <b-form-group
                            :label= "$t('require.perPage')"
                            label-for="per-page-select"
                            label-cols-sm="6"
                            label-cols-md="4"
                            label-cols-lg="3"
                            label-align-sm="right"
                            label-size="sm"
                            class="mb-0"
                            >
                            <b-form-select
                                id="per-page-select"
                                v-model="perPage"
                                :options="pageOptions"
                            ></b-form-select>
                            </b-form-group>
                        </b-col>
                    </div>
                </b-row>
            </div>
        </div>
        <!-- Container-fluid Ends-->
    </div>
</template>
<script>
const axios = require("axios");
import { saveWithId ,googleMapApiKey ,updateActive, mergeObj , compListAll} from "../../firebase"
import {registCompAdv} from "../../constants/config"

export default {
	data(){
		return{
            process : "info",
            isHovered: false,
            isHoveredUpdate: false,
            showAutoComplete : false,
            address : '',
            error : '',
            spinner: false,
            compNameLocal: '',
            compNameEng: '',
            compAddress: '',
            compLoc: {},            
            contactEmail: '',
            contactPhone: '',
            taxId:'',
            busType: '',
            showAutoCompleteUpdate : false,
            addressUpdate : '',
            errorUpdate : '',
            spinnerUpdate: false,
            selectedCompId : '',
            compNameLocalUpdate: '',
            compNameEngUpdate: '',
            compAddressUpdate: '',
            compLocUpdate: {},            
            contactEmailUpdate: '',
            contactPhoneUpdate: '',
            taxIdUpdate:'',
            busTypeUpdate: '',
          
            items: [],
            fields: [
                { key: 'compId', label: this.$t('company.compId'), sortable: true },  
                { key: 'compNameLocal', label: this.$t('company.compNameLocal'), variant: 'info'},   
                { key: 'compNameEng', label: this.$t('company.compNameEng'), variant: 'primary'},  
                { key: 'busType', label: this.$t('company.busType'), sortable: true,
                    formatter: value => {
                        return this.$t(`company.${value}`)
                }},                
                { key: 'compAddress', label: this.$t('company.address'), variant: 'info'},  
                { key: 'contactEmail', label: this.$t('company.contactEmail'), sortable: true },  
                { key: 'contactPhone', label: this.$t('company.contactPhone'), sortable: true },  
                { key: 'taxId', label: this.$t('company.taxId'), sortable: true },  
                { key: 'edit', label: this.$t('company.edit'), variant: 'warning'},                
                { key: 'active', label: this.$t('company.active'), variant: 'primary',sortable: true,
                    formatter: value => {
                        return this.$t(`company.${value}`)
                }},
                { key: 'actActive', label: this.$t('company.actActive'), variant: 'danger'}
            ],
            totalRows: 1,
            currentPage: 1,
            perPage: 5,
            pageOptions: [5, 10, 15, { value: 100, text: "Show a lot" }],
            // sortBy: 'timestamp',
            // sortDesc: true,
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
		}
	},

	mounted(){
        compListAll((res)=>{
            this.items = res
            this.totalRows = res.length
        })
	},
    computed: {
      sortOptions() {
        // Create an options list from our fields
        return this.fields
          .filter(f => f.sortable)
          .map(f => {
            return { text: f.label, value: f.key }
          })
      },
    },
	methods:{
        onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length
        this.currentPage = 1
      },
        rowClass(item, type) {
            if (!item || type !== 'row') return
            if (item.active === false) return 'table-danger'
        },
        currentPosition(){
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    (position) =>{ 
                            this.showUserLocationOnTheMap(position.coords.latitude, position.coords.longitude )
                        },
                    error => {
                        this.error = "Locator is unable to find the address. Please type your address manually."
                    }
                );
            } else {
                this.error = error.message
            }
            var autocomplete = new google.maps.places.Autocomplete(
                document.getElementById('autocompleteComp'),
                {
                    bounds: new google.maps.LatLngBounds(
                        new google.maps.LatLng(13.555 , 100.50)
                    )
                }
            )
            autocomplete.addListener("place_changed",  ()=>{
                let place = autocomplete.getPlace()
                // console.log("place =>"+ JSON.stringify(place , null , 2))
                let lat = place.geometry.location.lat()
                let lng = place.geometry.location.lng() 
                if (!place.geometry) {
                    this.$toasted.show(this.$t("require.fillinMatchedChar"), {theme: 'bubble',   position: 'bottom-right',   type: 'error', duration: 15000});
                } else {
                    this.showUserLocationOnTheMap(lat, lng)
                    this.getAddressFrom(lat, lng)              
                    this.compLoc = {
                        lat : lat,
                        lng : lng
                    }
                }
            })  
        },
        compItemPosition(loc){
            // if (navigator.geolocation) {
            //     navigator.geolocation.getCurrentPosition(
            //         (position) =>{ 
            //                 this.showUserLocationOnTheMap(position.coords.latitude, position.coords.longitude )
            //             },
            //         error => {
            //             this.error = "Locator is unable to find the address. Please type your address manually."
            //         }
            //     );
            // } else {
            //     this.error = error.message
            // }
            this.showUserLocationOnTheMapUpdate(loc.lat, loc.lng )
            var autocomplete = new google.maps.places.Autocomplete(
                document.getElementById('autocompleteCompUpdate'),
                {
                    bounds: new google.maps.LatLngBounds(
                        new google.maps.LatLng(13.555 , 100.50)
                    )
                }
            )
            autocomplete.addListener("place_changed",  ()=>{
                let place = autocomplete.getPlace()
                // console.log("place =>"+ JSON.stringify(place , null , 2))
                let lat = place.geometry.location.lat()
                let lng = place.geometry.location.lng() 
                if (!place.geometry) {
                    this.$toasted.show(this.$t("require.fillinMatchedChar"), {theme: 'bubble',   position: 'bottom-right',   type: 'error', duration: 15000});
                } else {
                    this.showUserLocationOnTheMapUpdate(lat, lng)
                    this.getAddressFromUpdate(lat, lng)              
                    this.compLocUpdate = {
                        lat : lat,
                        lng : lng
                    }
                }
            })  
        },
        locatorButtonPressed(){
            this.spinner =true
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    (position) =>{ 
                        let lat = position.coords.latitude
                        let lng = position.coords.longitude
                        this.getAddressFrom(lat, lng )
                        this.showUserLocationOnTheMap(lat, lng )
                        this.compLoc = {
                            lat : lat,
                            lng : lng
                        }
                    },
                    error => {
                        this.error = "Locator is unable to find the address. Please type your address manually."
                        this.spinner =false
                    }
                );
            } else {
                this.error = error.message
                this.spinner =false
                // console.log(`Your browser does not support geolocation API. Please enter your address`);
            }
        },
        async getAddressFrom(lat, lng){
            await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${googleMapApiKey}&loading=async`)
            .then(response =>{
                if (response.data.error_message) {
                    this.error = response.data.error_message
                } else {
                   this.compAddress = response.data.results[0].formatted_address 
                    this.address = response.data.results[0].formatted_address
                    // this.address = ''
                }
                this.spinner =false
            })
            .catch(error =>{
                this.error = error.message
                this.spinner =false
                // console.log(`error.message =>${JSON.stringify(error)}`)
            })
        },
        showUserLocationOnTheMap(latitude , longitude){
            // Creat a map object
            let map = new google.maps.Map(document.getElementById("map"),{
                zoom:15,
                center: new google.maps.LatLng(latitude, longitude),
                mapTypeId: google.maps.MapTypeId.ROADMAP
            })
            // Add marker
            new google.maps.Marker({
                position: new google.maps.LatLng(latitude, longitude),
                map: map
            })
        },
        clearAddress(){
            this.address = ''
        },
        showAC(){
            if (this.showAutoComplete == false) {
                this.showAutoComplete = true
            } else {
                this.showAutoComplete = false
            }
        },
        offAC(){
            if (this.showAutoComplete == true) {
                this.showAutoComplete = false
            } 
        },

        locatorButtonPressedUpdate(){
            this.spinner =true
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    (position) =>{ 
                        let lat = position.coords.latitude
                        let lng = position.coords.longitude
                        this.getAddressFromUpdate(lat, lng )
                        this.showUserLocationOnTheMapUpdate(lat, lng )
                        this.compLocUpdate = {
                            lat : lat,
                            lng : lng
                        }
                    },
                    error => {
                        this.errorUpdate = this.$t('company.unableFindAddress')
                        this.spinnerUpdate =false
                    }
                );
            } else {
                this.errorUpdate = error.message
                this.spinnerUpdate =false
                // console.log(`Your browser does not support geolocation API. Please enter your address`);
            }
        },
        async getAddressFromUpdate(lat, lng){
            await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${googleMapApiKey}&loading=async`)
            .then(response =>{
                if (response.data.error_message) {
                    this.errorUpdate = response.data.error_message
                } else {
                   this.compAddressUpdate = response.data.results[0].formatted_address 
                    this.addressUpdate = response.data.results[0].formatted_address
                    // this.address = ''
                }
                this.spinnerUpdate =false
            })
            .catch(error =>{
                this.errorUpdate = error.message
                this.spinnerUpdate =false
                // console.log(`error.message =>${JSON.stringify(error)}`)
            })
        },
        showUserLocationOnTheMapUpdate(latitude , longitude){
            // Creat a map object
            let map = new google.maps.Map(document.getElementById("mapUpdate"),{
                zoom:15,
                center: new google.maps.LatLng(latitude, longitude),
                mapTypeId: google.maps.MapTypeId.ROADMAP
            })
            // Add marker
            new google.maps.Marker({
                position: new google.maps.LatLng(latitude, longitude),
                map: map
            })
        },
        clearAddressUpdate(){
            this.addressUpdate = ''
        },
        showACUpdate(){
            if (this.showAutoCompleteUpdate == false) {
                this.showAutoCompleteUpdate = true
            } else {
                this.showAutoCompleteUpdate = false
            }
        },
        offACUpdate(){
            if (this.showAutoCompleteUpdate == true) {
                this.showAutoCompleteUpdate = false
            } 
        },

        handleHover(hovered) {
            this.isHovered = hovered
        },
        handleHoverUpdate(hovered) {
            this.isHoveredUpdate = hovered
        },
        processAdd(){
            this.process = 'add'
            this.currentPosition()
        },
        // processUpdate(){
        //     this.process = 'update'
        //     this.compItemPosition()
        // },
        handleSave(){
            let msg = 'completed'
            let pattern = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g
            const englishTextOnly = /^[a-zA-Z\s.,!?]*$/;
            if (this.compNameLocal ==='') {
                msg = 'company.addCompNameLocal'
            } else if (this.compNameEng ===''){
                msg = 'company.addCompNameEng'
            } else if (!englishTextOnly.test(this.compNameEng)){
                msg = 'company.onlyCompNameEng'
            } else if (this.busType ===''){
                msg = 'company.selBusType'
            } else if (this.compAddress ===''){
                msg = 'company.addCompAddress'
            } else if (this.compLoc.lat == null || this.compLoc.lat == undefined){
                msg = 'company.useGoogleMap'
            } else if (this.contactEmail ===''){
                msg = 'company.addContactEmail'
            } else if (!pattern.test(this.contactEmail)){
                msg = 'company.contactEmailFormat'
            } else if (this.contactPhone ===''){
                msg = 'company.addContactPhone'
            } else if (isNaN(parseInt(this.contactPhone))){
                msg = 'company.contactPhoneNumber'
            } else if (this.contactPhone.length < 9 ){
                msg = 'company.contactPhone9Digits'
            } else if (this.taxId ===''){
                msg = 'company.addTaxId'
            } else if (isNaN(parseInt(this.taxId))){
                msg = 'company.taxIdNumber'
            } else if (this.taxId.length != 13 ){
                msg = 'company.taxId13Digits'
            }  
            if (msg === 'completed'){
                // console.log ('msg=>'+msg)
                let compId = `${this.$moment().format("YY")}${Math.floor(Math.random() * (90000 - 10000 + 1) + 10000)}`
                let payload = {
                    compId : compId,
                    compNameLocal: this.compNameLocal,
                    compNameEng: this.compNameEng,
                    busType: this.busType,
                    compAddress: this.compAddress,
                    compLoc: this.compLoc,            
                    contactEmail: this.contactEmail,
                    contactPhone: this.contactPhone,
                    taxId:this.taxId,
                    active: true
                }
                let payloadWallet = {
                    compId : compId,
                    balanced : registCompAdv || 0 ,
                    timestamp : new Date().valueOf()
                }
               // console.log ('payload=>'+JSON.stringify(payload , null, 2))
                saveWithId('Company', compId, payload).then (async()=>{
                    this.refresh()
                    this.$toasted.show(this.$t('reuse.successSave'), {theme: 'bubble', type: 'success' , position: 'bottom-center', duration: 5000});  
                    await saveWithId('Wallet', compId, payloadWallet)
                })                             
            }else{
                this.$toasted.show(this.$t(msg), {theme: 'bubble', type: 'error' , position: 'bottom-center', duration: 7000});     
            }
        },
        handleUpdate(){
            let msg = 'completed'
            let pattern = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g
            const englishTextOnly = /^[a-zA-Z\s.,!?]*$/;
            if (this.compNameLocalUpdate ==='') {
                msg = 'company.addCompNameLocal'
            } else if (!englishTextOnly.test(this.compNameEng)){
                msg = 'company.onlyCompNameEng'
            } else if (this.busTypeUpdate ===''){
                msg = 'company.selBusType'
            } else if (this.compAddressUpdate ===''){
                msg = 'company.addCompAddress'
            } else if (this.compLocUpdate.lat == null || this.compLocUpdate.lat == undefined){
                msg = 'company.useGoogleMap'
            } else if (this.contactEmailUpdate ===''){
                msg = 'company.addContactEmail'
            } else if (!pattern.test(this.contactEmailUpdate)){
                msg = 'company.contactEmailFormat'
            } else if (this.contactPhoneUpdate ===''){
                msg = 'company.addContactPhone'
            } else if (isNaN(parseInt(this.contactPhoneUpdate))){
                msg = 'company.contactPhoneNumber'
            } else if (this.contactPhoneUpdate.length < 9 ){
                msg = 'company.contactPhone9Digits'
            } else if (this.taxIdUpdate ===''){
                msg = 'company.addTaxId'
            } else if (isNaN(parseInt(this.taxIdUpdate))){
                msg = 'company.taxIdNumber'
            } else if (this.taxIdUpdate.length != 13 ){
                msg = 'company.taxId13Digits'
            }  
            if (msg === 'completed'){
                let obj = {
                    compNameLocal: this.compNameLocalUpdate,
                    busType: this.busTypeUpdate,
                    compAddress: this.compAddressUpdate,
                    compLoc: this.compLocUpdate,            
                    contactEmail: this.contactEmailUpdate,
                    contactPhone: this.contactPhoneUpdate,
                    taxId:this.taxIdUpdate,
                }
               // console.log ('payload=>'+JSON.stringify(payload , null, 2))
                mergeObj('Company', this.selectedCompId, obj).then (()=>{
                    this.refreshUpdate()
                    this.$toasted.show(this.$t('company.successUpdate'), {theme: 'bubble', type: 'success' , position: 'bottom-center', duration: 5000});  
                })                             
            }else{
                this.$toasted.show(this.$t(msg), {theme: 'bubble', type: 'error' , position: 'bottom-center', duration: 5000});     
            }
        },

        async refresh(){
            await compListAll( (res)=>{
            this.items = res
            this.totalRows = res.length
            this.compNameLocal = '',
            this.compNameEng = '',
            this.compAddress = '',
            this.compLoc = {},            
            this.contactEmail = '',
            this.contactPhone = '',
            this.taxId ='',
            this.busType = ''
            }).then(()=>{
                this.process = 'info'
            })
        },
        async refreshUpdate(){
            await compListAll( (res)=>{
            this.items = res
            this.totalRows = res.length
            this.compNameLocalUpdate = '',
            this.compNameEngUpdate = '',
            this.compAddressUpdate = '',
            this.compLocUpdate = {},            
            this.contactEmailUpdate = '',
            this.contactPhoneUpdate = '',
            this.taxIdUpdate ='',
            this.busTypeUpdate = ''
            }).then(()=>{
                this.process = 'info'
            })
        },
        actSuspend(item) {
            this.$bvModal.msgBoxConfirm(this.$t('company.confirmSuspendMsg'), {
            title: this.$t('matchTx.plsConfirm'),
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'warning',
            okTitle: this.$t('matchTx.yes'),
            cancelTitle: this.$t('matchTx.no'),
            footerClass: 'p-2',
            hideHeaderClose: false,
            centered: true
            })
            .then(async(value) => {
                if (value) {
                    await updateActive( "Company", item.compId , false)
                     this.refresh()                    
                    this.$toasted.show( this.$t('company.suspended'), {theme: 'bubble', type: 'error' , position: 'bottom-center', duration: 5000});  
                } else {
                return
                }
            })
            .catch(error => {
                this.$toasted.show( `Action-Delete: ${error}`, {theme: 'bubble', type: 'error' , position: 'bottom-center', duration: 5000});  
            })
        },    

        actAble(item) {
            this.$bvModal.msgBoxConfirm(this.$t('company.confirmAbleMsg'), {
            title: this.$t('matchTx.plsConfirm'),
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'warning',
            okTitle: this.$t('matchTx.yes'),
            cancelTitle: this.$t('matchTx.no'),
            footerClass: 'p-2',
            hideHeaderClose: false,
            centered: true
            })
            .then(async(value) => {
                if (value) {
                    await updateActive( "Company", item.compId , true)
                     this.refresh()                    
                    this.$toasted.show( this.$t('company.abled'), {theme: 'bubble', type: 'error' , position: 'bottom-center', duration: 5000});  
                } else {
                return
                }
            })
            .catch(error => {
                this.$toasted.show( `Action-Delete: ${error}`, {theme: 'bubble', type: 'error' , position: 'bottom-center', duration: 5000});  
            })
        },    
        actUpdate(item) {
            this.compItemPosition(item.compLoc)
            this.selectedCompId = item.compId
            this.compNameLocalUpdate = item.compNameLocal,
            this.compNameEngUpdate = item.compNameEng,
            this.compAddressUpdate = item.compAddress,
            this.compLocUpdate = item.compLoc,            
            this.contactEmailUpdate = item.contactEmail,
            this.contactPhoneUpdate = item.contactPhone,
            this.taxIdUpdate =item.taxId,
            this.busTypeUpdate = item.busType,
            this.process = "update"   // to pop up Update subMenu
            console.log(`[actUpdate] selectedItem => ${JSON.stringify(item , null , 2)}`)
        }, 
    }
}
</script>
<style scoped>
.ui.button,
.dot.circle.icon{
    background-color: #138fc0;
    color : white
}
.pac-icon {
    display: none;
}
.pac-item{
    padding:0px;
    font-size: 10px;
    cursor: pointer;
}
.pac-item:hover {
    background-color: #ececec;
}
.pac-item-query {
    font-size: 10px;
}
#map {
    position:absolute;
    top:0;
    right:0;
    bottom: 0;
    left: 0;
    background: blue;
}
#mapUpdate {
    position:absolute;
    top:0;
    right:0;
    bottom: 0;
    left: 0;
    background: blue;
}
</style>
