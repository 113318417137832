<template>
	<div>
		<Breadcrumbs main="" title="require.header"/>
		<!-- Container-fluid starts-->
		<div class="container-fluid search-page">
            <div>
                <h4 class="mb-1 mt-4 f-w-600">{{ $t('require.postedRequire') }} - Admin</h4>
                <b-table
                    sticky-header
                    head-variant="light"
                    table-variant="success"
                    show-empty
                    sort-icon-left
                    stacked="md"      
                    :items="itemsPostedReqr"
                    :fields="fieldsPostedReqr"
                    :current-page="currentPagePostedReqr"
                    :per-page="perPagePostedReqr"
                >
                    <template #cell(actPostedReqr)="row" >
                        <b-button  size="sm" @click="actPostedReqrCancel(row.item)" class="m-1">
                            {{ $t('matchTx.cancel') }}
                        </b-button>
                    </template>
                    <template #empty>
                        <h5>{{ $t('reuse.noRecord') }}</h5>
                    </template>
                </b-table>
                <b-row>
                    <div class="row col-sm-8 ">
                    <b-col class="my-1 ml-3">
                        <b-pagination
                        v-model="currentPagePostedReqr"
                        :total-rows="totalRowsPostedReqr"
                        :per-page="perPagePostedReqr"
                        align="fill"
                        class="my-0"
                        ></b-pagination>
                    </b-col>   
                    <b-col sm="3" md="6" class="my-1">
                        <b-form-group
                        :label= "$t('require.perPage')"
                        label-for="per-page-select"
                        label-cols-sm="6"
                        label-cols-md="4"
                        label-cols-lg="3"
                        label-align-sm="right"
                        label-size="sm"
                        class="mb-0"
                        >
                        <b-form-select
                            id="per-page-select"
                            v-model="perPagePostedReqr"
                            :options="pageOptionsPostedReqr"
                        ></b-form-select>
                        </b-form-group>
                    </b-col>
                    </div>
                </b-row>
                <!-- Info modal Reqr Attch Seal-->
            </div>
        </div>
        <!-- Container-fluid Ends-->
    </div>
</template>
<script>

import { postedByAdmin, getStatusByDocId, updateStatus } from "../../firebase"
export default {
	data(){
		return{
            itemsPostedReqr: [],
            fieldsPostedReqr: [
                { key: 'reqrId', label: this.$t('require.reqrId'), sortable: true, },  
                { key: 'bkgId', label: this.$t('require.bkgId'), sortable: true, },                        
                { key: 'agent', label: this.$t('require.agentByBkgId'), sortable: true, }, 
                { key: 'compNameLocalReqr', label: this.$t('matchTx.compNameLocalReqr'), sortable: true, },                
                { key: 'contReqrAddress', label: this.$t('matchTx.contReqrAddress'), sortable: false, },   
                { key: 'distLim', label: this.$t('require.distLim'), sortable: true, },             
                { key: 'minDetDate', label: this.$t('require.minDetDate'), sortable: true, }, 
                { key: 'contType', label: this.$t('require.contType'), sortable: true, },
                { key: 'contSize', label: this.$t('require.contSize'), sortable: true, },
                { key: 'contGrade', label: this.$t('require.contGrade'), sortable: true, },
                { key: 'jobType', label: this.$t('require.jobType'), sortable: true, 
                    formatter: value => {
                        return this.$t(`require.${value}`)
                }},
                { key: 'timestamp', label: this.$t('shipApproval.date'), sortable: true, 
                    formatter: value => {
                        return this.$moment(value).format("YY-MM-DD @ HH:mm")
                }},
                { key: 'actPostedReqr', label: this.$t('matchTx.actions'), variant: 'danger'}
            ],
            totalRowsPostedReqr: 1,
            currentPagePostedReqr: 1,
            perPagePostedReqr: 10,
            pageOptionsPostedReqr: [5, 10, 15, { value: 100, text: "Show a lot" }],
		}
	},
	mounted(){
        postedByAdmin( "Require", "Require" , (res)=>{
            this.itemsPostedReqr = res
            this.totalRowsPostedReqr = res.length
        })
	},
	methods:{
        async refreshPostedRequire() {
            await postedByAdmin( "Require", "Require" , (res)=>{
                this.itemsPostedReqr = res
                this.totalRowsPostedReqr = res.length
                })
        },
        actPostedReqrCancel(item) {
            this.$bvModal.msgBoxConfirm(this.$t('require.confirmCancelMsg'), {
            title: this.$t('matchTx.plsConfirm'),
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'warning',
            okTitle: this.$t('matchTx.yes'),
            cancelTitle: this.$t('matchTx.no'),
            footerClass: 'p-2',
            hideHeaderClose: false,
            centered: true
            })
            .then(value => {
                if (value) {
                    getStatusByDocId("Require", item.reqrId, (res)=>{
                        if (res == "Matched") {
                            this.$toasted.show( this.$t("require.postedMatched"), {theme: 'bubble', type: 'info' , position: 'bottom-center', duration: 10000});  
                        } else {
                            updateStatus('Require', item.reqrId, 'Cancel')
                            this.$toasted.show( this.$t("require.postedCancelled"), {theme: 'bubble', type: 'error' , position: 'bottom-center', duration: 10000});  
                            this.refreshPostedRequire()
                        }
                    })
                } else {
                    return
                }
            })
            .catch(error => {
                this.$toasted.show( `Action-Cancel: ${error}`, {theme: 'bubble', type: 'error' , position: 'bottom-center', duration: 10000});  
            })
        }, 
    }
}
</script>
<style>
.ui.button,
.dot.circle.icon{
    background-color: #138fc0;
    color : white
}
.pac-icon {
    display: none;
}
.pac-item{
    padding:0px;
    font-size: 10px;
    cursor: pointer;
}
.pac-item:hover {
    background-color: #ececec;
}
.pac-item-query {
    font-size: 10px;
}
#map {
    position:absolute;
    top:0;
    right:0;
    bottom: 0;
    left: 0;
    background: blue;
}

.style-chooser .vs__search::placeholder,
.style-chooser .vs__dropdown-toggle,
.style-chooser .vs__dropdown-menu {
  background: #dfe5fb;
  border: none;
  color: #394066;
  /* text-transform: lowercase;
  font-variant: small-caps; */
}

.style-chooser .vs__clear,
.style-chooser .vs__open-indicator {
  fill: #394066;
}


</style>
