<template>
	<div>
		<Breadcrumbs main="" title="reuse.header"/>
		<!-- Container-fluid starts-->
		<div class="container-fluid search-page">
            <div class="row ml-1">
                <div class="ml-3" > 
                    <span for="selectedCompName" class=" text-primary pt-0 f-w-600" style="font-size: 20px">{{ $t("register.compId") }}</span>          
                </div>
                <div class="col-sm-2 ml-3">
                    <v-select label="selectedCompName" v-model="selectedCompName" :options="compNameListLogist" class=" text-primary pt-0 f-w-600" style="font-size: 20px;">
                    </v-select> 
                </div>    
                <div class=" ml-3">
                    <button @click="reuseComp" class="btn btn-primary btn-lg active" type="button"><i class="fa fa-star"></i> Run</button>
                </div>     
            </div>
            <div>
                <h4 class="mb-1 mt-4 f-w-600">{{ $t('reuse.activeReuseList') }} - Company</h4>
                <b-table
                    sticky-header
                    head-variant="light"
                    table-variant="success"
                    show-empty
                    sort-icon-left
                    stacked="md"      
                    :items="items"
                    :fields="fields"
                    :current-page="currentPage"
                    :per-page="perPage"
                >
                    <template #cell(act)="row" >
                        <b-button  size="sm" @click="actCancel(row.item)" class="m-1">
                            {{ $t('matchTx.cancel') }}
                        </b-button>
                    </template>
                    <template #cell(contImage)="row" >
                        <b-button  size="sm" @click="openModalImage(row.item, row.index, $event.target )" class="m-1">
                            {{ row.item.contNo }}
                        </b-button>
                    </template>
                    <template #empty>
                        <h5>{{ $t('reuse.noRecord') }}</h5>
                    </template>
                </b-table>
                <b-row>
                    <div class="row col-sm-8 ">
                    <b-col class="my-1 ml-3">
                        <b-pagination
                        v-model="currentPage"
                        :total-rows="totalRows"
                        :per-page="perPage"
                        align="fill"
                        class="my-0"
                        ></b-pagination>
                    </b-col>   
                    <b-col sm="3" md="6" class="my-1">
                        <b-form-group
                        :label= "$t('require.perPage')"
                        label-for="per-page-select"
                        label-cols-sm="6"
                        label-cols-md="4"
                        label-cols-lg="3"
                        label-align-sm="right"
                        label-size="sm"
                        class="mb-0"
                        >
                        <b-form-select
                            id="per-page-select"
                            v-model="perPage"
                            :options="pageOptions"
                        ></b-form-select>
                        </b-form-group>
                    </b-col>
                    </div>
                </b-row>
                    <!-- Info modal Container Image-->
                <b-modal id="contModal" :title="modalTitle" ok-only @hide="resetInfoModal">
                    <div class="card me-5" style=" width:15rem">
                        <img  class="card-img-top" :src="imageUriModal" alt="Image Preview"/>
                    </div>
                </b-modal>
            </div>
        </div>
        <!-- Container-fluid Ends-->
    </div>
</template>
<script>

import { updateActive, updateStatus, updateMatchTx2StepN, mTxIdReuByReuId , postedByCompId, updateContActivebyContNo, compNameListLogist, compIdByCompName} from "../../firebase"
export default {
	data(){
		return{
            compNameListLogist : [],
            selectedCompName : '',
            selectedCompId : '',
            items: [],
            fields: [
                { key: 'reuId', label: this.$t('require.reqrId'), sortable: true, },  
                { key: 'bkgId', label: this.$t('require.bkgId'), sortable: true, }, 
                { key: 'agent', label: this.$t('require.agentByBkgId'), sortable: true, },                    
                { key: 'contImage', label: this.$t('container.contNo'), variant: 'info'}, 
                { key: 'detDate', label: this.$t('container.detDate'), sortable: true, },                 
                { key: 'sealNo', label: this.$t('seal.sealNo'), sortable: true,},
                { key: 'contType', label: this.$t('require.contType'), sortable: true, },
                { key: 'contSize', label: this.$t('require.contSize'), sortable: true, },
                { key: 'contGrade', label: this.$t('require.contGrade'), sortable: true, },
                { key: 'compNameLocalReu', label: this.$t('reuse.compNameLocalReu'), sortable: true, },                
                { key: 'contReuAddress', label: this.$t('matchTx.contReqrAddress'), sortable: false, },              
                { key: 'timestamp', label: this.$t('shipApproval.date'), sortable: true, 
                    formatter: value => {
                        return this.$moment(value).format("YY-MM-DD @ HH:mm")
                }},
                { key: 'act', label: this.$t('matchTx.actions'), variant: 'danger'}
            ],
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [5, 10, 15, { value: 100, text: "Show a lot" }],
            imageUriModal: '',
            modalTitle : ''
		}
	},
	mounted(){
        compNameListLogist((res)=>{
            this.compNameListLogist = res
        })
	},
	methods:{
        async reuseComp(){
            if (this.selectedCompName) {
                await compIdByCompName(this.selectedCompName , async(selCompId)=>{
                    this.selectedCompId = selCompId
                    postedByCompId( "Reuse",selCompId, "matched" , (res)=>{
                        if (res.length == 0) {
                            this.$toasted.show( this.$t('reuse.noData'), {theme: 'bubble', type: 'error' , position: 'bottom-center', duration: 5000});  
                        } else {
                            this.items= res
                            this.totalRows = res.length
                        }
                    })
                })          
            }
        },
        actCancel(item) {
            this.$bvModal.msgBoxConfirm(this.$t('matchTx.confirmCancelMsg'), {
                title: this.$t('matchTx.plsConfirm'),
                size: 'sm',
                buttonSize: 'sm',
                okVariant: 'warning',
                okTitle: this.$t('matchTx.yes'),
                cancelTitle: this.$t('matchTx.no'),
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
            })
            .then(async (value) => {
                if (value) {
                    await mTxIdReuByReuId( item.reuId, async(res)=>{    // reuId = reqrId for Reuse
                        if (res.length == 0) {
                            this.$toasted.show( this.$t('reuse.noData'), {theme: 'bubble', type: 'error' , position: 'bottom-center', duration: 5000});  
                        } else {
                            if (res.msReqr == "approved" || res.msRels == "approved" ) {
                                this.$toasted.show( this.$t('reuse.approved'), {theme: 'bubble', type: 'error' , position: 'bottom-center', duration: 5000});  
                            } else {
                                await updateMatchTx2StepN('MatchTx', res.mTxId , 1.1) 
                                await updateStatus('Reuse', item.reuId, 'Cancel')// return  status of Poster for recycle.
                                await updateContActivebyContNo( item.contNo , true )
                                await updateActive('Seal', item.sealNo, true )
                                this.$toasted.show( this.$t('reuse.cancelled'), {theme: 'bubble', type: 'error' , position: 'bottom-center', duration: 5000});                                        
                                await this.refresh().then(()=>{
                                    // window.location.reload()
                                })    
                            }
                        }       
                    }) 
                } else {
                return
                }
            })
            .catch(error => {
                this.$toasted.show( `Action-Delete: ${error}`, {theme: 'bubble', type: 'error' , position: 'bottom-center', duration: 5000});  
            })
        },  
        async refresh(){
            await postedByCompId( "Reuse",this.selectedCompId, "matched" , (res)=>{
                this.items= res
                this.totalRows = res.length
            })
        },

        openModalImage(item, index, button) {
            this.imageUriModal = item.imageUri
            this.modalTitle = item.contNo
            this.$root.$emit('bv::show::modal', "contModal" , button)
        },
        resetInfoModal() {
            this.modalTitle = ''
            this.imageUriModal = ''
        },
    }
}
</script>

