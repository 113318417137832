<template>
  <div>
    <Breadcrumbs main="matchTx.dashboard" title="matchTx.header" />
    <b-container fluid>
      <!-- <h3 class="mb-1 mt-4 f-w-600">{{ $t('matchTx.header') }}</h3> -->
      <div class="row">
        <div class="ml-3" > 
          <span for="selectedCompName" class=" text-primary pt-0 f-w-600" style="font-size: 20px">{{ $t("register.compId") }}</span>          
        </div>
        <div class="col-sm-4 ml-3">
          <v-select label="selectedCompName" v-model="selectedCompName" :options="compNameListLogist" class=" text-primary pt-0 f-w-600" style="font-size: 20px;">
          </v-select>     
        </div>
        <div class=" ml-3">
          <button @click="matchTxAdmin" class="btn btn-primary btn-lg active" type="button"><i class="fa fa-star"></i> Run</button>
        </div>        
      </div>
      <div>
      <h4 class="mb-1 mt-4 f-w-600">{{ $t('matchTx.reqrReceiver') }}</h4>
      <b-table
        sticky-header
        head-variant="light"
        table-variant="info"
        show-empty
        sort-icon-left
        stacked="md"
        :sort-by.sync="sortByReqr"
        :sort-desc.sync="sortDescReqr"
        :items="itemsReqr"
        :fields="fieldsReqr"
        :current-page="currentPageReqr"
        :per-page="perPageReqr"
      >
        <template #cell(contImageReqr)="row" >
          <b-button  size="sm" @click="openModalImageReqr(row.item, row.index, $event.target )" class="m-1">
              {{ row.item.contNo }}
          </b-button>
        </template>
        <template #cell(actReqr)="row" >
          <b-button  v-show="row.item.actReqr.cancel" size="sm" variant="danger" @click="actReqrCancel(row.item)" class="m-1 ">
            {{ $t('matchTx.cancel') }}
          </b-button>
          <b-button  v-show="row.item.actReqr.accept" size="sm" variant="success" @click="actReqrAccept(row.item)" class="m-1">
            {{ $t('matchTx.accept') }}
          </b-button>
          <b-button  v-show="row.item.actReqr.reject" size="sm" variant="danger" @click="actReqrReject(row.item)" class="m-1">
            {{ $t('matchTx.reject') }}
          </b-button>
          <b-button  v-show="row.item.actReqr.attachSeal" size="sm" variant="warning" @click="actReqrAttachSeal(row.item)" class="m-1">
            {{ $t('matchTx.attachSeal') }}
          </b-button>
          <b-button  v-show="row.item.actReqr.terminate" size="sm" variant="danger" @click="actReqrTerminate(row.item)" class="m-1">
            {{ $t('matchTx.terminate') }}
          </b-button>
          <b-button  v-show="row.item.actReqr.transfer" size="sm" variant="warning" @click="actReqrTransfer(row.item)" class="m-1">
            {{ $t('matchTx.transfer') }}
          </b-button>
          <b-button v-show="row.item.jbReqr == 'shipsRej'" size="md" variant="warning" @click="openModalViewShipsRejectReqr(row.item, row.index, $event.target )" class="m-1">
              {{ $t('matchTx.viewShipsReject') }}
            </b-button>
        </template>
        <template #empty>
            <h5>{{ $t('reuse.noRecord') }}</h5>
        </template>
      </b-table>
      <b-row>
        <div class="row col-sm-8 ">
          <b-col class="my-1 ml-3">
            <b-pagination
              v-model="currentPageReqr"
              :total-rows="totalRowsReqr"
              :per-page="perPageReqr"
              align="fill"
              class="my-0"
            ></b-pagination>
          </b-col>   
          <b-col sm="3" md="6" class="my-1">
            <b-form-group
              :label= "$t('require.perPage')"
              label-for="per-page-select"
              label-cols-sm="6"
              label-cols-md="4"
              label-cols-lg="3"
              label-align-sm="right"
              label-size="sm"
              class="mb-0"
            >
              <b-form-select
                id="per-page-select"
                v-model="perPageReqr"
                :options="pageOptionsReqr"
              ></b-form-select>
            </b-form-group>
          </b-col>
        </div>
      </b-row>
      <!-- Info modal Reqr Attch Seal-->
      <div>
        <!-- <b-button @click="showSealNoModal=true" variant="primary">Show Modal</b-button> -->
        <b-modal
          :id="actReqrAttachSealModal.id" 
          :title="actReqrAttachSealModal.title"
          v-model="showSealNoModal"
          header-bg-variant="info"
          header-text-variant="dark"
          body-bg-variant="light"
          body-text-variant="dark"
          hide-footer
        >
          <b-row class="mb-1">
            <b-col cols="4">{{ $t('matchTx.selSealNo') }}</b-col>
            <b-col>
              <b-form-select
                v-model="selectedSealNo"
                :options="sealNos"
              ></b-form-select>
            </b-col>
          </b-row>
          <div class="w-100">
            <span class="float-left">{{$t('matchTx.sealNo')}} : </span> <span> {{ selectedSealNo }}</span> 
            <b-button
              variant="primary"
              size="sm"
              class="float-right"
              @click="clickAttachSeal_OK"
            >
              OK
            </b-button>
          </div>
        </b-modal>
        <b-modal
          :id="actReqrTransferModal.id" 
          :title="actReqrTransferModal.title"
          v-model="showTransferModal"
          header-bg-variant="info"
          header-text-variant="dark"
          body-bg-variant="light"
          body-text-variant="dark"
          hide-footer
        >
          <b-row class="mb-1">
            <b-col cols="4">{{ $t('matchTx.selBkgId') }}</b-col>
            <b-col>
              <b-form-select
                v-model="selectedBkgId"
                :options="bkgIds"
              ></b-form-select>
            </b-col>
          </b-row>
          <div class="w-100">
            <span class="float-left">{{$t('matchTx.bkgId')}} : </span> <span> {{ selectedBkgId }}</span> 
            <b-button
              variant="primary"
              size="sm"
              class="float-right"
              @click="clickBkgId_OK"
            >
              OK
            </b-button>
          </div>
        </b-modal>
          <!-- Info modal Container Image Reqr-->
        <b-modal id="contModalReqr" :title="modalTitleReqr" ok-only @hide="resetInfoModalReqr">
          <div class="card me-5" style=" width:15rem">
              <img  class="card-img-top" :src="imageUriModalReqr" alt="Image Preview - Require"/>
          </div>
        </b-modal>
          <!-- Info modal Container Image Rels-->
        <b-modal id="contModalRels" :title="modalTitleRels" ok-only @hide="resetInfoModalRels">
          <div class="card me-5" style=" width:15rem">
              <img  class="card-img-top" :src="imageUriModalRels" alt="Image Preview - Release"/>
          </div>
        </b-modal>
        <b-modal id="viewShipsRejectModalReqr" :title="$t('matchTx.viewShipsRejectTitle')" ok-only @hide="resetInfoModalShipsRejectReqr">
          <div class="card me-5" style=" width:30rem">
              <h6 class="card me-5"> {{ shipsRejectRemarkReqr }}</h6>
          </div>
        </b-modal>
        <b-modal id="viewShipsRejectModalRels" :title="$t('matchTx.viewShipsRejectTitle')" ok-only @hide="resetInfoModalShipsRejectRels">
          <div class="card me-5" style=" width:30rem">
              <h6 class="card me-5"> {{ shipsRejectRemarkRels   }}</h6>
          </div>
        </b-modal>
      </div>
    </div>
    <div class="divider"> </div>
    <div>
      <h4 class="mb-1 mt-4 f-w-600">{{ $t('matchTx.relsProposer') }}</h4>
      <b-table
        sticky-header
        head-variant="light"
        table-variant="success"
        show-empty
        sort-icon-left
        stacked="md"
        :sort-by.sync="sortByRels"
        :sort-desc.sync="sortDescRels"
        :items="itemsRels"
        :fields="fieldsRels"
        :current-page="currentPageRels"
        :per-page="perPageRels"
      >
        <template #cell(contImageRels)="row" >
          <b-button  size="sm" @click="openModalImageRels(row.item, row.index, $event.target )" class="m-1">
              {{ row.item.contNo }}
          </b-button>
        </template>
        <template #cell(actRels)="row" >
          <b-button  v-show="row.item.actRels.cancel" size="sm" @click="actReqrCancel(row.item)" class="m-1 ">
            {{ $t('matchTx.cancel') }}
          </b-button>
          <b-button  v-show="row.item.actRels.accept" size="sm" @click="actReqrAccept(row.item)" class="m-1">
            {{ $t('matchTx.accept') }}
          </b-button>
          <b-button  v-show="row.item.actRels.reject" size="sm" @click="actReqrReject(row.item)" class="m-1">
            {{ $t('matchTx.reject') }}
          </b-button>
          <b-button  v-show="row.item.jbRels == 'shipsRej'" size="md" variant="warning" @click="openModalViewShipsRejectRels(row.item, row.index, $event.target )" class="m-1">
              {{ $t('matchTx.viewShipsReject') }}
          </b-button>
        </template>
        <template #empty>
            <h5>{{ $t('reuse.noRecord') }}</h5>
        </template>
      </b-table>
      <b-row>
        <div class="row col-sm-8 ">
          <b-col class="my-1 ml-3">
            <b-pagination
              v-model="currentPageRels"
              :total-rows="totalRowsRels"
              :per-page="perPageRels"
              align="fill"
              class="my-0"
            ></b-pagination>
          </b-col>   
          <b-col sm="3" md="6" class="my-1">
            <b-form-group
              :label="$t('require.perPage')"
              label-for="per-page-select"
              label-cols-sm="6"
              label-cols-md="4"
              label-cols-lg="3"
              label-align-sm="right"
              label-size="sm"
              class="mb-0 "
            > 
              <b-form-select
                id="per-page-select"
                v-model="perPageRels"
                :options="pageOptionsRels"
              ></b-form-select>
            </b-form-group>
          </b-col>          
        </div>
      </b-row>
    </div>
  </b-container>
  </div>
</template>
<script>
import {shipReviewRemark, matchTxsRels,bkgIdListAgentGroup, makeMatchTxTrStep2, matchTxsReqr,compNameListLogist,compIdByCompName ,updateStatus, updateActive, updateContActivebyContNo, sealNoListAgentGroup, updateMatchTx2StepN} from "../../firebase"
  export default {
    data() {
      return {
        compNameListLogist : [],
        selectedCompName : '',
        selectedCompId : '',
        // compId : '',
        itemsReqr: [],
        itemsRels: [],
        showSealNoModal: false,
        sealNos: [],
        selectedSealNo: '',
        currentSealNo: '',
        showTransferModal: false,
        bkgIds: [],
        selectedBkgId: '',
        selected_mTxId : '',
        fieldsReqr: [
          { key: 'mTxId', label: this.$t('matchTx.mTxId'), sortable: true, },  
          { key: 'bkgId', label: this.$t('require.bkgId'), sortable: true, },     
          { key: 'contImageReqr', label: this.$t('matchTx.contNo'), sortable: true, variant: 'info'},  
          // { key: 'contNo', label: this.$t('matchTx.contNo'), sortable: true, },          
          { key: 'detDate', label: this.$t('matchTx.detDate'), sortable: true, }, 
          { key: 'contType', label: this.$t('matchTx.contType'), sortable: true, },
          { key: 'contSize', label: this.$t('matchTx.contSize'), sortable: true, },
          { key: 'contGrade', label: this.$t('matchTx.contGrade'), sortable: true, },
          { key: 'maxGross', label: this.$t('container.maxGross'), sortable: true, },
          { key: 'tare', label: this.$t('container.tare'), sortable: true, },
          { key: 'agent', label: this.$t('matchTx.agent'), sortable: true, },                
          { key: 'compNameLocalRels', label: this.$t('matchTx.compNameLocalRels'), sortable: true, },                
          { key: 'contRelsAddress', label: this.$t('matchTx.contRelsAddress'), sortable: false, },
          { key: 'jobTypeRels', label: this.$t('matchTx.jobTypeRels'), sortable: true, 
              formatter: value => {
                return this.$t(`require.${value}`)
          }}, 
          { key: 'distance', label: this.$t('matchTx.distance'), sortable: true, },                
          { key: 'compNameLocalReqr', label: this.$t('matchTx.compNameLocalReqr'), sortable: true, },                
          { key: 'contReqrAddress', label: this.$t('matchTx.contReqrAddress'), sortable: false, },
          { key: 'jobTypeReqr', label: this.$t('matchTx.jobTypeReqr'), sortable: true, 
              formatter: value => {
                return this.$t(`require.${value}`)
          }},           
          { key: 'timestamp', label: this.$t('shipApproval.date'), sortable: true, 
                    formatter: value => {
                    return this.$moment(value).format("YY-MM-DD @ HH:mm")
          }},
          { key: 'msReqr', label: this.$t('matchTx.matchStatus'), sortable: false, variant: 'warning',
              formatter: value => {
                 return this.$t(`matchTx.${value}`)
              }}, 
          { key: 'jbReqr', label: this.$t('matchTx.jobStatus'), sortable: true, variant: 'warning',
              formatter: value => {
                return this.$t(`matchTx.${value}`)
              }}, 
          { key: 'actReqr', label: this.$t('matchTx.actions'), variant: 'warning' }
        ],
        fieldsRels: [
          { key: 'mTxId', label: this.$t('matchTx.mTxId'), sortable: true, },  
          { key: 'bkgId', label: this.$t('require.bkgId'), sortable: true, },     
          { key: 'contImageRels', label: this.$t('matchTx.contNo'),sortable: true, variant: 'info'},  
          // { key: 'contNo', label: this.$t('matchTx.contNo'), sortable: true, },          
          { key: 'detDate', label: this.$t('matchTx.detDate'), sortable: true, }, 
          { key: 'contType', label: this.$t('matchTx.contType'), sortable: true, },
          { key: 'contSize', label: this.$t('matchTx.contSize'), sortable: true, },
          { key: 'contGrade', label: this.$t('matchTx.contGrade'), sortable: true, },
          { key: 'maxGross', label: this.$t('container.maxGross'), sortable: true, },
          { key: 'tare', label: this.$t('container.tare'), sortable: true, },
          { key: 'agent', label: this.$t('matchTx.agent'), sortable: true, },                
          { key: 'compNameLocalRels', label: this.$t('matchTx.compNameLocalRels'), sortable: true, },                
          { key: 'contRelsAddress', label: this.$t('matchTx.contRelsAddress'), sortable: false, },
          { key: 'jobTypeRels', label: this.$t('matchTx.jobTypeRels'), sortable: true, 
            formatter: value => {
              return this.$t(`require.${value}`)
            }},   
          { key: 'distance', label: this.$t('matchTx.distance'), sortable: true, },                   
          { key: 'compNameLocalReqr', label: this.$t('matchTx.compNameLocalReqr'), sortable: true, },                
          { key: 'contReqrAddress', label: this.$t('matchTx.contReqrAddress'), sortable: false, },
          { key: 'jobTypeReqr', label: this.$t('matchTx.jobTypeReqr'), sortable: true, 
            formatter: value => {
              return this.$t(`require.${value}`)
          }},           
          { key: 'timestamp', label: this.$t('shipApproval.date'), sortable: true, 
            formatter: value => {
              return this.$moment(value).format("YY-MM-DD @ HH:mm")
          }},
          { key: 'msRels', label: this.$t('matchTx.matchStatus'), sortable: false,variant: 'warning',
              formatter: value => {
                return this.$t(`matchTx.${value}`)
              }}, 
          { key: 'jbRels', label: this.$t('matchTx.jobStatus'), sortable: true, variant: 'warning',
              formatter: value => {
                return this.$t(`matchTx.${value}`)
              }}, 
          { key: 'actRels', label: this.$t('matchTx.actions'), variant: 'warning'}
        ],
        totalRowsReqr: 1,
        currentPageReqr: 1,
        perPageReqr: 10,
        pageOptionsReqr: [5, 10, 15, { value: 100, text: "Show a lot" }],
        sortByReqr: 'timestamp',
        sortDescReqr: true,
        actReqrAttachSealModal: {
          id: 'reqrAttachSeal-modal',
          title: this.$t('matchTx.attachSeal'),
        },
        actReqrTransferModal: {
          id: 'reqrTransfer-modal',
          title: this.$t('matchTx.transfer'),
        },
        totalRowsRels: 1,
        currentPageRels: 1,
        perPageRels: 10,
        pageOptionsRels: [5, 10, 15, { value: 100, text: "Show a lot" }],
        sortByRels: 'timestamp',
        sortDescRels: true,
        imageUriModalReqr: '',
        modalTitleReqr : '',
        imageUriModalRels: '',
        modalTitleRels : '',
        shipsRejectRemarkRels : '',
        shipsRejectRemarkReqr : ''
      }
    },
    computed: {
    },
    mounted() {
      compNameListLogist((res)=>{
            this.compNameListLogist = res
        })
    },
    methods: {
      async matchTxAdmin(){
        if (this.selectedCompName) {
          await compIdByCompName(this.selectedCompName , async(selCompId)=>{
            this.selectedCompId = selCompId
            matchTxsReqr(selCompId , (res)=>{
              this.itemsReqr = res
              this.totalRowsReqr = res.length
              // console.log(`[matchTxReqr => ${JSON.stringify(res , null , 2)}]`)
            })
            matchTxsRels(selCompId , (res)=>{
              this.itemsRels = res
              this.totalRowsRels = res.length
              // console.log(`[matchTxRels => ${JSON.stringify(res , null , 2)}]`)
            })          
          })          
        }
      },
      async refreshMatchTx() {
        this.selectedSealNo = ""
        this.selected_mTxId = ""
        await matchTxsReqr(this.selectedCompId , (res)=>{
          this.itemsReqr = res
          this.totalRowsReqr = res.length
        })
         await matchTxsRels(this.selectedCompId , (res)=>{
          this.itemsRels = res
          this.totalRowsRels = res.length
        })
      },
      async actReqrCancel(item) {
        this.$bvModal.msgBoxConfirm(this.$t('matchTx.confirmCancelMsg'), {
          title: this.$t('matchTx.plsConfirm'),
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: this.$t('matchTx.yes'),
          cancelTitle: this.$t('matchTx.no'),
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true
        })
          .then(async(value) => {
            if (value) {
              // updateStatus('Release', item.relsId, 'Release')// reverse status to "Release"
              item.txSearcher == "Release" ? updateStatus('Require', item.reqrId, 'Require') : updateStatus('Release', item.relsId, 'Release')// return  status of Poster for recycle.
              item.txSearcher == "Release" ? updateContActivebyContNo( item.contNo , true ): null
              await updateMatchTx2StepN('MatchTx',item.mTxId , 1.1).then(async()=>{
                await this.refreshMatchTx()
              })
            } else {
              return
            }
          })
          .catch(error => {
            this.$toasted.show( `Action-Cancel : ${error}`, {theme: 'bubble', type: 'error' , position: 'bottom-center', duration: 10000});  
          })
      },
      actReqrReject(item) {
        this.$bvModal.msgBoxConfirm(this.$t('matchTx.confirmRejectMsg'), {
          title: this.$t('matchTx.plsConfirm'),
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'warning',
          okTitle: this.$t('matchTx.yes'),
          cancelTitle: this.$t('matchTx.no'),
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true
        })
          .then(async(value) => {
            if (value) {
              // updateStatus('Release', item.relsId, 'Release')// reverse status to "Release"
              item.txSearcher == "Release" ? updateStatus('Require', item.reqrId, 'Require') : updateStatus('Release', item.relsId, 'Release')// return  status of Poster for recycle.
              item.txSearcher == "Release" ? updateContActivebyContNo( item.contNo , true ): null
              await updateMatchTx2StepN( 'MatchTx',item.mTxId , 1.2).then(async()=>{
                await this.refreshMatchTx()
              })
            } else {
              return
            }
          })
          .catch(error => {
            this.$toasted.show( `Action-Reject-Require : ${error}`, {theme: 'bubble', type: 'error' , position: 'bottom-center', duration: 10000});  
          })
      },      
      actReqrAccept(item) {
        this.$bvModal.msgBoxConfirm(this.$t('matchTx.confirmAcceptMsg'), {
          title: this.$t('matchTx.plsConfirm'),
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'success',
          okTitle: this.$t('matchTx.yes'),
          cancelTitle: this.$t('matchTx.no'),
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true
        })
          .then(async (value) => {
            if (value) {
              await updateMatchTx2StepN('MatchTx',item.mTxId , 2).then(async()=>{
                await this.refreshMatchTx()
              })
            } else {
              return
            }
          })
          .catch(error => {
            this.$toasted.show( `Action-Accept-Require : ${error}`, {theme: 'bubble', type: 'error' , position: 'bottom-center', duration: 10000});  
          })
      },
      async actReqrAttachSeal(item) {
        console.log(`[actReqrAttachSeal], item =>  ${JSON.stringify(item, null, 2)}`)
        this.selected_mTxId = item.mTxId
        await sealNoListAgentGroup(this.selectedCompId, item.agent, (res)=>{
          this.sealNos = res
          this.showSealNoModal=true
        })
      },
      async clickAttachSeal_OK(){
        if (!this.selectedSealNo ) {
          this.$toasted.show( this.$t('matchTx.noSeal'), {theme: 'bubble', type: 'error' , position: 'bottom-center', duration: 10000});  
        } else {
          await updateMatchTx2StepN('MatchTx', this.selected_mTxId , 4 , this.selectedSealNo)
          .then(async()=>{
            await updateActive('Seal' , this.selectedSealNo , false)
            this.showSealNoModal= false
            this.refreshMatchTx()
          })
        }
      },
      actReqrTerminate(item){
        this.$bvModal.msgBoxConfirm(this.$t('matchTx.confirmTerminateMsg'), {
          title: this.$t('matchTx.plsConfirm'),
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: this.$t('matchTx.yes'),
          cancelTitle: this.$t('matchTx.no'),
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true
        })
          .then(async(value) => {
            if (value) {
              if (item.txSearcher == "Reuse") {
                await updateMatchTx2StepN('MatchTx',item.mTxId , 4.2).then(async()=>{
                  let slipId = `${this.$moment().format("YYMMDDHH")}-${getRandomInt(10000, 90000)}`
                  updateStatus('Reuse', item.reqrId, 'Terminated')
                  updateContActivebyContNo( item.contNo, true )
                  item.sealNo ? await updateActive('Seal' , item.sealNo , true) : null
                  await this.refreshMatchTx()
                })
              } else {
                let nowTS = new Date().valueOf()
                let earlyDetTS = item.detTS - nowTS
                if (earlyDetTS < 259200000 ) {    // 3 days early
                  this.$toasted.show( `${this.$t('matchTx.early3DaysTerminateMsg')}`, {theme: 'bubble', type: 'error' , position: 'bottom-center', duration: 10000});  
                } else {
                  item.txSearcher == "Require" ? updateStatus('Release', item.relsId, 'Release') : null
                  // no refund to Reqr (should be receiver but terminate )
                  await updateMatchTx2StepN('MatchTx',item.mTxId , 4.2).then(async()=>{
                    item.sealNo ? await updateActive('Seal' , item.sealNo , true) : null
                    await this.refreshMatchTx()
                  })
                }
              }
              // await updateMatchTx2StepN('MatchTx',item.mTxId , 4.2).then(async()=>{
              //   await updateContActivebyContNo( item.contNo , true )
              //   item.sealNo ? await updateActive('Seal' , item.sealNo , true) : null
              //   await this.refreshMatchTx()
              // })
            } else {
              return
            }
          })
          .catch(error => {
            this.$toasted.show( `Action-Terminate : ${error}`, {theme: 'bubble', type: 'error' , position: 'bottom-center', duration: 10000});  
          })
      },

      async actReqrTransfer(item) {
        // console.log(`[actReqrTransfer], item =>  ${JSON.stringify(item, null, 2)}`)
        this.selected_mTxId = item.mTxId
        await bkgIdListAgentGroup(this.selectedCompId, item.agent, item.bkgId,(res)=>{
          this.bkgIds = res
          this.showTransferModal=true
        })
      },
      async clickBkgId_OK(){
        if (!this.selectedBkgId ) {
          this.$toasted.show( this.$t('matchTx.noBkgId'), {theme: 'bubble', type: 'error' , position: 'bottom-center', duration: 10000});  
        } else {
          await makeMatchTxTrStep2(this.selected_mTxId , this.selectedBkgId).then(async()=>{   // make new MatchTx - Transfer for ship approval 
            await updateMatchTx2StepN('MatchTx', this.selected_mTxId , 4.1 , this.selectedBkgId)  // step up current MatchTx to step 4.1 as status: cancel , jbreqr: transfer
            this.showTransferModal= false
            this.refreshMatchTx()    
          })
        }
      },
      openModalImageReqr(item, index, button) {
            this.imageUriModalReqr = item.imageUri
            this.modalTitleReqr = item.contNo
            this.$root.$emit('bv::show::modal', "contModalReqr" , button)
        },
      resetInfoModalReqr() {
        this.modalTitleReqr = ''
        this.imageUriModalReqr = ''
      },
      openModalImageRels(item, index, button) {
        this.imageUriModalRels = item.imageUri
        this.modalTitleRels = item.contNo
        this.$root.$emit('bv::show::modal', "contModalRels" , button)
      },
      resetInfoModalRels() {
        this.modalTitleRels = ''
        this.imageUriModalRels = ''
      },
      async openModalViewShipsRejectRels(item, index, button) {
        await shipReviewRemark(item.mTxId , (res)=>{
          this.shipsRejectRemarkRels = res
        })  
        this.$root.$emit('bv::show::modal', "viewShipsRejectModalRels" , button)
        },

      resetInfoModalShipsRejectRels() {
        this.shipsRejectRemarkRels = ''
      },

      async openModalViewShipsRejectReqr(item, index, button) {
        // console.log(`openModalViewShipsRejectReqr => ${JSON.stringify(item , null , 2)}`)
        await shipReviewRemark(item.mTxId , (res)=>{
          this.shipsRejectRemarkReqr = res
        })  
        this.$root.$emit('bv::show::modal', "viewShipsRejectModalReqr" , button)
      },

      resetInfoModalShipsRejectReqr() {
        this.shipsRejectRemarkReqr = ''
      },
    }
  }
</script>
<style scoped>
.divider {
  height: 1px;
  background-color: #0f0f0f;
  margin:5px
}
</style>