
export const defaultLocale = "th";
export const currency = "THB";
export const localeOptions=[
    {id:'th',name:'Thai'},    
    {id:'en',name:'English'}
];
export const registCompAdv = 5000
export const relsCost = 450
export const reqrCost = 450
export const reuCost = 900
export const transferCost = 100

