<template>
	<div>
		<Breadcrumbs main="" title="require.header"/>
		<!-- Container-fluid starts-->
		<div class="container-fluid search-page">
			<div class="row">
                <div class="col-sm-6">
                    <form class="theme-form">
                        <div class="row ml-1">
                            <div class="col-sm-6">
                                <label for="selectedBkgId" class="col-form-label pt-0 f-w-600" style="font-size:16px;">{{ $t("require.bkgId") }}</label>  
                                <v-select label="selectedBkgId" class="style-chooser" style="font-size:16px;" v-model="selectedBkgId" :options="bkgIdList" :placeholder="$t('require.bkgIdPh')">                                        
                                    <template #no-options>
                                        {{$t('reuse.noMatchingOptions')}}
                                    </template>
                                </v-select>
                            </div>
                            <div class="col-sm-6 form-group">
                                <label for="distLim" class="col-form-label pt-0 f-w-600" style="font-size:16px;">{{ $t("require.distLim") }}</label>
                                <input v-model="distLim" class="form-control" style="font-size:16px;" type="text" :placeholder="$t('require.distLimPh')" >
                            </div>   
                        </div>   
                        <div class="form-group ml-3 text-primary f-w-600">
                            <div class="row">
                                <label for="contReqrAddress" class="col-form-label pt-0 ml-3">{{ $t("require.locReqr") }}</label>    
                                <p class=" ml-2 fw-bold text-danger"  > {{ $t("require.ifChg") }}</p>
                                <a href="#googleMapReqr"><u class=" ml-2 fw-bold text-info" style="font-size:15px" @click="showAC"> <i class="icofont icofont-map-search mr-2 " style="font-size:25px"></i>Google Map </u></a>                                
                            </div>
                            <input v-model="contReqrAddress" class="form-control text-info" type="text" id="contReqrAddress" :placeholder="$t('require.locReqrPh') " >
                            <h6 class=" ml-3 text-info"> Latitude : {{ locReqr.lat }}  ,  Longitude : {{ locReqr.lng }}</h6>
                        </div>      
                        <div class="row ml-1">
                            <div class="col-sm-6">
                                <label class="f-w-600">{{ $t("require.minDetDate") }}</label>
                                <div class="form-group">
                                    <datepicker input-class="datepicker-here form-control digits" :disabled-dates="disabledDates" :format="format" v-model="minDetDate" ></datepicker>
                                </div>                     
                            </div>                                   
                        </div>  
                        <div class="row ml-4">
                            <div class="row">
                                <div class="col-sm-12">
                                    <b-form-group class="f-w-600" style="font-size: 16px"  :label="$t('require.contSize')" v-slot="{ ariaDescribedby }">
                                        <b-form-radio-group
                                            v-model="contSize"
                                            :options="optionsContSize"
                                            :aria-describedby="ariaDescribedby"
                                            name="contSize-options"
                                            plain
                                            stacked
                                            size="lg"
                                        ></b-form-radio-group>
                                    </b-form-group>
                                </div>  
                            </div>
                            <div class="row">
                                <div class="col-sm-12 ml-5">
                                    <b-form-group class="f-w-600" style="font-size: 16px"  :label="$t('require.contType')" v-slot="{ ariaDescribedby }">
                                        <b-form-radio-group
                                            v-model="contType"
                                            :options="optionsContType"
                                            :aria-describedby="ariaDescribedby"
                                            name="contType-options"
                                            plain
                                            stacked
                                            size="lg"
                                        ></b-form-radio-group>
                                    </b-form-group>
                                </div>  
                            </div>
                            <div class="row">
                                <div class="col-sm-12 ml-5">
                                    <b-form-group class="f-w-600" style="font-size: 16px"  :label="$t('require.contGrade')" v-slot="{ ariaDescribedby }">
                                        <b-form-radio-group
                                            v-model="contGrade"
                                            :options="optionsContGrade"
                                            :aria-describedby="ariaDescribedby"
                                            name="contGrade-options"
                                            size="lg"                                            
                                            plain
                                            stacked
                                        ></b-form-radio-group>
                                    </b-form-group>
                                </div>  
                            </div>
                            <div class="row ">
                                <div class="col-sm-12 ml-5">
                                    <b-form-group class="f-w-600" style="font-size: 16px"  :label="$t('require.jobType')" v-slot="{ ariaDescribedby }">
                                        <b-form-radio name="jobType" plain value="Drop-off" v-model="jobType"  :aria-describedby="ariaDescribedby">{{ $t('require.df') }}</b-form-radio>
                                        <b-form-radio name="jobType" plain value="Pick-up" v-model="jobType"  :aria-describedby="ariaDescribedby">{{ $t('require.pu') }}</b-form-radio>
                                    </b-form-group>
                                </div>
                            </div>                             
                        </div>
                        <b-modal ref="reqr-modal" hide-footer :title= "$t('release.findingResult')">
                            <div class="d-block text-center">
                                <h4>{{ $t('require.noMatchingCont') }}</h4>
                            </div>
                            <b-button class="my-2" style="font-size:18px;" variant="outline-warning" block @click="doNotModal">
                                {{ $t('require.dontPost') }}
                            </b-button>                            
                            <div class="row " style="align-items:center ">                                   
                                <form @submit.stop.prevent="postItModal">
                                    <div class="row ml-4">
                                        <label class="f-w-600 text-primary mt-1" style="font-size:18px;">{{ $t("require.qty") }}</label>
                                        <div>
                                            <b-form-input class="f-w-600 text-primary" style="font-size:18px;" v-model="reqrQty" type="number" min="1" max="100" step="1"></b-form-input>   
                                        </div>
                                    </div>
                                </form>                                           
                                <b-button class="mt-3" variant="outline-info" block @click="postItModal">
                                    <span class="my-5" style="font-size: 18px;">
                                        {{ $t("require.postIt") }}
                                    </span>                                                                             
                                </b-button>                                               
                            </div>   
                        </b-modal>
                        <!-- </div> -->
                        <div class="form-group mt-3 mb-0">
                            <button class="btn btn-primary btn-block" style="font-size:20px" type="button" @click="handleSearching">
                                {{ $t('require.searching') }}
                            </button>
                        </div>
                    </form>
                </div>
                <div>
                </div>                
                <div class="col-sm-6" id="googleMapReqr" >
                    <section class="ui one column centered grid mt-5" v-show="showAutoComplete" style="position:relative; z-index: 5;">
                        <div class="column" >
                            <form class="ui left segment small form" >
                                <div class="ui message red" v-show="error">{{ error }}</div>
                                <!-- <div class="ui segment" > -->
                                    <div class="field" >
                                        <div class="row mb-3 col-sm-12">
                                            <div class=" col-sm-1">
                                                <i class="icon times text-primary" style="font-size: 24px;"  @click="offAC" ></i>                                                    
                                            </div>             
                                            <div class="col-sm-11">
                                                <span class="text-warning"> {{ $t('company.donotEnterKey') }}</span>       
                                            </div>                                                      
                                        </div>
                                        <!-- <div class="ui message blue" >{{ compAddress }}</div> -->
                                        <div class="ui right icon input large" :class="{loading:spinner}">
                                            <input 
                                                type="text"
                                                v-b-popover.hover="$t('company.inputLocNameMsg')" 
                                                :title="$t('company.inputLocName')"   
                                                id="autocompleteReqr"
                                                v-model="address"
                                                onkeypress= "return event.keyCode != 13;"
                                            >
                                            <i 
                                                class="dot circle link icon" 
                                                @click="locatorButtonPressed"
                                                v-b-popover.hover.top="$t('company.useCurrPosition')"
                                            ></i>
                                        </div>
                                        <!-- dummy input to fix bug : only one input, make reload if we press ENTER.  -->
                                        <!-- <div class="ui message red" v-show="false">
                                            <input 
                                                type="text" 
                                            >
                                        </div> -->
                                    </div>
                                    <div class="row" @click="clearAddress">
                                        <i class="icon eraser f-10 ml-4 text-danger"  style="font-size: 18px;"></i>
                                        <h5 class="ml3 text-info" > {{ $t('company.clearAddress') }} </h5>
                                    </div>
                                <!-- </div> -->
                            </form>                               
                        </div>
                    </section> 
                    <section id="map" style="background-color: lightblue;"></section>   
                </div>
            </div>
            <b-alert v-model="showAlertWallet" variant="danger" dismissible class="col-sm-6">
                <h4 class="alert-heading f-w-800">{{ $t(`${this.$t("release.walletCompNotEnough")}`)}}</h4>
                <div class="row ml-1">
                    <span>{{ $t(`${this.$t("release.balancedAmount")}`) }}</span>
                    <span class="text-dark f-w-800 mx-2" style="font-size: 16px;">{{ balanced}}</span>
                    <span>{{ $t(`${this.$t("refill.mUnit")}`) }}</span>
                </div>
                <div>
                    <span>{{ $t(`${this.$t("release.refillOrContact")}`) }}</span>
                </div>
            </b-alert>
            <div class="row">
                <div class="col-md-12">
                    <div class="card">
                        <h4 class="mb-1 mt-4 f-w-600">{{ $t('require.matchingList') }}</h4>
                        <div class="table-responsive datatable-vue">
                            <b-table
                                selectable
                                sticky-header
                                head-variant="light"
                                table-variant="info"
                                select-mode="single"
                                show-empty
                                sort-icon-left
                                stacked="md"
                                selectedVariant="primary"
                                :busy="isBusy"
                                :items="items"
                                :fields="tablefields"
                                :current-page="currentPage"
                                :per-page="perPage"
                                @row-selected="rowSelected"
                            >
                                <template #cell(selected)="{ rowSelected }">
                                    <template v-if="rowSelected">
                                        <span aria-hidden="true">&check;</span>
                                        <span class="sr-only">{{ $t('require.selected') }}</span>
                                    </template>
                                    <template v-else>
                                        <span aria-hidden="true">&nbsp;</span>
                                        <span class="sr-only">{{ $t('require.notSelected') }}</span>
                                    </template>
                                </template>
                                <template #table-busy>
                                    <div class="text-center text-danger my-2">
                                    <b-spinner class="align-middle"></b-spinner>
                                    <strong>{{ $t('require.loading') }}</strong>
                                    </div>
                                </template>
                                <template #cell(contImage)="row" >
                                    <b-button  size="sm" @click="openModalImage(row.item, row.index, $event.target )" class="m-1">
                                        {{ row.item.contNo }}
                                    </b-button>
                                </template>
                                <template #empty>
                                    <h5>{{ $t('reuse.noRecord') }}</h5>
                                </template>
                            </b-table>
                        </div>
                        <b-row>
                            <div class="col-sm-3 ">
                                <b-col class="my-1 ml-3">
                                    <b-pagination
                                    v-model="currentPage"
                                    :total-rows="totalRows"
                                    :per-page="perPage"
                                    class="my-0"
                                    ></b-pagination>
                                </b-col>                             
                            </div>
                            <div class="col-sm-5" >  
                                <b-col class="delete-datatable">
                                    <b-button variant="primary" @click="selectBatchRow" class="mb-1" style="font-size:20px">
                                        {{ $t("require.confirmSelected") }} <span class="position-absolute top-0 start-100 translate-middle badge rouned-pill bg-warning">{{ selected.length }}</span>   
                                    </b-button>
                                </b-col>                            
                            </div>          
                            <div class="col-sm-4 " >
                                <b-form-group label-cols="2" :label= "$t('require.perPage')" class="datatable-select">
                                <b-form-select v-model="perPage" :options="pageOptions"></b-form-select>
                                </b-form-group>
                            </div>      
                        </b-row>
                            <!-- Info modal Container Image-->
                        <b-modal id="contModal" :title="modalTitle" ok-only @hide="resetInfoModal">
                            <div class="card me-5" style=" width:15rem">
                                <img  class="card-img-top" :src="imageUriModal" alt="Image Preview"/>
                            </div>
                        </b-modal>
                        <!-- </div> -->
                    </div>
                </div>
            </div>
            <div class="divider"> </div>
            <div>
                <h4 class="mb-1 mt-4 f-w-600">{{ $t('require.postedRequire') }}</h4>
                <b-table
                    sticky-header
                    head-variant="light"
                    table-variant="success"
                    show-empty
                    sort-icon-left
                    stacked="md"      
                    :items="itemsPostedReqr"
                    :fields="fieldsPostedReqr"
                    :current-page="currentPagePostedReqr"
                    :per-page="perPagePostedReqr"
                >
                    <template #cell(actPostedReqr)="row" >
                        <b-button  size="sm" @click="actPostedReqrCancel(row.item)" class="m-1">
                            {{ $t('matchTx.cancel') }}
                        </b-button>
                    </template>
                    <template #empty>
                        <h5>{{ $t('reuse.noRecord') }}</h5>
                    </template>
                </b-table>
                <b-row>
                    <div class="row col-sm-8 ">
                    <b-col class="my-1 ml-3">
                        <b-pagination
                        v-model="currentPagePostedReqr"
                        :total-rows="totalRowsPostedReqr"
                        :per-page="perPagePostedReqr"
                        align="fill"
                        class="my-0"
                        ></b-pagination>
                    </b-col>   
                    <b-col sm="3" md="6" class="my-1">
                        <b-form-group
                        :label= "$t('require.perPage')"
                        label-for="per-page-select"
                        label-cols-sm="6"
                        label-cols-md="4"
                        label-cols-lg="3"
                        label-align-sm="right"
                        label-size="sm"
                        class="mb-0"
                        >
                        <b-form-select
                            id="per-page-select"
                            v-model="perPagePostedReqr"
                            :options="pageOptionsPostedReqr"
                        ></b-form-select>
                        </b-form-group>
                    </b-col>
                    </div>
                </b-row>
                <!-- Info modal Reqr Attch Seal-->
            </div>
        </div>
        <!-- Container-fluid Ends-->
    </div>
</template>
<script>

const axios = require("axios");
import {getBalance, uaTxWallet, googleMapApiKey, compNameLocalByCompId, postedByCompId, getStatusByDocId, updateStatus ,agentAtBkgId, bkgIdList, locByCompId,saveWithId} from "../../firebase"
import Datepicker from "vuejs-datepicker";
import {reqrCost} from '../../constants/config'
import {getRandomInt} from "../../utilities/util" 
export default {
	data(){
		return{
            balanced: 0,
            showAlertWallet: false,
            disabledDates: {
                // to: new Date(Date.parse(new Date())-7905600000),  // 3 months back
                to: new Date(Date.parse(new Date())-86400000),  // today onward
            },
            showAutoComplete : false,
            bkgIdList : [],
            address : '',
            error : '',        
            spinner: false,
            format: "yyyy-MM-dd",
            reqrId: '',
            selectedBkgId : '',
            contType: '',
            optionsContType : [
                {text : 'Dry' , value : 'dry'},
                {text : 'Reefer' , value : 'reefer'},
                {text : 'Flat-rack' , value : 'flatRack'},
                {text : 'Open Top' , value : 'openTop'},
                {text : 'Ventilated' , value : 'vent'},
                {text : 'ISO Tank' , value : 'isoTank'}
            ],
            contSize: '',
            optionsContSize : [
                {text : "20'GP" , value : "20GP"},
                {text : "20'DC" , value : "20DC"},
                {text : "20'ST" , value : "20ST"},
                {text : "40'GP" , value : "40GP"},
                {text : "40'ST" , value : "40ST"},
                {text : "40'HC" , value : "40HC"},
                {text : "40'HQ" , value : "40HQ"},
                {text : "45'GP" , value : "45GP"},
                {text : "45'G1" , value : "45G1"},
            ],
            contGrade: '',
            optionsContGrade : [
                {text : 'A' , value : 'A'},
                {text : 'B' , value : 'B'},
                {text : 'C' , value : 'C'}
            ], 
            agent: '',
            contReqrAddress: '',
            locReqr : '',
            jobType : '',
            userId: '',
            compId: '',
            minDetDate : '',
            distLim : '50',
            payload : '',
            reqrQty : 1,
            isBusy: false,
            items : [],
            // items: [{"id": 1, "distance":0, "agent":"", "contRelsAddress":"Wait for matching result.","detDate":"YYYY-MM-DD","distLim":50,"contNo":"1","contType":"","contSize":"","contGrade":"","maxGross":"","tare":"","compNameLocal":"******","jobType":"Drop-off","relsId":""}],            
            tablefields: [
                { key: 'selected', label: this.$t('require.selected') },
                { key: 'distance', label: this.$t('require.distance'), sortable: true, variant: 'info' },                 
                { key: 'id', label: this.$t('require.id'), sortable: true, },  
                { key: 'contImage', label: this.$t('container.contNo'), variant: 'info'},      
                // { key: 'contNo', label: this.$t('require.contNo'), sortable: true, },
                { key: 'agent', label: this.$t('require.agent'), sortable: true, },                
                { key: 'detDate', label: this.$t('require.detDate'), sortable: true, variant: 'danger' },                
                { key: 'compNameLocalRels', label: this.$t('require.compNameLocalRels'), sortable: true, },                
                { key: 'contRelsAddress', label: this.$t('require.contRelsAddress'), sortable: false, },
                { key: 'contType', label: this.$t('require.contType'), sortable: true, },
                { key: 'contSize', label: this.$t('require.contSize'), sortable: true, },
                { key: 'contGrade', label: this.$t('require.contGrade'), sortable: true, },
                { key: 'maxGross', label: this.$t('container.maxGross'), sortable: true, },
                { key: 'tare', label: this.$t('container.tare'), sortable: true, },
                { key: 'jobType', label: this.$t('require.jobType'), sortable: true, 
                    formatter: value => {
                        return this.$t(`require.${value}`)
                }},
            ],
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [5, 10, 15],
            selected: [],
            imageUriModal: '',
            modalTitle : '',
            // PostedRequire
            itemsPostedReqr: [],
            fieldsPostedReqr: [
                { key: 'reqrId', label: this.$t('require.reqrId'), sortable: true, },  
                { key: 'bkgId', label: this.$t('require.bkgId'), sortable: true, },                        
                { key: 'agent', label: this.$t('require.agentByBkgId'), sortable: true, }, 
                { key: 'compNameLocalReqr', label: this.$t('matchTx.compNameLocalReqr'), sortable: true, },                
                { key: 'contReqrAddress', label: this.$t('matchTx.contReqrAddress'), sortable: false, },   
                { key: 'distLim', label: this.$t('require.distLim'), sortable: true, },             
                { key: 'minDetDate', label: this.$t('require.minDetDate'), sortable: true, }, 
                { key: 'contType', label: this.$t('require.contType'), sortable: true, },
                { key: 'contSize', label: this.$t('require.contSize'), sortable: true, },
                { key: 'contGrade', label: this.$t('require.contGrade'), sortable: true, },
                { key: 'jobType', label: this.$t('require.jobType'), sortable: true, 
                    formatter: value => {
                        return this.$t(`require.${value}`)
                }},
                { key: 'timestamp', label: this.$t('shipApproval.date'), sortable: true, 
                    formatter: value => {
                        return this.$moment(value).format("YY-MM-DD @ HH:mm")
                }},
                { key: 'actPostedReqr', label: this.$t('matchTx.actions'), variant: 'danger'}
            ],
            totalRowsPostedReqr: 1,
            currentPagePostedReqr: 1,
            perPagePostedReqr: 10,
            pageOptionsPostedReqr: [5, 10, 15, { value: 100, text: "Show a lot" }],
		}
	},
	components: {
		Datepicker
	},
    computed: {
        // sortOptions() {
        // // Create an options list from our fields
        // return this.tablefields.filter(f => f.sortable).map(f => { return { text: f.label, value: f.key } });
        // }
    },

	mounted(){
        // Set the initial number of items
        this.totalRows = this.items.length
        let compId= localStorage.getItem("compId")
        let userId= localStorage.getItem("userId")
        this.compId = compId
        this.userId= userId        
        bkgIdList(compId, (res)=>{
            this.bkgIdList = res
        })
        postedByCompId( "Require",this.compId, "Require" , (res)=>{
            this.itemsPostedReqr = res
            this.totalRowsPostedReqr = res.length
        })
        locByCompId(compId ,(res)=>{
            // console.log("res =>"+ JSON.stringify(res , null , 2))
            if (res) {
                this.locReqr = {
                        lat : res.compLoc.lat,
                        lng : res.compLoc.lng
                    }
                this.contReqrAddress = res.compAddress
                this.showUserLocationOnTheMap(res.compLoc.lat, res.compLoc.lng )                
            }
        })
        var autocomplete = new google.maps.places.Autocomplete(
            document.getElementById('autocompleteReqr'),
            {
                bounds: new google.maps.LatLngBounds(
                    new google.maps.LatLng(13.555 , 100.50)
                )
            }
        )
        autocomplete.addListener("place_changed",  ()=>{
            let place = autocomplete.getPlace()
            // console.log("place =>"+ JSON.stringify(place , null , 2))
            let lat = place.geometry.location.lat()
            let lng = place.geometry.location.lng() 
            if (!place.geometry) {
                this.$toasted.show(this.$t("require.fillinMatchedChar"), {theme: 'bubble',   position: 'bottom-right',   type: 'error', duration: 15000});
            } else {
                this.showUserLocationOnTheMap(lat, lng)
                this.getAddressFrom(lat, lng)              
                this.locReqr = {
                    lat : lat,
                    lng : lng
                }
            }
        })        
	},

	methods:{
        locatorButtonPressed(){
            this.spinner =true
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    (position) =>{ 
                        let lat = position.coords.latitude
                        let lng = position.coords.longitude
                        this.getAddressFrom(lat, lng )
                        this.showUserLocationOnTheMap(lat, lng )
                        this.locReqr = {
                            lat : lat,
                            lng : lng
                        }
                    },
                    error => {
                        this.error = "Locator is unable to find the address. Please type your address manually."
                        this.spinner =false
                    }
                );
            } else {
                this.error = error.message
                this.spinner =false
                // console.log(`Your browser does not support geolocation API. Please enter your address`);
            }
        },
        async getAddressFrom(lat, lng){
            await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${googleMapApiKey}&loading=async`)
            .then(response =>{
                if (response.data.error_message) {
                    this.error = response.data.error_message
                } else {
                   this.contReqrAddress = response.data.results[0].formatted_address 
                    this.address = response.data.results[0].formatted_address
                    // this.address = ''
                }
                this.spinner =false
            })
            .catch(error =>{
                this.error = error.message
                this.spinner =false
                // console.log(`error.message =>${JSON.stringify(error)}`)
            })
        },
        showUserLocationOnTheMap(latitude , longitude){
            // Creat a map object
            let map = new google.maps.Map(document.getElementById("map"),{
                zoom:15,
                center: new google.maps.LatLng(latitude, longitude),
                mapTypeId: google.maps.MapTypeId.ROADMAP
            })
            // Add marker
            new google.maps.Marker({
                position: new google.maps.LatLng(latitude, longitude),
                map: map
            })
            // await new google.maps.marker.AdvanceedMarkerElement({
            //     position: await new google.maps.LatLng(latitude, longitude),
            //     map: map
            // })
        },
        clearAddress(){
            this.address = ''
        },
        showAC(){
            if (this.showAutoComplete == false) {
                this.showAutoComplete = true
            } else {
                this.showAutoComplete = false
            }
        },
        offAC(){
            if (this.showAutoComplete == true) {
                this.showAutoComplete = false
            } 
        },
        showMatchingTable( updatedRelsList){
            // console.log (`updateRelsList.data (before nextTick) => ${JSON.stringify(updatedRelsList ,null, 2)}`)  
            this.items = updatedRelsList
            this.totalRows = updatedRelsList.length     
            this.isBusy = false
        },
        showModal() {
            this.$refs['reqr-modal'].show()
            this.isBusy = false            
        },
        async postItModal() {
            var reqrData = this.payload
            let totalReqrCost = reqrCost * parseInt(this.reqrQty)
            await getBalance(this.compId , async(res)=>{
                if (res < totalReqrCost) {
                    this.balanced = res 
                    this.showAlertWallet = true
                } else {
                    if (this.reqrQty = 1) {
                        await saveWithId('Require', reqrData.reqrId, reqrData).then(()=>{
                            this.$toasted.show( this.$t("require.post1Reqr"), {theme: 'bubble', type: 'success' , position: 'bottom-center', duration: 5000});   
                            // this.refreshPostedRequire() 
                        })   
                        // this.$refs['reqr-modal'].hide()
                    } else {
                        for (let i = 0; i < this.reqrQty; i++) {    
                            let YYMM = this.$moment().format("YYMM")
                            let id = YYMM+'RQ'+`${Math.floor(Math.random() * (90000 - 10000 + 1) + 10000)}`
                            reqrData.reqrId = id 
                            await saveWithId('Require', id, reqrData)
                        } 
                        this.$toasted.show( `${this.reqrQty} ${this.$t("require.reqrWasPosted")}`, {theme: 'bubble', type: 'success' , position: 'bottom-center', duration: 5000});   
                    }
                    let slipId = `${this.$moment().format("YYMMDDHH")}-${getRandomInt(10000 , 90000)}`
                    await uaTxWallet(totalReqrCost * -1, this.compId, slipId, "auto", "expense", "post require" , this.payload.reqrId)
                    this.refreshPostedRequire()
                    this.$refs['reqr-modal'].hide()                     
                    setTimeout(function () {
                        window.location.reload
                    },1000) 
                }
            })
        },
        doNotModal() {
            // We pass the ID of the button that we want to return focus to
            // when the modal has hidden
            // this.$refs['reqr-modal'].toggle('#toggle-btn')
            this.$refs['reqr-modal'].hide()
        },
        rowSelected(item) {
            this.selected = item
        },
        async selectBatchRow(){
            // console.log (`selectBatchRow, this.items => ${JSON.stringify(this.items ,null, 2)}`)  
            // console.log (`selectBatchRow, this.selected => ${JSON.stringify(this.selected ,null, 2)}`)  
            await this.makeMatchTx(this.selected, (res) => {
                if (res != "done") {
                    this.$toasted.show(this.$t("require.notMatched"), {theme: 'bubble', type: 'error' , position: 'bottom-center', duration: 5000});  
                } else {
                    this.items = []
                    this.totalRows = 0
                    this.selected =[]
                    this.minDetDate = ''
                    this.selectedBkgId = ''
                    this.contType = ''
                    this.contSize = ''
                    this.contGrade = ''
                    this.jobType = '' 
                }
            })
        },
        // async makeMatchTx(selectedConts , cb){  // seleted release item.
        //     let selContQty = selectedConts.length
        //     let totalReqrCost = reqrCost * selContQty
        //     if (selContQty == 0) {
        //         this.$toasted.show(this.$t("require.noSelectedCont"), {theme: 'bubble', type: 'error' , position: 'bottom-center', duration: 5000});   
        //     } else {
        //         await getBalance(this.compId, async(res)=>{
        //             if (res < totalReqrCost) {
        //                 this.balanced = res 
        //                 this.showAlertWallet = true
        //             } else {
        //                 for (let i = 0; i < selContQty; i++) {
        //                     await this.saveMatchTx(selectedConts[i]);
        //                 }
        //                 let slipId = `${this.$moment().format("YYMMDDHH")}-${getRandomInt(10000 , 90000)}`
        //                 await uaTxWallet(totalReqrCost * -1, this.compId, slipId, "auto", "expense", "match require" ,"Reqr cont(s): MatchTx")
        //                 this.$toasted.show(this.$t("require.makeMatchingTx"), {theme: 'bubble', type: 'success' , position: 'bottom-center', duration: 10000});                          
        //                 cb( "done")
        //             }
        //         })
        //     }
        // },
        async makeMatchTx(selectedCont , cb){  // seleted release item.
            let mTxId = `${this.$moment().format("YYMM")}`+'MT'+`${getRandomInt(10000 , 90000)}`
            // console.log(`selectedCont => ${JSON.stringify(selectedCont , null , 2)}`)
            if (selectedCont.length == 0) {
                this.$toasted.show(this.$t("require.noSelectedCont"), {theme: 'bubble', type: 'error' , position: 'bottom-center', duration: 5000});   
            } else {
                await getBalance(this.compId, async(res)=>{
                    if (res < reqrCost) {
                        this.balanced = res 
                        this.showAlertWallet = true
                    } else {
                        await getStatusByDocId( "Release",selectedCont[0].relsId, async(res)=>{
                            if (res == "Matched") {
                                this.$toasted.show( this.$t("require.postedMatched"), {theme: 'bubble', type: 'info' , position: 'bottom-center', duration: 10000}); 
                            } else {
                                await this.saveMatchTx(selectedCont[0] , mTxId);
                                let slipId = `${this.$moment().format("YYMMDDHH")}-${getRandomInt(10000 , 90000)}`
                                await uaTxWallet(reqrCost * -1, this.compId, slipId, "auto", "expense", "match by require", mTxId,)
                                this.$toasted.show(this.$t("require.makeMatchingTx"), {theme: 'bubble', type: 'success' , position: 'bottom-center', duration: 10000});                          
                                cb( "done")
                            }
                        })
                    } 
                })
            }
        },
        async saveMatchTx( selCont , mTxId){
            const data = {
                mTxId : mTxId,
                bkgId: this.selectedBkgId,                
                contNo : selCont.contNo,
                agent: selCont.agent, 
                contType: selCont.contType,
                contSize: selCont.contSize,
                contGrade: selCont.contGrade,                               
                detDate : selCont.detDate,
                detTS : selCont.detTS,
                imageUri : selCont.imageUri,
                maxGross : selCont.maxGross,
                tare : selCont.tare,
                distance: selCont.distance,
                compNameLocalRels:selCont.compNameLocalRels,                
                contRelsAddress: selCont.contRelsAddress,
                compNameLocalReqr :await compNameLocalByCompId(this.compId),                
                contReqrAddress: this.contReqrAddress,                
                jobTypeRels: selCont.jobType,
                jobTypeReqr: this.jobType,
                msReqr: 'matched',
                msReqrTS: Date.parse(new Date()),
                jbReqr: 'waitAccept',
                jbReqrTS:Date.parse(new Date()),
                actReqr:{
                    cancel:true, 
                    accept: false, 
                    reject:false, 
                    attachSeal:false
                },
                actRels:{
                    cancel:false,
                    accept: true,
                    reject:true,
                    attachSeal:false
                },
                stepNo: 1,                                      
                msRels: 'matched',
                msRelsTS:Date.parse(new Date()),
                jbRels: 'offer',
                jbRelsTS:Date.parse(new Date()),
                compIdRels:selCont.compId,
                compIdReqr : this.compId,
                userIdRels: selCont.userId,
                userIdReqr: this.userId,
                relsTS:selCont.timestamp,
                reqrTS: Date.parse(new Date()),
                // reqrId:this.reqrId,
                reqrId: "noPost",
                relsId:selCont.relsId,                
                status: "matched", 
                txSearcher: "Require", 
                timestamp: Date.parse(new Date()),
                active: true,
                sealNo: ""                                              
            }
            await saveWithId('MatchTx', mTxId, data).then(async()=>{
                await updateStatus('Release', selCont.relsId, 'Matched')
                // await updateActive2False_Cont(selCont.contNo)  
            })
        },
        handleSearching(){
            let msg = 'completed'
            if (this.selectedBkgId ==='') {
                msg = 'require.addSelectedBkgId'
            } else if (this.contReqrAddress ===''){
                msg = 'require.addContReqrAddress'
            } else if (this.LocReqr== ''){
                msg = 'require.useGoogleMap'
            } else if (this.minDetDate ===''){
                msg = 'require.selMinDetDate'
            } else if (this.distLim ===''){
                msg = 'require.addDistLim'
            } else if (isNaN(parseInt(this.distLim))){
                msg = 'require.distLimNumber'
            } else if (this.jobType ===''){
                msg = 'require.selJobType'
            } else if (this.contType == ''){
                msg = 'require.selContType'
            } else if (this.contSize ===''){
                msg = 'require.selContSize'
            } else if (this.contGrade == ''){
                msg = 'require.selContGrade'
            }  
            if (msg === 'completed'){
                this.items = []   // clear data in the matching table
                agentAtBkgId(this.selectedBkgId , this.compId, async(ag)=>{
                    this.isBusy = true  // turn om spiner
                    this.agent = ag
                    let YYMM = this.$moment().format("YYMM")
                    this.reqrId = YYMM+'RQ'+`${Math.floor(Math.random() * (90000 - 10000 + 1) + 10000)}`
                    let mDetDate = this.$moment(this.minDetDate).format("YYYY-MM-DD")
                    let mDetTS = Date.parse(mDetDate + ' ' + '23:59:59')
                    const payload = {
                        reqrId : this.reqrId,
                        bkgId : this.selectedBkgId,
                        distLim : parseInt(this.distLim),
                        contReqrAddress: this.contReqrAddress,
                        locReqr: this.locReqr,            
                        minDetDate : mDetDate,
                        minDetTS: mDetTS,
                        contType : this.contType,
                        contSize : this.contSize,
                        contGrade : this.contGrade,
                        agent : ag,
                        jobType : this.jobType,
                        compId : this.compId,
                        compNameLocalReqr : await compNameLocalByCompId(this.compId),
                        userId : this.userId,
                        status: "Require",
                        timestamp: Date.parse(new Date())
                    }
                    // console.log ('payload=>'+JSON.stringify(payload , null, 2))    
                    const functionsURL ='https://matchedreqr-7gonzgqu6a-as.a.run.app'       
                    await axios.post (functionsURL, payload)
                    .then((response)=>{
                        // console.log ('post, response.data=>'+JSON.stringify(response.data , null, 2))
                        let respData = response.data
                        if (respData.length == 0) {
                            this.payload = payload
                            this.showModal()                              
                        } else {
                            this.showMatchingTable(respData)
                        }
                    })
                    .catch((error) => { 
                        console.log(` Network Error => ${error}` )
                        this.$toasted.show( `Network Error : ${error}`, {theme: 'bubble', type: 'error' , position: 'bottom-center', duration: 10000});       
                    });
                    // this.$router.push('/');  
                    // let {data} = await axios.post (functionsURL, payload)
                    // this.$toasted.show(`Distance: ${JSON.stringify(data)}}`, {theme: 'bubble', type: 'success' , position: 'bottom-center', duration: 10000});                  
                })
                                              
            }else{
                this.$toasted.show(this.$t(msg), {theme: 'bubble', type: 'error' , position: 'bottom-center', duration: 5000});     
            }
        },



        // PostedReqr
        async refreshPostedRequire() {
            await postedByCompId( "Require",this.compId, "Require" , (res)=>{
                this.itemsPostedReqr = res
                this.totalRowsPostedReqr = res.length
                })
        },
        actPostedReqrCancel(item) {
            this.$bvModal.msgBoxConfirm(this.$t('require.confirmCancelMsg'), {
            title: this.$t('matchTx.plsConfirm'),
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'warning',
            okTitle: this.$t('matchTx.yes'),
            cancelTitle: this.$t('matchTx.no'),
            footerClass: 'p-2',
            hideHeaderClose: false,
            centered: true
            })
            .then(value => {
                if (value) {
                    getStatusByDocId( "Require", item.reqrId, async(res)=>{
                        if (res == "Matched") {
                            this.$toasted.show( this.$t("require.postedMatched"), {theme: 'bubble', type: 'info' , position: 'bottom-center', duration: 10000});  
                        } else {
                            updateStatus('Require', item.reqrId, 'Cancel')
                            this.refreshPostedRequire()
                            let slipId = `${this.$moment().format("YYMMDDHH")}-${getRandomInt(10000 , 90000)}`
                            await uaTxWallet(reqrCost, this.compId, slipId, "auto", "refund", "cancel posted require",item.reqrId)
                            this.$toasted.show( this.$t("require.postedCancelled"), {theme: 'bubble', type: 'error' , position: 'bottom-center', duration: 10000});                              
                            setTimeout(function () {
                                window.location.reload
                            },1000) 
                        }
                    })
                } else {
                return
                }
            })
            .catch(error => {
                this.$toasted.show( `Action-Cancel: ${error}`, {theme: 'bubble', type: 'error' , position: 'bottom-center', duration: 10000});  
            })
        }, 
        openModalImage(item, index, button) {
            this.imageUriModal = item.imageUri
            this.modalTitle = item.contNo
            this.$root.$emit('bv::show::modal', "contModal" , button)
        },
        resetInfoModal() {
            this.modalTitle = ''
            this.imageUriModal = ''
        },
    }
}
</script>
<style>
.ui.button,
.dot.circle.icon{
    background-color: #138fc0;
    color : white
}
.pac-icon {
    display: none;
}
.pac-item{
    padding:0px;
    font-size: 10px;
    cursor: pointer;
}
.pac-item:hover {
    background-color: #ececec;
}
.pac-item-query {
    font-size: 10px;
}
#map {
    position:absolute;
    top:0;
    right:0;
    bottom: 0;
    left: 0;
    background: blue;
}

.style-chooser .vs__search::placeholder,
.style-chooser .vs__dropdown-toggle,
.style-chooser .vs__dropdown-menu {
  background: #dfe5fb;
  border: none;
  color: #394066;
  /* text-transform: lowercase;
  font-variant: small-caps; */
}

.style-chooser .vs__clear,
.style-chooser .vs__open-indicator {
  fill: #394066;
}
.divider {
        height: 1px;
        background-color: #0f0f0f;
        margin:5px
    }

</style>
