import Vue from "vue";
import Vuex from "vuex";
import 'es6-promise/auto'
import layout from './modules/layout'
import menu from './modules/menu'
import user from './modules/user'
import realtimeFs from './modules/firestoremodule'
Vue.use(Vuex);

export const store = new Vuex.Store({
    modules: {
        realtimeFs,
        layout,
        menu,
        user,
    },
    strict: false
});

