import Vue from 'vue'
import Router from "vue-router";

import {authUser} from "../firebase"

import Body from '../components/body'
import auth from "../auth/auth"
import loginPage from "../auth/loginPage"
import forgotPassword from "../auth/forgotPassword"
import Register from '../auth/register'


/* maintenance */
import Maintenance from '../pages/maintenance'

/* Error Pages */
import Error400 from '../errors/error_400'
import Error401 from '../errors/error_401'
import Error403 from '../errors/error_403'
import Error404 from '../errors/error_404'
import Error500 from '../errors/error_500'
import Error503 from '../errors/error_503'

/* comingsoon */
import ComingsoonSimple from '../pages/comingsoon/comingsoon_simple'
import ComingsoonImage from '../pages/comingsoon/comingsoon_image'
import ComingsoonVideo from '../pages/comingsoon/comingsoon_video'

import HomePage from '../pages/dashboard/homePage'
import Matching from '../pages/dashboard/matching'
import MatchingAdmin from '../pages/dashboard/matchingAdmin'
import ShipApproval from '../pages/dashboard/shipApproval'
import ShipDetDate from '../pages/dashboard/shipDetDate'
/* Procee */
import Reuse from '../pages/process/reuse'
import Require from '../pages/process/require'
import Release from '../pages/process/release'
import ReuseComp from '../pages/process/reuseComp'
import RequireAdmin from '../pages/process/requireAdmin'
import ReleaseAdmin from '../pages/process/releaseAdmin'
/* payment */
import RefillCC from '../pages/payment/refillCC'
import RefillOL from '../pages/payment/refillOL'
import AdjustBal from '../pages/payment/adjustBal'
/* Resources */
import Container from '../pages/resource/container'
import BookingId from '../pages/resource/bookingId'
import Seal from '../pages/resource/seal'
import Cert from '../pages/resource/cert'
import Company from '../pages/resource/company'
import User from '../pages/resource/user'
import CreateUser from '../pages/resource/createUser'
import AgentGroup from '../pages/resource/agentGroup'
/* Info */
import Manual from '../pages/info/manual'
import Fees from '../pages/info/fees'
import TermCondition from '../pages/info/termCondition'
/* Report */
import Slip from '../pages/report/slip'
import Completed from '../pages/report/completed'
import Bill from '../pages/report/bill'
import BillComp from '../pages/report/billComp'
import BillAdmin from '../pages/report/billAdmin'
import ShipReport from '../pages/report/shipReport'
import CompWalletReport from '../pages/report/compWalletReport'
import RefillSlip from '../pages/report/refillSlip'
/* Widgets */

/* User Profile */
// import UserProfile from '../pages/users/profile'
import UserEditProfile from '../pages/users/edit-profile'



// component

Vue.use(Router)

const routes = [
  { path: '', redirect: { name: 'login' }},
// { path: '', redirect: { name: 'Matching' }},
// { path: '', redirect: { name: '' }},
{
  path:'/auth',
  component:auth,
  children:[
    {
      path:'login',
      name: 'login',
      component:loginPage
    },
    {
      path:'forgotPassword',
      name: 'forgotPassword',
      component:forgotPassword
    },
    {
      path: 'register',
      name: 'register',
      component: Register,
      meta: {
        title: ' Register | Conex - Premium Matching Empty Contsiner Platform',
      }
    }
  ]
},
{
  path: '/dashboard',
  component: Body,
  meta: {
    requiresAuth: true
  },
  children: [
  {
    path: 'homePage',
    name: 'HomePage',
    component: HomePage,
  },
  {
    path: 'matching',
    name: 'Matching',
    component: Matching,
  },
  {
    path: 'matchingAdmin',
    name: 'MatchingAdmin',
    component: MatchingAdmin,
  },
  {
    path: 'shipApproval',
    name: 'ShipApproval',
    component: ShipApproval,
  },
  {
    path: 'shipDetDate',
    name: 'ShipDetDate',
    component: ShipDetDate,
  },
  ]
},
{
  path: '/process',
  component: Body,
  children: [
  {
    path: 'reuse',
    name: 'reuse',
    component: Reuse,
    meta: {
      title: 'RE-USE',
    }
  },
  {
    path: 'require',
    name: 'require',
    component: Require,
    meta: {
      title: 'REQUIRE',
    }
  },
  {
    path: 'release',
    name: 'release',
    component: Release,
    meta: {
      title: 'RELEASE',
    }
  },
  {
    path: 'reuseComp',
    name: 'reuseComp',
    component: ReuseComp,
    meta: {
      title: 'REUSE-Company',
    }
  },
  {
    path: 'requireAdmin',
    name: 'requireAdmin',
    component: RequireAdmin,
    meta: {
      title: 'REQUIRE-Admin',
    }
  },
  {
    path: 'releaseAdmin',
    name: 'releaseAdmin',
    component: ReleaseAdmin,
    meta: {
      title: 'RELEASE-Admin',
    }
  }
  ]
},
{
  path: '/resource',
  component: Body,
  children: [
  {
    path: 'container',
    name: 'container',
    component: Container,
    meta: {
      title: 'Container Input Data',
    }
  },
  {
    path: 'bookingId',
    name: 'bookingId',
    component: BookingId,
    meta: {
      title: 'BookingId Input Data',
    }
  },
  {
    path: 'seal',
    name: 'seal',
    component: Seal,
    meta: {
      title: 'Seal Input Data',
    }
  },
  {
    path: 'cert',
    name: 'cert',
    component: Cert,
    meta: {
      title: 'Container Certification Input Data',
    }
  },
  {
    path: 'company',
    name: 'company',
    component: Company,
    meta: {
      title: 'Company Information',
    }
  },
  {
    path: 'user',
    name: 'user',
    component: User,
    meta: {
      title: 'User Information',
    }
  },
  {
    path: 'agentGroup',
    name: 'agentGroup',
    component: AgentGroup,
    meta: {
      title: 'Agent/Ship Group',
    }
  },
  {
    path: 'createUser',
    name: 'createUser',
    component: CreateUser,
    meta: {
      title: 'Create New User',
    }
  },
  ]
},
{
  path: '/payment',
  component: Body,
  children: [
  {
    path: 'refillCC',
    name: 'refillCC',
    component: RefillCC,
    meta: {
      title: 'Refill Cash',
    }
  },
  {
    path: 'refillOL',
    name: 'refillOL',
    component: RefillOL,
    meta: {
      title: 'Refill Online',
    }
  },
  {
    path: 'adjustBal',
    name: 'adjustBal',
    component: AdjustBal,
    meta: {
      title: 'Adjust Balance',
    }
  },
  ]
},
{
  path: '/report',
  component: Body,
  children: [
  {
    path: 'slip',
    name: 'slip',
    component: Slip,
    meta: {
      title: 'Slip Report',
    }
  },
  {
    path: 'completed',
    name: 'completed',
    component: Completed,
    meta: {
      title: 'Completed Report',
    }
  },
  {
    path: 'bill',
    name: 'bill',
    component: Bill,
    meta: {
      title: 'Bill Report',
    }
  },
  {
    path: 'billComp',
    name: 'billComp',
    component: BillComp,
    meta: {
      title: 'Bill Report - Company',
    }
  },
  {
    path: 'billAdmin',
    name: 'billAdmin',
    component: BillAdmin,
    meta: {
      title: 'Bill Report - Admin',
    }
  },
  {
    path: 'shipReport',
    name: 'shipReport',
    component: ShipReport,
    meta: {
      title: 'Ship Report',
    }
  },
  {
    path: 'compWalletReport',
    name: 'compWalletReport',
    component: CompWalletReport,
    meta: {
      title: 'Company Wallet Report ',
    }
  },
  {
    path: 'refillSlip',
    name: 'refillSlip',
    component: RefillSlip,
    meta: {
      title: 'Refill Slip Report',
    }
  },
  ]
},
{
  path: '/info',
  component: Body,
  children: [
  {
    path: 'manual',
    name: 'manual',
    component: Manual,
    meta: {
      title: 'User Manual',
    }
  },
  {
    path: 'fees',
    name: 'fees',
    component: Fees,
    meta: {
      title: 'Process Fees',
    }
  },
  {
    path: 'termCondition',
    name: 'termCondition',
    component: TermCondition,
    meta: {
      title: 'Terms & Conditions',
    }
  },
  ]
},
{
  path:'/users',
  component: Body,
  children:[
    {
      path: 'edit',
      name: 'UserEditProfile',
      component:UserEditProfile,
       meta: {
        title: 'serEditProfile | Conex - Premium Admin Template',
      }
    }
  ]
},
{
  path:'/error-400',
  name:'Error400',
  component:Error400,
  meta: {
      title: 'Error400 | Conex - Premium Admin Template',
    }
},
{
  path:'/error-401',
  name:'Error401',
  component:Error401,
  meta: {
      title: 'Error401 | Conex - Premium Admin Template',
    }
},
{
  path:'/error-403',
  name:'Error403',
  component:Error403,
  meta: {
      title: 'Error403 | Conex - Premium Admin Template',
    }
},
{
  path:'/error-404',
  name:'Error404',
  component:Error404,
  meta: {
      title: 'Error404 | Conex - Premium Admin Template',
    }
},
{
  path:'/error-500',
  name:'Error500',
  component:Error500,
  meta: {
      title: 'Error500 | Conex - Premium Admin Template',
    }
},
{
  path:'/error-503',
  name:'Error503',
  component:Error503,
  meta: {
      title: 'Error503 | Conex - Premium Admin Template',
    }
},
{
  path:'/maintenance',
  name:'Maintenance',
  component:Maintenance,
  meta: {
      title: 'Maintenance | Conex - Premium Admin Template',
    }
},
{
  path:'/comingsoon/comingsoon-simple',
  name:'ComingsoonSimple',
  component:ComingsoonSimple,
  meta: {
      title: 'ComingsoonSimple | Conex - Premium Admin Template',
    }
},
{
  path:'/comingsoon/comingsoon-image',
  name:'ComingsoonImage',
  component:ComingsoonImage,
  meta: {
      title: 'ComingsoonImage | Conex - Premium Admin Template',
    }
},
{
  path:'/comingsoon/comingsoon-video',
  name:'ComingsoonVideo',
  component:ComingsoonVideo,
  meta: {
      title: 'ComingsoonVideo | Conex - Premium Admin Template',
    }
}
];

const router = new Router({
  routes,
  base: '/conex/',
  mode: 'history',
  linkActiveClass: "active",
  scrollBehavior () {
    return { x: 0, y: 0 }
  }
});


router.beforeEach((to, from, next) => {
  // const  path = ['/auth/login','/auth/register'];
  // if (path.includes(to.path) || localStorage.getItem('user')){
  //   return next();
  // }

  authUser.onAuthStateChanged((user) => {
    if(to.meta.title)
    document.title = to.meta.title;
    const CurrentUser = authUser.currentUser;
    const path = ['/auth/login','/auth/register'];
    if (path.includes(to.path) || to.path === "/callback" || CurrentUser ){
    return next();
    }

    next('/auth/login');
  });
});

export default router
