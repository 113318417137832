<template>
	<div>
		<Breadcrumbs main="bookingId.resource" title="bookingId.header"/>
		<!-- Container-fluid starts-->
		<div class="container-fluid search-page">
            <div class="d-flex flex-row justify-content-between">
                <div class="col-sm-5">
                    <div class="row">
                        <div class="col-sm-12">
                            <h4 class="text-primary">{{ $t('bookingId.addBooking') }}</h4>    
                            <form class="theme-form">
                                <div class="d-flex flex-row justify-content-start">
                                    <div class="form-group col-sm-6">
                                        <label for="bkgId" class="col-form-label pt-0 f-w-600 " style="font-size: 16px;" >{{ $t("bookingId.bkgId") }}</label>                                                 
                                        <input  v-model="bkgId" class="form-control" type="text" id="bkdId" :placeholder="$t('bookingId.bkgIdPh')" >
                                    </div>  
                                    <div class="form-group col-sm-4 ml-3">
                                        <label for="agent" class="col-form-label pt-0 f-w-600" style="font-size: 16px;"  >{{ $t("bookingId.agent") }}</label>    
                                        <!-- <select id="agent" class="form-select" v-model="agent">
                                            <option v-for = "( agent, index) in agentList" :key="index" :value="agent.agentNameEng"> 
                                                <span ml-5> {{ agent.agentNameEng }}</span>
                                            </option> 
                                        </select> -->
                                        <v-select label="agent"  style="font-size:16px;" v-model="agent" :options="agentList" :placeholder="$t('container.ownerPh')">                                        
                                        </v-select>
                                    </div>                             
                                </div>
                                <div class="form-group">
                                    <button class="btn btn-primary btn-block" style="font-size:20px"  type="button" @click="handleSave">{{ $t('bookingId.saveAdd') }}</button>
                                </div>
                            </form>                    
                        </div>
                    </div>    
                    <div class="row col-sm-12"> 
                        <div class=" card">
                            <div class="top">
                                <span class="text-primary" style="font-size: 20px;">{{ $t('bookingId.dragDrop') }}</span>
                            </div>
                            <div class="drag-area" @dragover.prevent="onDragOver" @dragleave.prevent="onDragLeave" @drop.prevent="onDrop">  
                                <span class="text-info" v-if="!isDragging">
                                    {{ $t('bookingId.dragDropHere') }}
                                        <span class="select" role="button" @click="selectFiles">
                                        {{ $t('bookingId.choose') }}
                                        </span>
                                </span>
                                <div v-else class="select">{{ $t('bookingId.dropHere') }}</div>
                                <input name="file" type="file" accept="application/pdf, image/png, image/jpg" class="file" ref="fileInput" @change="onFileSelect"/>
                            </div>
                            <div v-if="fileType == 'pdf'" >
                                <div  class="file" v-for="(file, index) in files" :key="index" >
                                    <span class="delete" @click="deleteFile(index)">&times;</span>
                                    <vue-pdf-embed :source="file" />
                                </div>
                            </div>
                            <div v-else class="container">
                                <div  class="file" v-for="(file, index) in files" :key="index" >
                                    <span  class="delete" @click="deleteFile(index)">&times;</span>
                                    <img :src="file.url" alt=" image "/>
                                </div>
                            </div>
                                        
                        </div>
                    </div>   
                </div>
                <div class="col-sm-7">
                    <h4 class="text-primary">{{ $t('bookingId.activeBookingIdList') }}</h4>                             
                    <b-col lg="6" class="my-1">
                        <b-form-group
                        :label="$t('matchTx.filter')"
                        label-for="filter-input"
                        label-cols-sm="4"
                        label-align-sm="right"
                        label-size="lg"
                        class="mb-0"
                        >
                            <b-input-group size="sm">
                                <b-form-input
                                id="filter-input"
                                v-model="filter"
                                type="search"
                                :placeholder="$t('matchTx.typeToSearch')"
                                class="text-info"
                                style="font-size: 18px;"
                                ></b-form-input>
                                <b-input-group-append>
                                <b-button size="md" variant="primary" :disabled="!filter" @click="filter = ''">
                                    {{ $t('matchTx.clear') }}
                                </b-button>
                                </b-input-group-append>
                            </b-input-group>
                        </b-form-group>
                    </b-col>                            
                    <b-col lg="6" class="my-1">
                        <b-form-group
                        v-model="sortDirection"
                        :label="$t('matchTx.filterOn')"
                        :description= "$t('matchTx.uncheck')"
                        label-cols-sm="4"
                        label-align-sm="right"
                        label-size="lg"
                        class="mb-0"
                        v-slot="{ ariaDescribedby }"
                        >
                        <b-form-checkbox-group
                            v-model="filterOn"
                            :aria-describedby="ariaDescribedby"
                            class="mt-1 text-info"
                            plain
                        >
                            <b-form-checkbox value="bkgId">{{ $t('bookingId.bkgId') }}</b-form-checkbox>                                        
                            <b-form-checkbox value="agent">{{ $t('bookingId.agent') }}</b-form-checkbox>
                        </b-form-checkbox-group>
                        </b-form-group>
                    </b-col>                                  
                    <b-row class="ml-2">
                        <b-table
                            sticky-header
                            head-variant="light"
                            table-variant="success"
                            show-empty
                            sort-icon-left
                            stacked="md"
                            :sort-direction="sortDirection"
                            :filter="filter"
                            :filter-included-fields="filterOn"
                            :items="items"
                            :fields="fields"
                            :tbody-tr-class="rowClass"
                            :current-page="currentPage"
                            :per-page="perPage"
                            @filtered="onFiltered"
                        >
                                              <!-- A virtual column -->
                            <template #cell(index)="data">
                                {{ data.index + 1 }}
                            </template>
                            <template #cell(act)="row" >
                                <b-button  v-show="row.item.active"  size="sm" variant="warning" @click="actStop(row.item)" class="m-1">
                                    {{ $t('bookingId.stop') }}
                                </b-button>
                                <b-button  v-show="!row.item.active" size="sm" variant="success" @click="actAble(row.item)" class="m-1">
                                    {{ $t('company.able') }}
                                </b-button>
                                <b-button size="sm" variant="danger" @click="actDelete(row.item)" class="m-1">
                                    {{ $t('container.delete') }}
                                </b-button>
                            </template>
                            <template #cell(bookingIdFile)="row" >
                                <b-button  size="sm" @click="openBkgFile(row.item, row.index, $event.target )" class="m-1">
                                    {{ row.item.bkgId }}
                                </b-button>
                            </template>
                            <template #empty>
                                <h5>{{ $t('reuse.noRecord') }}</h5>
                            </template>
                        </b-table>  
                    </b-row>
                    <b-row>
                        <b-col class="my-1 ml-1">
                            <b-pagination
                            v-model="currentPage"
                            :total-rows="totalRows"
                            :per-page="perPage"
                            align="fill"
                            class="my-0"
                            ></b-pagination>
                        </b-col>   
                        <b-col sm="3" md="6" class="my-1">
                            <b-form-group
                            :label= "$t('require.perPage')"
                            label-for="per-page-select"
                            label-cols-sm="6"
                            label-cols-md="4"
                            label-cols-lg="3"
                            label-align-sm="right"
                            label-size="sm"
                            class="mb-0"
                            >
                            <b-form-select
                                id="per-page-select"
                                v-model="perPage"
                                :options="pageOptions"
                            ></b-form-select>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-modal ref="bkgId-modal" hide-footer :title="$t('bookingId.repeatBkgId')">
                        <div class="d-block text-center">
                            <h4>{{ $t('bookingId.repeatBkgIdMsg') }}</h4>
                        </div>
                        <b-button class="my-2" style="font-size:18px;" variant="outline-warning" block @click="doNotModal">
                            {{ $t('bookingId.doNot') }}
                        </b-button>                            
                        <div class="row " style="align-items:center ">                                                                         
                            <b-button class="mt-3" variant="outline-danger" block @click="overwriteModal">
                                <span class="my-5" style="font-size: 18px;">
                                    {{ $t("bookingId.overwrite") }}
                                </span>                                                                             
                            </b-button>                                               
                        </div>   
                    </b-modal>                 
                </div>
            </div>
        </div>
        <!-- Container-fluid Ends-->
    </div>
</template>
<script>
import { agentList, deleteItem,updateActive ,uploadImage, bkgIdListAll, saveWithId, agentAtBkgId} from "../../firebase"
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'
export default {
    components: {
    VuePdfEmbed,
  },
	data(){
		return{
            compId : '',
            agentList : [],
            bkgId : '',
            agent: '',
            files : [],
            fileType : '',
            isDragging: false,
            url: '',
            items: [],
            fields: [
                { key: 'index', label: this.$t('seal.item'), sortable: true, }, 
                { key: 'bookingIdFile', label: this.$t('bookingId.bkgId'), variant: 'info'},                                        
                { key: 'agent', label: this.$t('bookingId.agent'), sortable: true },  
                { key: 'timestamp', label: this.$t('shipApproval.date'), sortable: true, 
                    formatter: value => {
                        return this.$moment(value).format("YY-MM-DD@HH:mm")
                }},
                { key: 'active', label: this.$t('company.active'), variant: 'primary',sortable: true,
                    formatter: value => {
                        return this.$t(`bookingId.${value}`)
                }},
                { key: 'act', label: this.$t('matchTx.actions'), variant: 'danger'}
            ],
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [5, 10, 15, { value: 100, text: "Show a lot" }],
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
		}
	},

    computed: {
      sortOptions() {
        // Create an options list from our fields
        return this.fields
          .filter(f => f.sortable)
          .map(f => {
            return { text: f.label, value: f.key }
          })
      },
    },
	mounted(){
        this.compId= localStorage.getItem("compId")
        agentList((res)=>{
            this.agentList = res
        })
        bkgIdListAll(this.compId, (res)=>{
            this.items = res
            this.totalRows = res.length
        })
	},
	methods:{
        onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length
        this.currentPage = 1
      },
        rowClass(item, type) {
            if (!item || type !== 'row') return
            if (item.active === false) return 'table-danger'
        },
        showModal() {
            this.$refs['bkgId-modal'].show()         
        },
        async overwriteModal() {
            await this.saveBkgId().then(()=>{          
                this.$refs['bkgId-modal'].hide()            
            })
        },
        async doNotModal() {
            await this.refresh()
            this.$refs['bkgId-modal'].hide()
        },
        async saveBkgId(){
            await uploadImage('bookingId', this.files[0], async(url)=>{ 
                this.url = url
                // console.log(`url => ${url}`)
                let payload = {
                    bkgId : this.bkgId,
                    agent : this.agent,
                    compId : this.compId,          
                    fileUri : url,
                    timestamp: Date.parse(new Date()),
                    active: true
                }
                await saveWithId('BookingID', this.bkgId + this.compId, payload)
                await this.refresh()
                this.$toasted.show(this.$t('reuse.successSave'), {theme: 'bubble', position: 'bottom-center', type: 'success' , duration: 5000});    
            })   
        },
        async handleSave(){
            let msg = 'completed'
            if (this.bkgId ==='') {
                msg = 'bookingId.addBkgId'
            } else if (this.agent ===''){
                msg = 'bookingId.selAgent'
            } else if (this.files.length === 0){
                msg = 'bookingId.addFile'
            } else if (this.files.length > 1){
                msg = 'bookingId.oneFileOnly'
            }  
            if (msg === 'completed'){
                // verfy for existing BookingId? If not, process saveBkgId.
                agentAtBkgId(this.bkgId , this.compId, async(res)=>{
                    if (res) {
                        this.showModal()
                    } else {
                        this.saveBkgId()                        
                    }
                })
            }else{
                this.$toasted.show(this.$t(msg), {theme: 'bubble', type: 'error' , position: 'bottom-center', duration: 5000});     
            }
        },
        selectFiles(){
            this.$refs.fileInput.click()
        },
        onFileSelect(event){
            const selFiles = event.target.files;
            const acceptFile = ["jpeg", "png", "pdf"]
            // console.log(`selFiles => ${JSON.stringify(event.target.files)}`)
            if (selFiles.length === 0) return     
            for (let i = 0; i < selFiles.length; i++) {
                // console.log(`bookingId, selFiles[i] => ${selFiles[i].type}`)
                if (!acceptFile.includes( selFiles[i].type.split("/")[1])) continue;
                if (!this.files.some((e)=> e.name === selFiles[i].name)){
                   this.fileType = selFiles[i].type.split("/")[1] 
                   this.files.push({name:selFiles[i].name, url: URL.createObjectURL(selFiles[i])});  // for Multiple images
                   // this.files[0] = {name:selFiles[i].name, url: URL.createObjectURL(selFiles[i])}  // replace for 1 last image only.
                }
            }  
            // console.log(`files => ${JSON.stringify(this.files)}`)
        },
        deleteFile(index){
            this.files.splice(index , 1)
        },
        onDragOver(event){
            event.preventDefault();
            this.isDragging = true
            event.dataTransfer.dropEffect = "copy";
        },
        onDragLeave(event){
            event.preventDefault();
            this.isDragging = false
        },
        onDrop(event){
            event.preventDefault();
            this.isDragging = false;
            const acceptFile = ["jpeg" ,"png", "pdf"]
            const selFiles = event.dataTransfer.files;
            for (let i = 0; i < selFiles.length; i++) {
                // console.log(`bookingId, on Drop, selFiles[i] => ${selFiles[i].type}`)
                // if (selFiles[i].type.split("/")[1] != "pdf") continue;
                if (!acceptFile.includes( selFiles[i].type.split("/")[1])) continue;
                if (!this.files.some((e)=> e.name === selFiles[i].name)){
                    this.fileType = selFiles[i].type.split("/")[1]
                    this.files.push({name:selFiles[i].name, url: URL.createObjectURL(selFiles[i])});  // for Multiple images
                    // this.files[0] = {name:selFiles[i].name, url: URL.createObjectURL(selFiles[i])} // replace for 1 last image only.
                }
            }
        },
        actStop(item) {
            this.$bvModal.msgBoxConfirm(this.$t('bookingId.confirmStopMsg'), {
            title: this.$t('matchTx.plsConfirm'),
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'warning',
            okTitle: this.$t('matchTx.yes'),
            cancelTitle: this.$t('matchTx.no'),
            footerClass: 'p-2',
            hideHeaderClose: false,
            centered: true
            })
            .then(async(value) => {
                if (value) {
                    await updateActive( "BookingID", item.bkgId + item.compId, false)
                    await this.refresh()                    
                    this.$toasted.show( this.$t('bookingId.stoped'), {theme: 'bubble', type: 'warning' , position: 'bottom-center', duration: 5000});  
                } else {
                return
                }
            })
            .catch(error => {
                this.$toasted.show( `Action-Suspend: ${error}`, {theme: 'bubble', type: 'error' , position: 'bottom-center', duration: 5000});  
            })
        },
        actAble(item) {
            this.$bvModal.msgBoxConfirm(this.$t('bookingId.confirmAbleMsg'), {
            title: this.$t('matchTx.plsConfirm'),
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'warning',
            okTitle: this.$t('matchTx.yes'),
            cancelTitle: this.$t('matchTx.no'),
            footerClass: 'p-2',
            hideHeaderClose: false,
            centered: true
            })
            .then(async(value) => {
                if (value) {
                    await updateActive( "BookingID", item.bkgId + item.compId , true)
                     this.refresh()                    
                    this.$toasted.show( this.$t('boookingId.abled'), {theme: 'bubble', type: 'error' , position: 'bottom-center', duration: 5000});  
                } else {
                return
                }
            })
            .catch(error => {
                this.$toasted.show( `Action-Delete: ${error}`, {theme: 'bubble', type: 'error' , position: 'bottom-center', duration: 5000});  
            })
        }, 
        actDelete(item) {
            this.$bvModal.msgBoxConfirm(this.$t('container.confirmDeleteMsg'), {
            title: this.$t('matchTx.plsConfirm'),
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'warning',
            okTitle: this.$t('matchTx.yes'),
            cancelTitle: this.$t('matchTx.no'),
            footerClass: 'p-2',
            hideHeaderClose: false,
            centered: true
            })
            .then(async(value) => {
                if (value) {
                    await deleteItem( "BookingID", item.bkgId + item.compId)
                    await this.refresh()                    
                    this.$toasted.show( this.$t('container.deleted'), {theme: 'bubble', type: 'error' , position: 'bottom-center', duration: 5000});  
                } else {
                return
                }
            })
            .catch(error => {
                this.$toasted.show( `Action-Delete: ${error}`, {theme: 'bubble', type: 'error' , position: 'bottom-center', duration: 5000});  
            })
        },    
        async refresh(){
            await bkgIdListAll(this.compId, (res)=>{
                this.items = res
                this.totalRows = res.length
                this.bkgId = ''
                this.agent = ''
                this.files = []
            })
        },

        openBkgFile(item, index, button) {
            // window.location.href= item.fileUri
            window.open(item.fileUri)

        },
    }
}
</script>
<style scoped>
.card{
    width:100%;
    padding:2px;
    box-shadow: 0 0 5px #ffdfdf;
    border-radius: 5px;
    overflow: hidden;
}
.card .top{
    text-align: center;
}
.card p{
    font-weight: bold;
    color: #fe0000;
}
.card button{
    outline: 0;
    border: 0;
    color : #ffff;
    border-radius: 4px;
    font-weight: 40;
    padding: 8px 13px;
    width: 100%;
    background: #fe0000;
}
.card .drag-area{
    height : 100px;
    border-radius: 5px;
    border: 2px dashed #00d4fe;
    background: #f4f3f9;
    color : #fe0000;
    display:flex;
    justify-content: center;
    align-items: center;
    user-select: center;
    -webkit-user-select: none;
    margin-top: 5px;
}
.card .darg-area .visible{
    font-size: 18px
}
.card .select{
    color: #5256ad;
    margin-left: 5px;
    cursor: pointer;
    transition: 0.4s;
}
.card .select :hover{
    opacity: 0.6;
}
.card .container{
    height : auto;
    width: 100%;
    display:flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    max-height: 100%;
    position: relative;
    margin-bottom: 8px;
    padding-top: 15px;
}
.card .container .file{
    width: 100%;
    margin-right: 5px;
    height: 100%;
    position: relative;
    margin-bottom: 8px;
}
.card .container .image img{
    width: 100%;
    height: 100%;
    border-radius: 5px;
}
.card .container .file span{
    position:absolute;
    top: -2px;
    right: 9px;
    font-size: 30px;
    cursor: pointer;
}
.card input,
.card .drsg-area .on-drop,
.card .drag-area.dragover .visible{
    display: none;
}
.delete{
    z-index: 999;
    color:#fe0000;
}
</style>

