<template>
    <div>
        <Breadcrumbs main="matchTx.dashboard" title="homePage.header" />
        <b-container fluid>   
            <div class="d-flex flex-row justify-content-start ml-5">
                <h4 class="text-dark ml-2">{{ compNameLocal }}-</h4>
                <h4 class="text-primary">{{ $t('refillOL.wallet') }} :</h4>
                <h4 class="text-secondary mx-2">{{ balanced }} </h4>
                <h4 class="text-primary"> {{ $t('refillOL.mUnit') }}</h4>
                <button 
                    v-b-popover.hover.top="$t('homePage.refillMsg')" 
                    @click="handleClickRefillOL" 
                    class="text-primary btn-sm ml-5"
                > 
                    {{ $t('refillOL.refill') }}
                </button>
            </div>  
            <div class="block-diagram">
                <div
                    style="top: 5px; left:50px;"
                    class="block"
                    v-b-popover.hover.top="$t('homePage.containerAction')" 
                >
                    <img src = "../../assets/images/homePage/container.png" alt="Block Image" class="block-image my-2" />
                    <p class="block-image">{{$t('homePage.containerInfo')}}</p>
                    <button @click="handleClickCont" class="block-button">{{$t('homePage.container')}}</button>
                </div>
                <div
                    style="top: 5px; left:250px;"
                    class="block"
                    v-b-popover.hover.top="$t('homePage.releaseAction')" 
                >
                    <img src = "../../assets/images/homePage/release.png" alt="Block Image" class="block-image" />
                    <p class="block-image" style="font-size: 13px;">{{ $t('homePage.releaseInfo') }}</p>
                    <button @click="handleClickRelease" class="block-button btn-lg">{{ $t('homePage.release') }}</button>
                </div>
                <svg class="connecting-lines">
                    <line
                    v-for="(line, index) in linesG1"
                    :key="index"
                    :x1="line.x1"
                    :y1="line.y1"
                    :x2="line.x2"
                    :y2="line.y2"
                    class="line"
                    />
                    <defs>
                    <marker
                        id="arrowhead"
                        markerWidth="10"
                        markerHeight="7"
                        refX="9"
                        refY="3.5"
                        orient="auto"
                    >
                        <polygon points="0 0, 10 3.5, 0 7" class="arrowhead" />
                    </marker>
                    </defs>
                </svg>
            </div>
            <div class="block-diagram">
                <div
                    style="top: 30px; left:80px;"
                    class="blockSeal"
                    v-b-popover.hover.top="$t('homePage.sealAction')" 
                >
                    <img src = "../../assets/images/homePage/sealTag.png" alt="Seal Image" class="block-imageSeal" />
                    <p class="block-imageSeal">{{$t('homePage.sealInfo')}}</p>
                    <button @click="handleClickSeal" class="block-button">{{$t('homePage.seal')}}</button>
                </div>
                <div
                    style="top: 30px; left:250px;"
                    class="block"
                    v-b-popover.hover.top="$t('homePage.reuseAction')" 
                >
                    <img src = "../../assets/images/homePage/reuse.png" alt="Reuse Image" class="block-image" />
                    <p class="block-image" style="font-size: 16px;">{{ $t('homePage.reuseInfo') }}</p>
                    <button @click="handleClickReuse" class="block-button btn-lg">{{ $t('homePage.reuse') }}</button>
                </div>
                <div
                    style="top: 5px; left:450px;"
                    class="block"
                    v-b-popover.hover.top="$t('homePage.matchingAction')" 
                >
                    <img src = "../../assets/images/homePage/matching.png" alt="Matching Image" class="block-imageMatching" />
                    <p class="block-image ml-4">{{ $t('homePage.matchingInfo')}}</p>
                    <button @click="handleClickMatching" class="block-button btn-lg">{{ $t('homePage.matching') }}</button>
                </div>
                <div
                    style="top: 30px; left:660px;"
                    class="blockShip"
                    v-b-popover.hover.top="$t('homePage.shipAction')" 
                >
                    <img src = "../../assets/images/homePage/shipApproval.png" alt="Seal Image" class="block-imageShip" />
                    <p class="block-image">{{$t('homePage.shipApproval')}}</p>
                </div>
                <svg class="connecting-lines">
                    <line
                    v-for="(line, index) in linesG2"
                    :key="index"
                    :x1="line.x1"
                    :y1="line.y1"
                    :x2="line.x2"
                    :y2="line.y2"
                    class="line"
                    />
                    <defs>
                    <marker
                        id="arrowhead"
                        markerWidth="10"
                        markerHeight="7"
                        refX="9"
                        refY="3.5"
                        orient="auto"
                    >
                        <polygon points="0 0, 10 3.5, 0 7" class="arrowhead" />
                    </marker>
                    </defs>
                </svg>
            </div>
            <div class="block-diagram">
                <div
                    style="top: 50px; left:50px;"
                    class="block"
                    v-b-popover.hover.top="$t('homePage.bookingAction')" 
                >
                    <img src = "../../assets/images/homePage/bookingID.png" alt="Block Image" class="block-image" />
                    <p class="block-image">{{$t('homePage.bookingInfo')}}</p>
                    <button @click="handleClickBooking" class="block-button">{{$t('homePage.booking')}}</button>
                </div>
                <div
                    style="top: 50px; left:250px;"
                    class="block"
                    v-b-popover.hover.top="$t('homePage.requireAction')" 
                >
                    <img src = "../../assets/images/homePage/require.png" alt="Block Image" class="block-image" />
                    <p class="block-image" style="font-size: 13px;">{{ $t('homePage.requireInfo') }}</p>
                    <button @click="handleClickRequire"  class="block-button btn-lg">{{ $t('homePage.require') }}</button>
                </div>
                <svg class="connecting-lines">
                    <line
                    v-for="(line, index) in linesG3"
                    :key="index"
                    :x1="line.x1"
                    :y1="line.y1"
                    :x2="line.x2"
                    :y2="line.y2"
                    class="line"
                    />
                    <defs>
                    <marker
                        id="arrowhead"
                        markerWidth="10"
                        markerHeight="7"
                        refX="9"
                        refY="3.5"
                        orient="auto"
                    >
                        <polygon points="0 0, 10 3.5, 0 7" class="arrowhead" />
                    </marker>
                    </defs>
                </svg>
            </div>
        </b-container>
    </div>

  </template>

  <script>
      import {getDataWithId, getBalance } from "../../firebase"
      export default {
    data() {
      return {
        compNameLocal: '',
        balanced : 0,
        cc: '',
        linesG1: [
            { x1: 175, y1: 100, x2: 250, y2: 100 }, // Line between container Block and Release Block
            { x1: 375, y1: 100, x2: 540, y2: 100 }, // Line between Release Block and Matchoing Block
            { x1: 540, y1: 100, x2: 540, y2: 190 }  // Line over Matching block
        ],
        linesG2: [
            { x1: 165, y1: -5, x2: 250, y2: 30 }, // Line from Contianer Block to Reuse Block
            { x1: 170, y1: 100, x2: 250, y2: 100 }, // Line between Seal Block and Reuse Block
            { x1: 370, y1: 100, x2: 450, y2: 100 }, // Line between Reuse Block and Matching Block
            { x1: 660, y1: 100, x2: 620, y2: 100 }, // Line between Matching Block and ShipApproval Block
        ],
        linesG3: [
            { x1: 170, y1: 55, x2: 250, y2: 10 }, // Line between Booking Block and Reuse Block
            { x1: 170, y1: 140, x2: 250, y2: 140 }, // Line between Booking Block and Reqr Block
            { x1: 370, y1: 140, x2: 540, y2: 140 }, // Line between Reqr Block and Matching Block
            { x1: 540, y1: 140, x2: 540, y2: 25 } //Line below Matching Block
          // Add more lines as needed
        ],
      };
    },
    mounted(){
        this.compId = localStorage.getItem("compId")
        getDataWithId("Company", "compId", this.compId, (res)=>{
            this.compNameLocal = res.compNameLocal
        })
        getBalance(this.compId ,(res)=>{
            this.balanced = res
        })
    },
    methods: {
      handleClickRefillOL() {
        this.$router.push('/payment/refillOL')
      },
      handleClickCont() {
        this.$router.push('/resource/container')
      },
      handleClickRelease() {
        this.$router.push('/process/release')
      },
      handleClickSeal() {
        this.$router.push('/resource/seal')
      },
      handleClickReuse() {
        this.$router.push('/process/reuse')
      },
      handleClickMatching() {
        this.$router.push('/dashboard/matching')
      },
      handleClickBooking() {
        this.$router.push('/resource/bookingId')
      },
      handleClickRequire() {
        this.$router.push('/process/require')
      },
    },
  };
  </script>
  
  <style>
  .block-diagram {
    position: relative;
    width: 100%;
    height: 190px;
  }
  
  .block {
    position: absolute;
    text-align: center;
    border: 1px solid #ccc;
    padding: 10px;
    background-color: #f9f9f9;
  }
  .blockShip {
    position: absolute;
    text-align: center;
    border: 1px solid #ccc;
    padding: 10px;
    background-color: #efd250bb;
  }
  .blockSeal {
    position: absolute;
    text-align: center;
    border: 1px solid #ccc;
    padding: 10px;
    background-color: #b7c8d1bb;
  }
  .block-image {
    width: 100px;
    height: 50px;
  }
  .block-imageSeal {
    width: 70px;
    height: 40px;
  }
  .block-imageMatching {
    width: 140px;
    height: 80px;
  }
  .block-imageShip {
    width: 100px;
    height: 80px;
  }
  .block-text {
    font-size: 18px;
    margin: 5px 0;
    justify-content: center;
  }
  
  .block-button {
    padding: 5px 10px;
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
  }
  
  .block-button:hover {
    background-color: #0056b3;
  }
  
  .connecting-lines {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
  }
  
  .line {
    stroke: #0e1cded7;
    stroke-width: 2;
    marker-end: url(#arrowhead);
    stroke-dasharray: 5, 5; /* Creates a dotted line */
    stroke-dashoffset: 10; /* Initial offset */
    animation: run-dot 1s linear infinite;
  }
  
  .arrowhead {
    fill: #000;
  }

@keyframes run-dot {
  to {
    stroke-dashoffset: 0; /* Moves the dots along the line */
  }
}
  </style>