<template>
	<div>
		<Breadcrumbs main="user.resource" title="user.header"/>
		<!-- Container-fluid starts-->
		<div class="container-fluid">
            <div>
                <div class="row">
                    <h4 class="mb-1 mt-4 ml-3 f-w-600">{{ $t('user.allUserList') }}</h4>                  
                    <b-modal ref="compInfo-modal"  :title="$t('company.header')" hide-footer>
                        <div>
                            <h4 class="f-w-600 text-primary mt-2 mx-2" style="font-size:16px">
                                {{ $t('refill.compNameLocal') }}
                                <span class="text-success ml-2">{{ selCompNameLocal }} </span>
                            </h4> 
                            <h4 class="f-w-600 text-primary mt-2 mx-2" style="font-size:16px">
                                {{ $t('company.busType') }}
                                <span class="text-success ml-2">{{ selBusType }} </span>
                            </h4> 
                            <h4 class="f-w-600 text-primary mt-2 mx-2" style="font-size:16px">
                                {{ $t('company.address') }}
                                <span class="text-success ml-2">{{ selCompAddress }} </span>
                            </h4> 
                        </div>
                    </b-modal>
                    <b-col lg="3" class="ml-3 mt-3">
                        <b-form-group
                        :label="$t('matchTx.filter')"
                        label-for="filter-input"
                        label-cols-sm="3"
                        label-align-sm="right"
                        label-size="lg"
                        class="mb-0"
                        >
                        <b-input-group size="sm">
                            <b-form-input
                            id="filter-input"
                            v-model="filter"
                            type="search"
                            :placeholder="$t('matchTx.typeToSearch')"
                            class="text-info"
                            style="font-size: 18px;"
                            ></b-form-input>
                            <b-input-group-append>
                            <b-button size="md" variant="primary" :disabled="!filter" @click="filter = ''">
                                {{ $t('matchTx.clear') }}
                            </b-button>
                            </b-input-group-append>
                        </b-input-group>
                        </b-form-group>
                    </b-col>
                    <b-col lg="5" class="my-1">
                        <b-form-group
                        v-model="sortDirection"
                        :label="$t('matchTx.filterOn')"
                        :description= "$t('matchTx.uncheck')"
                        label-cols-sm="3"
                        label-align-sm="right"
                        label-size="lg"
                        class="mb-0"
                        v-slot="{ ariaDescribedby }"
                        >
                        <b-form-checkbox-group
                            v-model="filterOn"
                            :aria-describedby="ariaDescribedby"
                            class="mt-1 text-info"
                            plain
                        >
                            <b-form-checkbox value="name">{{ $t('user.name') }}</b-form-checkbox>                                                                
                            <b-form-checkbox value="email">{{ $t('user.email') }}</b-form-checkbox>   
                            <b-form-checkbox value="phone">{{ $t('user.phone') }}</b-form-checkbox>   
                            <b-form-checkbox value="compId">{{ $t('user.compId') }}</b-form-checkbox>
                        </b-form-checkbox-group>
                        </b-form-group>
                    </b-col>   
                </div>
                <b-table
                    sticky-header
                    head-variant="light"
                    table-variant="success"
                    show-empty
                    sort-icon-left
                    stacked="md"
                    sort-direction="asc"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    :items="items"
                    :fields="fields"
                    :tbody-tr-class="rowClass"
                    :current-page="currentPage"
                    :per-page="perPage"
                    @filtered="onFiltered"
                >
                    <!-- A virtual column -->
                    <template #cell(index)="data">
                        {{ data.index + 1 }}
                    </template>
                    <template #cell(compInfo)="row" >
                        <b-button size="sm" @click="openModalCompInfo(row.item, row.index, $event.target )" class="m-1">
                            {{ row.item.compId }}
                        </b-button>
                    </template>
                    <template #cell(actActive)="row" >
                        <b-button v-show="row.item.active" size="sm" variant="danger" @click="actSuspend(row.item)" class="m-1">
                            <span class="f-w-600" style="font-size: 14px;"> {{ $t('user.suspend') }}</span>
                        </b-button>
                        <b-button  v-show="!row.item.active" size="sm" variant="success" @click="actAble(row.item)" class="m-1">
                            <span class="f-w-600" style="font-size: 14px;"> {{ $t('user.able') }}</span>
                        </b-button>
                    </template>
                </b-table>
                <b-row>
                    <div class="row col-sm-8 ">
                        <b-col class="my-1 ml-3">
                            <b-pagination
                            v-model="currentPage"
                            :total-rows="totalRows"
                            :per-page="perPage"
                            align="fill"
                            class="my-0"
                            ></b-pagination>
                        </b-col>   
                        <b-col sm="3" md="6" class="my-1">
                            <b-form-group
                            :label= "$t('require.perPage')"
                            label-for="per-page-select"
                            label-cols-sm="6"
                            label-cols-md="4"
                            label-cols-lg="3"
                            label-align-sm="right"
                            label-size="sm"
                            class="mb-0"
                            >
                            <b-form-select
                                id="per-page-select"
                                v-model="perPage"
                                :options="pageOptions"
                            ></b-form-select>
                            </b-form-group>
                        </b-col>
                    </div>
                </b-row>
            </div>
        </div>
        <!-- Container-fluid Ends-->
    </div>
</template>
<script>
import {updateActive, userListAll, getDataWithId} from "../../firebase"
export default {
	data(){
		return{
            userId: '',
            selCompNameLocal : '',
            selBusType : '',
            selCompAddress : '',
            items: [],
            fields: [
                { key: 'index', label: this.$t('seal.item'), sortable: true, }, 
                { key: 'userId', label: this.$t('user.userId'), sortable: true }, 
                { key: 'name', label: this.$t('user.name'), sortable: true },  
                { key: 'userCat', label: this.$t('user.userCat'), sortable: true,
                    formatter: value => {
                        return this.$t(`user.${value}`)
                }},                                     
                { key: 'email', label: this.$t('user.email'), sortable: true },  
                { key: 'phone', label: this.$t('user.phone'), sortable: true }, 
                { key: 'compInfo', label: this.$t('user.compId'),  variant: 'info' },             
                // { key: 'compId', label: this.$t('user.compId'), sortable: true },  
                { key: 'active', label: this.$t('user.active'), variant: 'primary',
                    formatter: value => {
                        return this.$t(`user.${value}`)
                }},
                { key: 'actActive', label: this.$t('user.actActive'), variant: 'danger'}
            ],
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [5, 10, 15, { value: 100, text: "Show a lot" }],
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
		}
	},
    computed: {
      sortOptions() {
        // Create an options list from our fields
        return this.fields
          .filter(f => f.sortable)
          .map(f => {
            return { text: f.label, value: f.key }
          })
      },
    },
	mounted(){
        userListAll((res)=>{
            this.items = res
            this.totalRows = res.length
        })
	},
	methods:{
        onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length
        this.currentPage = 1
      },
        rowClass(item, type) {
            if (!item || type !== 'row') return
            if (item.active === false) return 'table-danger'
        },
        async refresh(){
            await userListAll( (res)=>{
            this.items = res
            this.totalRows = res.length
            })
        },
        openModalCompInfo(item, index, button) {
            getDataWithId("Company" , "compId" , item.compId, (res)=>{
                this.selCompNameLocal = res.compNameLocal
                this.selBusType = res.busType
                this.selCompAddress = res.compAddress
            })
            this.$refs['compInfo-modal'].show()
        },
        
        actSuspend(item) {
            this.$bvModal.msgBoxConfirm(this.$t('user.confirmSuspendMsg'), {
            title: this.$t('matchTx.plsConfirm'),
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'warning',
            okTitle: this.$t('matchTx.yes'),
            cancelTitle: this.$t('matchTx.no'),
            footerClass: 'p-2',
            hideHeaderClose: false,
            centered: true
            })
            .then(async(value) => {
                if (value) {
                    await updateActive( "Users", item.userId , false)
                     this.refresh()                    
                    this.$toasted.show( this.$t('user.suspended'), {theme: 'bubble', type: 'error' , position: 'bottom-center', duration: 5000});  
                } else {
                return
                }
            })
            .catch(error => {
                this.$toasted.show( `Action-Delete: ${error}`, {theme: 'bubble', type: 'error' , position: 'bottom-center', duration: 5000});  
            })
        },    

        actAble(item) {
            this.$bvModal.msgBoxConfirm(this.$t('user.confirmAbleMsg'), {
            title: this.$t('matchTx.plsConfirm'),
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'warning',
            okTitle: this.$t('matchTx.yes'),
            cancelTitle: this.$t('matchTx.no'),
            footerClass: 'p-2',
            hideHeaderClose: false,
            centered: true
            })
            .then(async(value) => {
                if (value) {
                    await updateActive( "Users", item.userId , true)
                     this.refresh()                    
                    this.$toasted.show( this.$t('user.abled'), {theme: 'bubble', type: 'error' , position: 'bottom-center', duration: 5000});  
                } else {
                return
                }
            })
            .catch(error => {
                this.$toasted.show( `Action-Delete: ${error}`, {theme: 'bubble', type: 'error' , position: 'bottom-center', duration: 5000});  
            })
        },    

    }
}
</script>

