<template>
	<div>
		<Breadcrumbs main="matchTx.dashboard" title="shipDetDate.header"/>
		<!-- Container-fluid starts-->
		<div class="container-fluid">
            <div>
                <div class="row">
                    <h4 class="mb-1 mt-4 ml-3 f-w-600">{{ $t('shipDetDate.reqrDetList') }}</h4>                 
                </div>
                <b-table
                    sticky-header
                    head-variant="light"
                    table-variant="success"
                    show-empty
                    sort-icon-left
                    stacked="md"      
                    :items="items"
                    :fields="fields"
                    :current-page="currentPage"
                    :per-page="perPage"
                >
                    <template #cell(index)="data">
                        {{ data.index + 1 }}
                    </template>
                    <template #cell(addDet)="row" >
                        <b-button  size="sm" @click="showModal(row.item, row.index, $event.target)" class="m-1">
                           <span class="f-w-600" style="font-size: 16px;"> {{ $t('shipDetDate.selDate') }}</span>
                        </b-button>
                    </template>
                    <template #empty>
                        <h5>{{ $t('reuse.noRecord') }}</h5>
                    </template>
                    
                </b-table>
                <b-row>
                    <div class="row col-sm-4 ">
                    <b-col class="my-1 ml-3">
                        <b-pagination
                        v-model="currentPage"
                        :total-rows="totalRows"
                        :per-page="perPage"
                        align="fill"
                        class="my-0"
                        ></b-pagination>
                    </b-col>   
                    <b-col sm="3" md="6" class="my-1">
                        <b-form-group
                        :label= "$t('require.perPage')"
                        label-for="per-page-select"
                        label-cols-sm="6"
                        label-cols-md="4"
                        label-cols-lg="3"
                        label-align-sm="right"
                        label-size="sm"
                        class="mb-0"
                        >
                        <b-form-select
                            id="per-page-select"
                            v-model="perPage"
                            :options="pageOptions"
                        ></b-form-select>
                        </b-form-group>
                    </b-col>
                    </div>
                </b-row>
                <b-modal ref="selDet-modal" :title="contNo" @hide="resetInfoModal" hide-footer>
                    <label for="detDate" class="f-w-600 text-primary" style="font-size: 16px">{{ $t("shipDetDate.selDate") }}</label>
                    <datepicker input-class="datepicker-here form-control digits"  :disabled-dates="disabledDates"  :format="format" v-model="detDate" ></datepicker>
                    <b-row class="mt-2">
                        <b-button class="ml-3 f-w-600 bg-warning text-danger" variant="outline-warning" size="lg" @click="doNotModal"> <span >{{ $t('shipDetDate.cancel') }} </span></b-button>
                        <b-button class="ml-4 f-w-600 bg-success" size="lg" @click="saveDet">{{ $t('shipDetDate.ok') }}</b-button>
                    </b-row>
                </b-modal>
            </div>
            <div>
                <div class="row">
                    <h4 class="mb-1 mt-4 ml-3 f-w-600">{{ $t('shipDetDate.detList') }}</h4>                 
                </div>
                <b-table
                    sticky-header
                    head-variant="light"
                    table-variant="muted"
                    show-empty
                    sort-icon-left
                    stacked="md" 
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :items="itemsDet"
                    :fields="fieldsDet"
                    :current-page="currentPageDet"
                    :per-page="perPageDet"
                >
                    <template #cell(index)="data">
                        {{ data.index + 1 }}
                    </template>
                    <template #cell(actDet)="row" >
                        <b-button  size="sm" @click="showModalDet(row.item, row.index, $event.target)" class="m-1">
                           <span class="f-w-600" style="font-size: 16px;"> {{ $t('shipDetDate.edit') }}</span>
                        </b-button>
                    </template>
                    <template #empty>
                        <h5>{{ $t('reuse.noRecord') }}</h5>
                    </template>
                </b-table>
                <b-row>
                    <div class="row col-sm-4 ">
                    <b-col class="my-1 ml-3">
                        <b-pagination
                        v-model="currentPageDet"
                        :total-rows="totalRowsDet"
                        :per-page="perPageDet"
                        align="fill"
                        class="my-0"
                        ></b-pagination>
                    </b-col>   
                    <b-col sm="3" md="6" class="my-1">
                        <b-form-group
                        :label= "$t('require.perPage')"
                        label-for="per-page-select"
                        label-cols-sm="6"
                        label-cols-md="4"
                        label-cols-lg="3"
                        label-align-sm="right"
                        label-size="sm"
                        class="mb-0"
                        >
                        <b-form-select
                            id="per-page-select"
                            v-model="perPageDet"
                            :options="pageOptionsDet"
                        ></b-form-select>
                        </b-form-group>
                    </b-col>
                    </div>
                </b-row>
                <b-modal ref="actDet-modal" :title="contNoDet" @hide="resetInfoModalDet" hide-footer>
                    <label for="detDateDet" class="f-w-600 text-primary" style="font-size: 16px">{{ $t("shipDetDate.selDate") }}</label>
                    <datepicker input-class="datepicker-here form-control digits"  :disabled-dates="disabledDates"  :format="format" v-model="detDateDet" ></datepicker>
                    <b-row class="mt-2">
                        <b-button class="ml-3 f-w-600 bg-warning text-danger" variant="outline-warning" size="lg" @click="doNotModalDet"> <span >{{ $t('shipDetDate.cancel') }} </span></b-button>
                        <b-button class="ml-4 f-w-600 bg-success" size="lg" @click="updateDet">{{ $t('shipDetDate.ok') }}</b-button>
                    </b-row>
                </b-modal>
            </div>
        </div>
        <!-- Container-fluid Ends-->
    </div>
</template>
<script>
import {agentAtCompId, contListAgentNoDet, contListAgentDet, mergeObj } from "../../firebase"
import Datepicker from 'vuejs-datepicker';
export default {
	data(){
		return{
            disabledDates: {
                to: new Date(Date.parse(new Date())-86400000),
            },
            compId : '',
            format: "yyyy-MM-dd",
            contId : '',
            contNo : '',
            owner: '',
            detDate : '',
            items: [],
            fields: [
                { key: 'index', label: this.$t('shipDetDate.item') },  
                { key: 'contNo', label: this.$t('release.contNo'), sortable: true, }, 
                { key: 'contSize', label: this.$t('release.contSize'), sortable: true},                  
                { key: 'contType', label: this.$t('release.contType'), sortable: true },
                { key: 'owner', label: this.$t('release.agent'), sortable: true },  
                { key: 'detDate', label: this.$t('require.detDate'), sortable: true,variant: 'info' },                  
                { key: 'addDet', label: this.$t('shipDetDate.action'), variant: 'warning'},
            ],
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [5, 10, 15, { value: 100, text: "Show a lot" }],
            contIDet : '',
            contNoDet : '',
            detDateDet : '',
            itemsDet: [],
            fieldsDet: [
                { key: 'index', label: this.$t('shipDetDate.item') },  
                { key: 'contNo', label: this.$t('release.contNo'), sortable: true, }, 
                { key: 'contSize', label: this.$t('release.contSize'), sortable: true},                  
                { key: 'contType', label: this.$t('release.contType'), sortable: true },
                { key: 'owner', label: this.$t('release.agent'), sortable: true },  
                { key: 'detDate', label: this.$t('require.detDate'), sortable: true,variant: 'info' },                  
                  { key: 'actDet', label: this.$t('shipDetDate.action'), variant: 'danger'},
                { key: 'timestamp', label: this.$t('shipApproval.date'), sortable: true, 
                    formatter: value => {
                    return this.$moment(value).format("YY-MM-DD @ HH:mm")
                }},
            ],
            totalRowsDet: 1,
            currentPageDet: 1,
            perPageDet: 10,
            pageOptionsDet: [5, 10, 15, { value: 100, text: "Show a lot" }],
            sortBy: 'timestamp',
            sortDesc: true,
        }
	},
	components: {
		Datepicker
	},
	mounted(){
        this.compId= localStorage.getItem("compId")
        agentAtCompId (this.compId , (agent)=>{
            this.owner = agent
            contListAgentNoDet(agent, (res)=>{
                this.items = res
                this.totalRows = res.length
            })
            contListAgentDet(agent, (res)=>{
                this.itemsDet = res
                this.totalRowsDet = res.length
            })
        })

	},
	methods:{
        showModal(item, index, button) {
            this.contNo = item.contNo
            this.contId = item.contId
            this.$refs['selDet-modal'].show()
        },
        resetInfoModal() {
            this.contNo = ''
            this.contId = ''
        },        

        async doNotModal() {
            this.contNo = ''
            this.contId = ''
            this.$refs['selDet-modal'].hide()
        },
        async saveDet(){
            if (this.detDate) {
                let detDate = this.$moment(this.detDate).format("YYYY-MM-DD")
                let detTS = Date.parse(detDate + ' ' + '23:59:59')
                let obj = {
                    detDate : detDate,
                    detTS : detTS,
                    timestamp: Date.parse(new Date()),
                }
                // console.log(`obj =>${JSON.stringify(obj , null , 2)}`)
                await mergeObj('Container', this.contId, obj).then(async()=>{
                    await this.refresh()
                    this.$toasted.show(this.$t('reuse.successSave'), {theme: 'bubble', position: 'top-center', type: 'success' , duration: 5000});                                                    
                })  
                this.$refs['selDet-modal'].hide()
            } else {
                this.$toasted.show(this.$t('shipDetDate.noDetDate'), {theme: 'bubble', position: 'top-center', type: 'warning' , duration: 3000});               
            }
        },
        async refresh(){
            await contListAgentNoDet(this.owner, (res)=>{
                this.items = res
                this.totalRows = res.length
                this.contNo = ''
                this.contId = ''
                this.detDate = ''
                this.detDateTS = 0
            })
            await this.refreshDet()
        },
        showModalDet(item, index, button) {
            this.contNoDet = item.contNo
            this.contIdDet = item.contId
            this.detDateDet = item.detDate
            this.$refs['actDet-modal'].show()
        },
        resetInfoModalDet() {
            this.contNoDet = ''
            this.contIdDet = ''
        },        

        async doNotModalDet() {
            this.contNoDet = ''
            this.contIdDet = ''
            this.$refs['actDet-modal'].hide()
        },
        async updateDet(){
            if (this.detDateDet) {
                let detDate = this.$moment(this.detDateDet).format("YYYY-MM-DD")
                let detTS = Date.parse(detDate + ' ' + '23:59:59')
                let obj = {
                    detDate : detDate,
                    detTS : detTS,
                    timestamp: Date.parse(new Date()),
                }
                // console.log(`obj =>${JSON.stringify(obj , null , 2)}`)
                await mergeObj('Container', this.contIdDet, obj).then(async()=>{
                    await this.refreshDet()
                    this.$toasted.show(this.$t('reuse.successSave'), {theme: 'bubble', position: 'top-center', type: 'success' , duration: 5000});                                                    
                })
                this.$refs['actDet-modal'].hide()
            } else{
                this.$toasted.show(this.$t('shipDetDate.noDetDate'), {theme: 'bubble', position: 'top-center', type: 'warning' , duration: 3000});                        
            }
        },
        async refreshDet(){
            await contListAgentDet(this.owner, (res)=>{
                this.itemsDet = res
                this.totalRowsDet = res.length
                this.contNoDet = ''
                this.contIdDet = ''
                this.detDateDet= ''
            })
        },
    }
}
</script>

